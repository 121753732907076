import React from "react";

// styles
import css from "../styles/pages/OrderSuccess.module.css";
import { CheckCircle } from "@mui/icons-material";
import { Button } from "@mui/material";
import { Link, useLocation } from "react-router-dom";

const OrderSuccess = () => {
  const { state } = useLocation();

  return (
    <div className={css.container}>
      <div>
        <CheckCircle sx={{ zoom: "7", color: "#005A5F" }} />
      </div>
      <div className={css.header}>
        {state
          ? "Order added to your orders list"
          : "Order placed successfully"}
      </div>
      <div>
        <div className={css.btnContainer}>
          <Link to={"/user/orders"}>
            <Button variant="contained">
              {state ? "View Order" : "track order"}
            </Button>
          </Link>
          <Link to={"/user/print"}>
            <Button variant="contained">order again</Button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default OrderSuccess;
