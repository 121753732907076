import { Add, Remove } from "@mui/icons-material";
import {
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  Paper,
  Radio,
  RadioGroup,
  TextareaAutosize,
  TextField,
  Grid,
  Select,
  MenuItem,
  Box,
  Container,
  Typography,
  Slider,
} from "@mui/material";
import React, { useEffect, useState, Fragment, useContext } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { AttachFile, Clear, UploadFileOutlined } from "@mui/icons-material";
import { storeUnitConverter } from "../utils/utils";
import { useParams } from "react-router-dom";
import PrintContext from "./PrintContex";
import css from "../styles/pages/PrintConfig.module.css";
import { toast } from "react-toastify";
import PreviewPages from "../components/PreviewPages";
// import MenuItem from '@mui/material/MenuItem';
function valuetext(value) {
  return `${value}°C`;
}

const PrintConfig = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [documentslen, setDocumentslen] = useState([]);

  const [documents, setDocuments] = useState(location.state?.data || []);
  const [selectedDocument, setSelectedDocument] = useState(
    location.state?.data[0]
  );

  // use effects
  useEffect(() => {
    if (Array.isArray(location.state.documents)) {
      setDocumentslen(location.state.documents.length);
    } else {
      navigate("/user/print");
      toast.error("Please select files");
    }
  }, []);

  const handleSelectStore = () => {
    let inputEmpty = false;
    const docsLength = documents.length;

    for (let doc of documents) {
      const {
        copies,
        sides,
        paperSize,
        colors,
        printLayout,
        binding,
        pageRange,
      } = doc.config;
      let isEmpty =
        !copies ||
        !sides ||
        !paperSize ||
        !colors.color ||
        !printLayout ||
        !binding ||
        !pageRange.length;
      if (isEmpty) {
        inputEmpty = true;
        break;
      }
    }

    if (!docsLength) inputEmpty = true;

    let updatedPageCountDocuments = [
      ...documents.map((d) => ({
        ...d,
        totalPages: d.config.pageRange[1] - d.config.pageRange[0] + 1,
      })),
    ];

    if (!inputEmpty) {
      navigate("/user/select-store", {
        state: {
          items: updatedPageCountDocuments.map((d) => ({
            ...d.config,
            fileName: d.fileName,
            totalPages: d.totalPages,
            document: d.document,
          })),
          totalPages: location.state.totalPages,
        },
      });
    } else {
      toast.error("Please fill the requied fields");
    }
  };
  const handlePrevious = () => {
    navigate("/user/print", { state: "isTrue" });
  };

  const paperSizeOptions = [
    { value: "a4", label: "A4" },
    { value: "a5", label: "A5" },
    { value: "legal", label: "Legal" },
    { value: "letter", label: "Letter" },
    { value: "b5", label: "B5" },
    { value: "a6", label: "A6" },
    { value: "postCard", label: "Post Card" },
    { value: "5*7", label: "5*7" },
    { value: "4*6", label: "4*6" },
    { value: "3.5*5", label: "3.5*5" },
  ];

  const handleOptionChange = (event) => {
    const { value } = event.target;
    setSelectedDocument(documents.filter((d) => d.id == value)[0]);
  };

  const handleUpdateSelectedDocument = (name, value) => {
    selectedDocument.config[name] = value;
    setSelectedDocument({ ...selectedDocument });
    setDocuments(
      documents.map((d) => (d.id == selectedDocument.id ? selectedDocument : d))
    );
  };

  const handleUpdateNumberOfCopies = (type) => {
    selectedDocument.config.copies =
      type == "inc"
        ? (parseInt(selectedDocument.config.copies) + 1).toString()
        : (parseInt(selectedDocument.config.copies) - 1).toString();
    setSelectedDocument({ ...selectedDocument });
    setDocuments(
      documents.map((d) => (d.id == selectedDocument.id ? selectedDocument : d))
    );
  };

  const handleUpdatePages = (page) => {
    if (selectedDocument.config.colorPar.pageNumbers.includes(page)) {
      let filteredList = selectedDocument.config.colorPar.pageNumbers.filter(
        (p) => p != page
      );
      selectedDocument.config.colorPar.pageNumbers = [...filteredList];
      setSelectedDocument({ ...selectedDocument });
      setDocuments(
        documents.map((d) =>
          d.id == selectedDocument.id ? selectedDocument : d
        )
      );
    } else {
      selectedDocument.config.colorPar.pageNumbers.push(page);
      setSelectedDocument({ ...selectedDocument });
      setDocuments(
        documents.map((d) =>
          d.id == selectedDocument.id ? selectedDocument : d
        )
      );
    }
  };

  if (!documents.length) return null;

  return (
    <Container>
      <form className={css.form}>
        <Grid container component={Paper} p={{ xs: 2, md: 5 }}>
          <Grid container spacing={2} sx={{ marginLeft: 0 }}>
            <Grid
              item
              xs={12}
              md={3}
              sx={{ textAlign: { xs: "center", md: "left" } }}
            >
              <Typography
                sx={{ fontSize: { xs: 20, md: 30 }, fontWeight: "bold" }}
              >
                SELECT FILES
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              sx={{
                textAlign: { xs: "center", md: "left" },
                marginLeft: { xs: -2, md: 0 },
              }}
            >
              <FormControl fullWidth sx={{ maxWidth: { xs: 300, md: "100%" } }}>
                <Select
                  value={selectedDocument.id}
                  onChange={handleOptionChange}
                  variant="outlined"
                  style={
                    selectedDocument !== null
                      ? { outline: "none", boxShadow: "0 0 0.5px blue" }
                      : null
                  }
                  size="small"
                >
                  {documents.map((file, index) => (
                    <MenuItem
                      key={index}
                      value={file.id}
                      style={
                        selectedDocument.id === file.id
                          ? { backgroundColor: "blue", color: "white" }
                          : null
                      }
                    >
                      <div className={css.listGap}></div>
                      <div className={css.listContentHolder}>
                        <div className={css.listContentFlex}>
                          <div className={css.listIcon}>
                            <UploadFileOutlined />
                          </div>
                          <div className={css.listContent}>
                            <div className={css.fileName}>{file.fileName}</div>
                            <div className={css.fileName}>
                              {storeUnitConverter(file.file.size)}(
                              {file.totalPages} Pages)
                            </div>
                          </div>
                        </div>
                      </div>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            {/* <Grid item xs={12} md={3}>
              <Typography
                sx={{
                  fontSize: 30,
                  marginLeft: { xs: 0, md: 7 },
                  fontWeight: "bold",
                }}>
                FILE {selectedDocument.id}{" "}
              </Typography>
            </Grid> */}
          </Grid>
          <Grid
            container
            spacing={2}
            sx={{
              marginLeft: 0,
              paddingBottom: 1,
              marginTop: 5,
              backgroundColor: "#eeeeee",
              borderRadius: "5px",
              padding: "5px 10px 10px 10px",
            }}
          >
            <Grid item xs={12} sm={6} md={4}>
              <div className={css.copiesHolder}>
                <FormLabel
                  className={css.mdRadioLabel}
                  id="demo-row-radio-buttons-group-label"
                >
                  Copies
                </FormLabel>
                <span className={css.required}>*</span>
                <br />
                <Box>
                  <IconButton onClick={() => handleUpdateNumberOfCopies("dec")}>
                    <Remove />
                  </IconButton>
                  <TextField
                    type="number"
                    value={selectedDocument.config.copies}
                    onChange={(e) =>
                      handleUpdateSelectedDocument("copies", e.target.value)
                    }
                    variant="outlined"
                    size="small"
                    sx={{ width: "4rem" }}
                  />
                  <IconButton onClick={() => handleUpdateNumberOfCopies("inc")}>
                    <Add />
                  </IconButton>
                </Box>
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <FormControl>
                <FormLabel
                  className={css.mdRadioLabel}
                  id="demo-row-radio-buttons-group-label"
                >
                  Sides<span className={css.required}>*</span>
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="sides"
                  value={selectedDocument.config.sides}
                  onChange={(e) =>
                    handleUpdateSelectedDocument("sides", e.target.value)
                  }
                >
                  <FormControlLabel
                    value="one"
                    control={<Radio />}
                    label="Single side"
                  />
                  <FormControlLabel
                    value="both"
                    control={<Radio />}
                    label="Both side"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <FormControl>
                <FormLabel
                  className={css.mdRadioLabel}
                  id="demo-row-radio-buttons-group-label"
                >
                  Printing Layout<span className={css.required}>*</span>
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="printLayout"
                  value={selectedDocument.config.printLayout}
                  onChange={(e) =>
                    handleUpdateSelectedDocument("printLayout", e.target.value)
                  }
                >
                  <FormControlLabel
                    value="Portrait"
                    control={<Radio />}
                    label="Portraint"
                  />
                  <FormControlLabel
                    value="LandScape"
                    control={<Radio />}
                    label="LandScape"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid>
          <Grid
            container
            spacing={2}
            sx={{ marginLeft: 0, marginTop: 5, backgroundColor: "white" }}
          >
            <Grid item xs={12} sm={6} md={4}>
              <FormControl>
                <FormLabel
                  className={css.mdRadioLabel}
                  id="demo-row-radio-buttons-group-label"
                >
                  Color<span className={css.required}>*</span>
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="color"
                  value={selectedDocument.config.colors.color}
                  onChange={(e) =>
                    handleUpdateSelectedDocument("colors", {
                      color: e.target.value,
                    })
                  }
                >
                  <FormControlLabel
                    value="bw"
                    control={<Radio />}
                    label="Black"
                  />
                  <FormControlLabel
                    value="multi"
                    control={<Radio />}
                    label="Color"
                  />

                  <FormControlLabel
                    value="colpar"
                    control={<Radio />}
                    label="ColPar"
                  />
                </RadioGroup>
              </FormControl>

              <FormControl>
                <FormLabel
                  className={css.mdRadioLabel}
                  id="demo-row-radio-buttons-group-label"
                >
                  Page Range (inclusive, from page{" "}
                  {selectedDocument.config.pageRange[0]} to page{" "}
                  {selectedDocument.config.pageRange[1]})
                  <span className={css.required}>*</span>
                </FormLabel>
                <Slider
                  getAriaLabel={() => "Temperature range"}
                  value={selectedDocument.config.pageRange}
                  onChange={(e) =>
                    handleUpdateSelectedDocument("pageRange", e.target.value)
                  }
                  valueLabelDisplay="auto"
                  getAriaValueText={valuetext}
                  max={selectedDocument.totalPages}
                  min={1}
                  size="small"
                />
              </FormControl>

              {selectedDocument.config.colors.color === "colpar" && (
                <div>
                  <div>
                    <FormControl>
                      <FormLabel
                        className={css.mdRadioLabel}
                        id="demo-row-radio-buttons-group-label"
                      >
                        Color pages
                      </FormLabel>

                      <TextField
                        id="filled-required"
                        variant="outlined"
                        label="Colo Pages"
                        name="colorQuantity"
                        value={selectedDocument.config.colorPar.pageNumbers.join(
                          ","
                        )}
                        disabled={true}
                        sx={{
                          m: 1,
                          minWidth: 227,
                          label: { fontSize: 12 },
                          label: { fontSize: 12 },
                        }}
                      ></TextField>
                    </FormControl>
                  </div>
                </div>
              )}
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              <FormControl>
                <FormLabel
                  className={css.mdRadioLabel}
                  id="demo-row-radio-buttons-group-label"
                >
                  Paper Size<span className={css.required}>*</span>
                </FormLabel>
                <Select
                  value={selectedDocument.config.paperSize}
                  onChange={(e) =>
                    handleUpdateSelectedDocument("paperSize", e.target.value)
                  }
                  label="Paper Size"
                  size="small"
                  sx={{ width: 300 }}
                  required
                >
                  {paperSizeOptions.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={4} sm={6}>
              <FormControl>
                <FormLabel
                  className={css.mdRadioLabel}
                  id="demo-row-radio-buttons-group-label"
                >
                  Bond Page<span className={css.required}>*</span>
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="bondPage"
                  value={selectedDocument.config.bondPage.selected}
                  onChange={(e) =>
                    handleUpdateSelectedDocument("bondPage", {
                      ...selectedDocument.config.bondPage,
                      selected: e.target.value,
                    })
                  }
                >
                  <FormControlLabel value="no" control={<Radio />} label="No" />
                  <FormControlLabel
                    value="yes"
                    control={<Radio />}
                    label="Yes"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            {selectedDocument.config.colors.color === "colpar" ? (
              <Grid item xs={12}>
                <Typography>Select page/pages</Typography>
                <PreviewPages
                  selectedDocument={selectedDocument}
                  onUpdatePageRange={handleUpdateSelectedDocument}
                  pageRange={selectedDocument.config.pageRange}
                  selectedPages={selectedDocument.config.colorPar.pageNumbers}
                  onPageSelect={handleUpdatePages}
                  file={selectedDocument.file}
                />
              </Grid>
            ) : null}
          </Grid>
          <Grid
            container
            spacing={2}
            mt={5}
            sx={{
              marginLeft: 0,
              marginTop: 5,
              backgroundColor: "#eeeeee",
              borderRadius: "5px",
              padding: "0 10px",
            }}
          >
            <Grid item xs={12} sm={6} md={7}>
              <FormControl>
                <FormLabel
                  className={css.mdRadioLabel}
                  id="demo-row-radio-buttons-group-label"
                >
                  Binding<span className={css.required}>*</span>
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="binding"
                  value={selectedDocument.config.binding}
                  onChange={(e) =>
                    handleUpdateSelectedDocument("binding", e.target.value)
                  }
                >
                  <Grid item xs={6} md={6}>
                    <FormControlLabel
                      value="Spiral"
                      control={<Radio />}
                      label="Spiral"
                    />
                  </Grid>
                  <Grid item xs={6} md={6}>
                    <FormControlLabel
                      value="Staples"
                      control={<Radio />}
                      label="Staples"
                    />
                  </Grid>
                  <Grid item xs={6} md={6}>
                    <FormControlLabel
                      value="StickFile"
                      control={<Radio />}
                      label="Stick File"
                    />
                  </Grid>
                  <Grid item xs={6} md={6}>
                    <FormControlLabel
                      value="noBinding"
                      control={<Radio />}
                      label="No binding"
                    />
                  </Grid>
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={5}>
              <FormControl fullWidth>
                <FormLabel
                  className={css.mdRadioLabel}
                  id="demo-row-radio-buttons-group-label"
                >
                  Additional Instructions<span className={css.required}>*</span>
                </FormLabel>
                <TextareaAutosize
                  name="instructions"
                  value={selectedDocument.config.instructions}
                  onChange={(e) =>
                    handleUpdateSelectedDocument("instructions", e.target.value)
                  }
                  placeholder="Add Instructions"
                  aria-label="empty textarea"
                  style={{
                    width: { xs: "100%", md: "86%" },
                    height: 50,
                    marginTop: 5,
                  }}
                />
              </FormControl>
            </Grid>
          </Grid>
          <Grid container mt={5}>
            <Grid item xs={12}>
              <div
                className={css.btnContainer}
                sx={{ textAlign: { xs: "center" } }}
              >
                <Button onClick={handlePrevious} variant="contained">
                  Back
                </Button>

                <Button
                  onClick={handleSelectStore}
                  type="button"
                  variant="contained"
                >
                  select store
                </Button>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </Container>
  );
};

export default PrintConfig;
