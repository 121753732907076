import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import AppRouter from "./routes/AppRouter";
import "react-toastify/dist/ReactToastify.css";
import { useState } from "react";
import EmployeePermission from "./context/employeePermission";

function App() {
  const [employeePermission, setEmployeePermission] = useState(null);

  return (
    <EmployeePermission.Provider
      value={{
        employeePermission,
        setEmployeePermission,
      }}
    >
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <BrowserRouter>
          <AppRouter />
          <ToastContainer />
        </BrowserRouter>
      </LocalizationProvider>
    </EmployeePermission.Provider>
  );
}

export default App;
