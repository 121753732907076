import * as React from "react";
import {
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  Link,
  MenuItem,
  Paper,
  Rating,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";

import css from "../styles/pages/Orders.module.css";
import { useEffect } from "react";
import { get, post, put } from "../api/services/apiMethods";
import urls from "../api/constants";
import { useState } from "react";
import dayjs from "dayjs";
import PaginationComponent from "../components/Pagination";
import { API_PREFIX } from "../api/client";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { LoadingButton } from "@mui/lab";

const steps = ["Order accepted", "Printing", "Ready to pickup"];
const data = [
  {
    orderId: "779879876",
    date: "10-10-2022/10:20 AM",
    cost: 15,
  },
  {
    orderId: "779879875",
    date: "12-11-2022/10:20 AM",
    cost: 70,
  },
];
const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const StoreOrders = () => {
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = React.useState(1);
  const [modalOpen, setModalOpen] = React.useState(false);
  const [modalData, setModalData] = React.useState(undefined);
  const [orders, setOrders] = useState([]);
  const [count, setCount] = useState(0);
  const [filterForm, setFilterForm] = useState({});
  const [isSearch, setIsSearch] = useState(false);
  const [resetPag, setResetPag] = useState(false);
  const [ratingStar, setRatingStar] = useState({});
  const [gettingOrderDetails, setGettingOrderDetails] = useState(false);
  const [deletingOrder, setDeletingOrder] = useState(false);
  const [deleteOrderModal, setDeleteOrderModal] = useState({
    open: false,
    orderId: null,
  });

  const openDeleteModal = (orderId) => {
    setDeleteOrderModal({
      open: true,
      orderId,
    });
  };

  const handleCloseDeleteModal = () => {
    setDeleteOrderModal({
      open: false,
      orderId: null,
    });
  };

  const getOrders = async () => {
    try {
      const { data: orders, status } = await get(`${urls.orders.list}`, {
        params: {
          limit: 25,
          page: 1,
        },
      });
      if (status > 199 && status < 299) {
        setOrders(orders.result.data);
        setCount(orders.result.count);
      }
    } catch (error) {}
  };

  useEffect(() => {
    getOrders();
  }, []);
  useEffect(() => {
    if (resetPag) {
      setResetPag(false);
    }
  }, [resetPag]);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleModalClose = () => {
    setModalOpen(false);
    setModalData(undefined);
  };
  const handleModalOpen = (dataIndex) => {
    const inject = orders.find((d, index) => dataIndex === index);
    setModalData(inject);
    setModalOpen(true);
  };
  const handleStatusChange = async (id, orderStatus) => {
    try {
      const { status } = await put(`${urls.orders.update}${id}`, {
        status: orderStatus,
      });
      if (status > 199 && status < 299) {
        const { data: orders, status } = await get(`${urls.orders.list}`);
        if (status > 199 && status < 299) setOrders(orders.result.data);
      }
    } catch (error) {}
  };
  const handlePageChange = async (page) => {
    try {
      const { data: orders, status } = await get(`${urls.orders.list}`, {
        params: {
          limit: 25,
          page: page,
        },
      });
      if (status > 199 && status < 299) {
        setOrders(orders.result.data);
      }
    } catch (error) {}
  };
  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilterForm((filter) => ({
      ...filter,
      [name]: value,
    }));
    if (isSearch) {
      setResetPag(true);
    }
  };
  const undoFilter = async () => {
    try {
      const { data: orders, status } = await get(`${urls.orders.list}`, {
        params: {
          limit: 25,
          page: 1,
        },
      });
      if (status > 199 && status < 299) {
        setOrders(orders.result.data);
        setCount(orders.result.count);
      }
    } catch (error) {}
    setFilterForm({});
    setIsSearch(false);
    setResetPag(true);
  };
  const handleFilterSubmit = async (e) => {
    e.preventDefault();
    try {
      const { data: orders, status } = await get(`${urls.orders.list}`, {
        params: {
          status: filterForm.status,
          limit: 25,
          page: 1,
        },
      });
      if (status > 199 && status < 299) {
        setOrders(orders.result.data);
        setCount(orders.result.count);
        setIsSearch(true);
        setResetPag(true);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const displayOrderText = (item) => {
    let text = "";
    if (item.color === "bw") {
      text += "black/white-";
    } else {
      text += "color-";
    }
    if (item.paperSize === "a4") {
      text += "A4-";
    } else {
      text += "A5-";
    }
    if (item.paperQuality === "100gsm") {
      text += "100gsm-";
    } else {
      text += "80gsm-";
    }
    if (item.binding === "Spiral") {
      text += "Spiral";
    } else if (item.binding === "Staples") {
      text += "Staples";
    } else {
      text += "No binding";
    }
    return text;
  };
  const getItemsInText = (item, field) => {
    if (field === "color") {
      return item.color === "bw" ? "black/white" : "color";
    }
    if (field === "paperSize") {
      return item.paperSize === "a4" ? "A4" : "A5";
    }
    if (field === "paperQuality") {
      return item.paperQuality === "100gsm" ? "100gsm" : "80gsm";
    }
    if (field === "binding") {
      return item.binding === "Spiral" ? "Spiral" : "Staples";
    }
  };
  const handleRatingChange = (val, id) => {
    setRatingStar((prev) => ({
      ...prev,
      [id]: val,
    }));
  };
  const handleSubmitFeedback = async (e, id, order) => {
    e.preventDefault();
    try {
      const data = {
        rating: ratingStar[id],
        review: e.target.comment.value,
      };
      const { data: review, status } = await post(
        `/store/addReview/${[order.storeId, order._id]}`,
        data
      );
      if (status > 199 && status < 299) {
        setOrders((prev) =>
          prev.map((order) =>
            order._id === id ? { ...order, feedBack: data } : { ...order }
          )
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handlePayment = async (order) => {
    try {
      if (!order.orderLater || order.isPaid)
        return alert("This order is already paid!");
      const cost = order.totalCost.toString() + "00";
      const data = {
        amount: Number(cost),
        orderId: order._id,
        receipt: "cnlna",
      };
      setGettingOrderDetails(true);
      const { data: payOrder, status } = await post(
        `${urls.payment.order}`,
        data
      );
      setGettingOrderDetails(false);

      if (status > 199 && status < 299) {
        var options = {
          key: "rzp_live_p6Q0b5huVYWl7D", // Enter the Key ID generated from the Dashboard
          amount: payOrder.result.amount, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
          currency: "INR",
          name: "I2N Technologies", //your business name
          description: "Test Transaction",
          order_id: payOrder.result.paymentId, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
          callback_url: `${API_PREFIX}/payment/confirm`,
          // prefill: {
          //   name: "Gaurav Kumar", //your customer's name
          //   email: "gaurav.kumar@example.com",
          //   contact: "9000090000",
          // },
          notes: {
            // address: "Razorpay Corporate Office",
          },
          theme: {
            color: "#3399cc",
          },
          handler: async function (response) {
            const { data: orderPayed, status } = await post(
              `${urls.orders.payment}`,
              response
            );
            if (status > 199 && status < 299) {
              if (orderPayed && orderPayed.signatureIsValid) {
                navigate("/user/order-successful");
              } else {
                toast.error("Something went wrong, Try again later");
              }
            }
          },
          modal: {
            ondismiss: async function () {
              await put(`${urls.orders.update}${order.result._id}`, {
                status: "failed",
              });
            },
          },
        };
        const rzp1 = new window.Razorpay(options);
        rzp1.open();
        rzp1.on("payment.failed", function (response) {
          console.log("Payment", response);
        });
      }
    } catch (error) {
      console.log(error);
    }
    setGettingOrderDetails(false);
  };

  const handleDeleteOrder = async () => {
    setDeletingOrder(true);
    try {
      const { data } = await put(urls.orders.del + deleteOrderModal.orderId);
      getOrders();
      setDeleteOrderModal({ open: false, orderId: null });
    } catch (error) {
      console.log(error);
    }
    setDeletingOrder(false);
  };

  return (
    <div className={css.container} style={{ margin: "0 10px" }}>
      <form className={css.filterContainer} onSubmit={handleFilterSubmit}>
        <FormControl sx={{ width: "20%" }}>
          <InputLabel id="demo-simple-select-label">Status</InputLabel>
          <Select
            value={filterForm.status || ""}
            label="Status"
            name="status"
            size="small"
            required
            fullWidth
            onChange={handleFilterChange}
          >
            <MenuItem value={"Pending"}>Pending</MenuItem>
            <MenuItem value={"accepted"}>Accept</MenuItem>
            <MenuItem value={"printing"}>Printing</MenuItem>
            <MenuItem value={"ready"}>Ready</MenuItem>
            <MenuItem value={"failed"}>Failed</MenuItem>
            <MenuItem value={"collected"}>Collected</MenuItem>
          </Select>
        </FormControl>
        <Button variant="contained" type="submit" size="small">
          search
        </Button>
        {isSearch && (
          <Button
            variant="contained"
            type="button"
            size="small"
            onClick={undoFilter}
          >
            Reset
          </Button>
        )}
      </form>
      {orders.map((order, index) => (
        <Card key={order._id} className={css.cardContainer}>
          <CardContent>
            <div className={css.orderDetailsContainer}>
              <div className={css.orderId}>{order.orderId}</div>
              <div className={css.date}>
                {dayjs(order.createdAt).format("DD-MM-YYYY")}
              </div>
              <div className={css.cost}>₹{order.totalCost}</div>
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  columnGap: "10px",
                  rowGap: "10px",
                  alignItems: "center",
                  marginottom: "5px",
                }}
              >
                {order.orderLater == true && !order.isPaid ? (
                  <>
                    <LoadingButton
                      variant="outlined"
                      loading={deletingOrder}
                      onClick={() => openDeleteModal(order._id)}
                      color="error"
                      size="small"
                    >
                      Delete Order
                    </LoadingButton>
                    <LoadingButton
                      loading={gettingOrderDetails}
                      onClick={() => handlePayment(order)}
                      size="small"
                      variant="contained"
                      sx={{
                        backgroundColor: "green",
                      }}
                    >
                      Pay to place order
                    </LoadingButton>
                  </>
                ) : null}
                <Button
                  onClick={() => handleModalOpen(index)}
                  size="small"
                  variant="contained"
                  // sx={{ marginBottom: "5px" }}
                >
                  VIEW ORDER DETAILS
                </Button>
              </Box>
            </div>
            <Box sx={{ width: "100%" }}>
              <Stepper
                activeStep={
                  (order.status === "Pending" && -1) ||
                  (order.status === "accepted" && 1) ||
                  (order.status === "printing" && 2) ||
                  (order.status === "ready" && 3)
                }
              >
                {steps.map((label, index) => {
                  return (
                    <Step key={label}>
                      <StepLabel>{label}</StepLabel>
                      {order.status === "failed" && (
                        <Typography variant="caption" color="error">
                          Order failed
                        </Typography>
                      )}
                    </Step>
                  );
                })}
              </Stepper>
            </Box>
            {!order.feedBack ? (
              <div className={css.feedBackContainer}>
                <div>
                  <Rating
                    disabled={!order.isPaid}
                    onChange={(e, val) => handleRatingChange(val, order._id)}
                    value={ratingStar[order._id] || 0}
                  />
                </div>
                <div>
                  <form
                    onSubmit={(e) => handleSubmitFeedback(e, order._id, order)}
                  >
                    <div className={css.commentContainer}>
                      <TextField
                        disabled={!order.isPaid}
                        name="comment"
                        label="Review"
                        fullWidth
                        size="small"
                      />
                      <Button
                        disabled={!order.isPaid}
                        type="submit"
                        size="small"
                        variant="contained"
                      >
                        ADD
                      </Button>
                    </div>
                  </form>
                </div>
              </div>
            ) : (
              <div className={css.feedBackContainer}>
                <div>
                  <Rating value={order.feedBack.rating} readOnly />
                </div>
                <div>{order.feedBack.review}</div>
              </div>
            )}
          </CardContent>
        </Card>
      ))}
      {orders && !orders.length && (
        <h1 style={{ textAlign: "center" }}>No Orders</h1>
      )}
      <PaginationComponent
        page={resetPag && 1}
        limit={25}
        count={count}
        handlePageChange={handlePageChange}
      />
      <Dialog open={modalOpen} onClose={handleModalClose} scroll="paper">
        <DialogTitle>Order details</DialogTitle>
        <DialogContent dividers={true}>
          {modalData && (
            // <div>{modalData._id}</div>
            <div className={css.modalContainer}>
              {modalData.items.map((item, index) => (
                <Paper elevation={2} className={css.fileConfigContainer_paper}>
                  <div className={css.fileConfigContainer}>
                    <div>File {index + 1}</div>
                    <div>
                      File url :
                      <Link href={item.documents} title="Download file">
                        Download
                      </Link>
                    </div>

                    <div>
                      Xerox color:{" "}
                      {item.colors.color === "color"
                        ? "Color"
                        : item.colors.color === "colpar"
                        ? "Multi-Color"
                        : "Black&White"}
                    </div>
                    <div>
                      Pages to Print : {item.pageRange[0]} to{" "}
                      {item.pageRange[1]}
                    </div>
                    {item.colors.color === "colpar" ? (
                      <div>
                        <div>
                          Total Black Pages :{" "}
                          {item.totalPages - item.colorPar.pageNumbers.length}{" "}
                        </div>
                        <div>
                          Total Color Pages : {item.colorPar.pageNumbers.length}{" "}
                        </div>
                        <div>
                          Color Page Nos. to print :{" "}
                          {item.colorPar.pageNumbers.join(",")}{" "}
                        </div>
                      </div>
                    ) : null}
                    <div>Instructions : {item.instructions || "NA"}</div>
                    <div>
                      Sides to print on :{" "}
                      {item.side === "one" ? "Single" : "Double"}
                    </div>
                    <div>Paper size: {getItemsInText(item, "paperSize")}</div>
                    <div>Binding: {getItemsInText(item, "binding")}</div>
                    <div>Copies: {item.copies}</div>

                    <div>Cost:{item.cost} </div>
                  </div>
                </Paper>
              ))}
              <Paper elevation={2} className={css.totalCostPaper}>
                <div>Total Cost:{modalData.totalCost}</div>
                <div>Status:{modalData.status}</div>
              </Paper>
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button color="warning" onClick={() => setModalOpen(false)}>
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={deleteOrderModal.open}
        onClose={handleCloseDeleteModal}
        scroll="paper"
      >
        <DialogTitle>Delete Order</DialogTitle>
        <DialogContent sx={{ borderBottom: "1px solid lightgray" }}>
          <Typography>Are you sure you want to delete this order?</Typography>
        </DialogContent>
        <DialogActions>
          <Button color="info" onClick={handleCloseDeleteModal}>
            Cancel
          </Button>
          <Button color="error" onClick={handleDeleteOrder}>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
export default StoreOrders;
