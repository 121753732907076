import { AttachFile, Clear, UploadFileOutlined } from "@mui/icons-material";
import {
  Button,
  Card,
  CardContent,
  CircularProgress,
  IconButton,
  Typography,
} from "@mui/material";
import React, {
  Children,
  Fragment,
  useContext,
  useEffect,
  useState,
} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import css from "../styles/pages/Print.module.css";
import { get, put } from "../api/services/apiMethods";
import { toast } from "react-toastify";
import { storeUnitConverter } from "../utils/utils";
import PrintContext from "./PrintContex";

const Print = () => {
  const { files, setFiles } = useContext(PrintContext);

  const [btnLoading, setBtnLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [name, setName] = useState("");

  console.log(location.state, "state......");

  useEffect(() => {
    if (!location.state) {
      setFiles([]);
    }
  }, [location.state]);

  useEffect(() => {
    if (location.state && location.state.documents) {
      setFiles([...location.state.documents]);
    }
  }, [location.state, files]);

  const handleFileAdd = async (e) => {
    setFiles((prev) => [...prev, ...e.target.files]);
  };
  const handleRemoveFile = (removeIndex) => {
    setFiles(files.filter((ele, index) => index !== removeIndex));
  };

  const handleDocsUpload = async (event) => {
    try {
      setBtnLoading(true);
      if (!files.length) throw new Error("Please select files");
      const documents = [];
      let totalPages = 0;
      const data = [];

      for (const file of files) {
        let newItem = {};
        newItem.id = data.length + 1;
        newItem.file = file;
        newItem.config = {
          copies: 1,
          pageRange: [],
          sides: "one",
          printLayout: "Portrait",
          colors: {
            color: "bw",
          },
          colorPar: {
            description: "",
            total: 0,
            pageNumbers: [],
          },
          paperSize: "a4",
          bondPage: {
            selected: "no",
            description: "",
            total: 0,
          },
          binding: "noBinding",
          instructions: "",
        };
        const signed = await get(`/cloud-services/file/getSignedUrl`, {
          params: {
            fileName: file.name,
          },
        });
        console.log(signed.data.result.filePath, "signed file path");
        documents.push(signed.data.result.filePath);

        newItem.filePath = signed.data.result.filePath;

        if (signed.statusText === "OK") {
          const upload = await put(signed.data.result.signedUrl, file, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          });
          if (upload.status === 200) {
            const downloadable = await get(
              `/cloud-services/file/getDownloadableUrl`,
              {
                params: {
                  filePath: signed.data.result.filePath,
                },
              }
            );
            console.log(downloadable, "downloadable");
            newItem.totalPages = downloadable.data.custom.pagesPdf;
            newItem.document = downloadable.data.result;
            newItem.fileName = file.name;
            newItem.config.pageRange = [1, newItem.totalPages];
            if (downloadable.status === 200) {
              totalPages += downloadable.data.custom.pagesPdf;
            }

            data.push(newItem);
          }
        }
      }
      navigate("/user/print-config", {
        state: {
          documents: documents,
          totalPages,
          files: files,
          data,
        },
      });
    } catch (error) {
      setBtnLoading(false);
      toast.error(error.message ? error.message : "Try again later");
    }
  };

  return (
    <div className={css.printContainer}>
      <div className={css.printFlex}>
        <div className={css.header}>Upload documents to print</div>
        <Card className={css.cardContainer}>
          <CardContent sx={{ padding: 0 }}>
            <div className={css.uploadContainer}>
              <IconButton
                color="primary"
                size="large"
                aria-label="upload picture"
                component="label"
              >
                <input
                  onChange={handleFileAdd}
                  hidden
                  type="file"
                  multiple
                  accept="image/*,application/pdf,.doc,.docx"
                />
                <div>
                  <div>
                    <AttachFile />
                  </div>
                  <div style={{ fontSize: "1rem" }}>Click to upload</div>
                </div>
              </IconButton>
              <Typography variant="overline"> PDF Only</Typography>
            </div>
            <div className={css.fileListContainer}>
              <div className={css.fileList}>
                <div className={css.fileListItem}>
                  {files.map((file, index) => (
                    <Fragment key={index}>
                      <div className={css.listGap}></div>
                      <div className={css.listContentHolder}>
                        <div className={css.listContentFlex}>
                          <div className={css.listIcon}>
                            <UploadFileOutlined />
                          </div>
                          <div className={css.listContent}>
                            <div className={css.fileName}>{file.name}</div>
                            <div className={css.fileSize}>
                              {storeUnitConverter(file.size)}
                            </div>
                          </div>
                        </div>
                        <div
                          onClick={() => handleRemoveFile(index)}
                          className={css.cancelBtn}
                        >
                          <IconButton>
                            <Clear />
                          </IconButton>
                        </div>
                      </div>
                    </Fragment>
                  ))}
                </div>
              </div>
            </div>
          </CardContent>
        </Card>
        <div className={css.btnHolder}>
          <Button
            sx={{ width: "70px" }}
            disabled={btnLoading}
            onClick={handleDocsUpload}
            variant="contained"
          >
            {btnLoading ? <CircularProgress size={20} /> : "Next"}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Print;
