import {
  AppBar,
  Button,
  Drawer,
  //   IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import Avatar from "@mui/material/Avatar";
import Logo from "./image/logo.jpg";

import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import React, { useState, useEffect } from "react";
import { Link, Outlet, useNavigate } from "react-router-dom";
import { styled, useTheme } from "@mui/material/styles";

// css imports
import navMenu from "../styles/UserNavDrawer.module.css";
import {
  AccountCircle,
  Inventory,
  MenuBook,
  ShoppingCart,
} from "@mui/icons-material";
import { toast } from "react-toastify";
import { objHas } from "../utils/utils";

const UserBar = () => {
  const [navOpen, setNavOpen] = useState(true);
  const [anchorEl, setAnchorEl] = useState(false);
  const [settings, setSettings] = useState(null);
  const theme = useTheme();

  const btnMenu = anchorEl;

  const navigate = useNavigate();

  const handleBtnMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleBtnMenuClose = () => setAnchorEl(false);

  const handleMenuOpen = () => setNavOpen(true);
  const handleMenuClose = () => setNavOpen(false);

  const handleLogout = () => {
    window.localStorage.removeItem("access_token");
    window.localStorage.removeItem("refresh_token");
    window.localStorage.removeItem("permission");
    navigate("/login");
    toast.success("Your logged out");
  };
  useEffect(() => {
    const logoURL = require("./image/logo.jpg");
    setSettings({ ...settings, logo: { link: logoURL } });
  }, []);
  // components
  const list = () => {
    return (
      <Box
        sx={{ width: 250 }}
        role="presentation"
        onClick={handleMenuClose}
        onKeyDown={handleMenuClose}
      >
        <List>
          {[
            { label: "Place order", icon: <ShoppingCart />, to: "/user/print" },
            { label: "Orders", icon: <Inventory />, to: "/user/orders" },
            // { label: "Stationery", icon: <MenuBook />, to: "/user/stationery" },
          ].map((list, index) => (
            <Link
              key={index}
              style={{ color: "inherit", textDecoration: "none" }}
              to={list.to}
            >
              <ListItem key={list.label} disablePadding>
                <ListItemButton>
                  <ListItemIcon>{list.icon}</ListItemIcon>
                  <ListItemText primary={list.label} />
                </ListItemButton>
              </ListItem>
            </Link>
          ))}
        </List>
      </Box>
    );
  };
  return (
    <Box className={navMenu.navMenuContainer}>
      <div>
        <Drawer anchor="left" open={navOpen} onClose={handleMenuClose}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div>
              {settings && (
                <img
                  src={Logo}
                  alt="logo"
                  style={{
                    width: "90px", // Adjust the width here
                    marginLeft: "60px",
                    marginTop: "20px",
                  }}
                />
              )}
            </div>
            <IconButton
              onClick={handleMenuClose}
              style={{ marginLeft: "40px", marginTop: "20px" }}
            >
              {theme.direction === "rtl" ? (
                <ChevronRightIcon />
              ) : (
                <ChevronLeftIcon />
              )}
            </IconButton>
          </div>
          <div
            style={{
              marginTop: "20px",
              backgroundColor: "#e1e3e1",
              color: "red",
              height: "1px",
            }}
          ></div>
          {list()}
        </Drawer>
      </div>
      <div>
        <Box sx={{ flexGrow: 1 }}>
          <AppBar sx={{ backgroundColor: "#FFFFFF" }} position="fixed">
            <Toolbar>
              <IconButton
                size="large"
                edge="start"
                color="inherit"
                aria-label="menu"
                sx={{ mr: 2, color: "#005A5F" }}
              >
                <img height={50} width={100} src={Logo} alt="" />
              </IconButton>
              <IconButton
                size="large"
                edge="start"
                color="inherit"
                aria-label="menu"
                sx={{ mr: 2, color: "#005A5F" }}
                onClick={handleMenuOpen}
              >
                <MenuIcon />
              </IconButton>
              {/* <div>
                {settings && (
                  <img
                    src={settings && objHas(settings, "logo.link", "")}
                    alt="logo"
                    style={{
                      width: "90px", // Adjust the width here

                    }}
                  />
                )}
              </div> */}

              <Typography
                variant="h6"
                component="div"
                sx={{ flexGrow: 1 }}
              ></Typography>
              <div
                style={{
                  display: "flex",
                  gap: "1rem",
                  // alignItems: "center",
                }}
              >
                {/* {list()} */}

                <Link
                  style={{
                    textDecoration: "none",
                  }}
                  to={"/user/print"}
                >
                  <Button
                    size="small"
                    variant="contained"
                    sx={{
                      backgroundColor: "#005A5F",
                      ":hover": {
                        backgroundColor: "#005A5F",
                      },
                      marginTop: { md: 1.3, xs: 1.5 },
                      fontSize: { xs: "10px" },
                    }}
                  >
                    Place order
                  </Button>
                </Link>
                <IconButton
                  id="basic-button"
                  aria-controls={btnMenu ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={btnMenu ? "true" : undefined}
                  onClick={handleBtnMenuClick}
                  edge="start"
                >
                  <AccountCircle sx={{ fontSize: "35px" }} />
                </IconButton>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={btnMenu ? true : false}
                  onClose={handleBtnMenuClose}
                  autoFocus
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >
                  <MenuItem onClick={handleLogout}>Logout</MenuItem>
                </Menu>
              </div>
            </Toolbar>
          </AppBar>
        </Box>
      </div>
      <div
        style={{
          paddingTop: "5rem",
          paddingBottom: "1rem",
        }}
      >
        <Outlet />
      </div>
    </Box>
  );
};
export default UserBar;
