import { Visibility, VisibilityOff } from "@mui/icons-material";

import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
  styled,
} from "@mui/material";

import React, { useState, useEffect } from "react";

import { Link, useNavigate } from "react-router-dom";

import authentication from "../api/services/authentication";

import css from "../styles/pages/Login.module.css";

import { objPropertyCheck } from "../utils/utils";

import { objHas } from "../utils/utils";

const PoweredByContainer = styled(Box)(() => ({
  backgroundColor: "white",
  position: "fixed",
  bottom: 0,
  display: "flex",
  width: "100%",
  flex: 1,
  padding: 2,
  justifyContent: {
    lg: "flex-end",
    md: "flex-end",
    sm: "center",
    xs: "center",
  },
  margin: "auto",
}));

const Login = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({});
  const [formError, setFormError] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [resError, setResError] = useState("");
  const [settings, setSettings] = useState(null);

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  useEffect(() => {
    const logoURL = require("./image/logo3.jpg");
    setSettings({ ...settings, logo: { link: logoURL } });
  }, []);
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!objPropertyCheck(formData, ["email", "password"]))
      return setFormError(true);
    try {
      const data = {
        email: formData.email,
        password: formData.password,
      };
      const loginRes = await authentication.login(data);
      if (loginRes.status < 200 || loginRes.status > 299)
        return setResError(loginRes.data.message);
      window.localStorage.setItem(
        "access_token",
        loginRes.data.result.access_token
      );
      window.localStorage.setItem(
        "refresh_token",
        loginRes.data.result.refresh_token
      );
      const userStringify = JSON.stringify(loginRes.data.result.user);
      window.localStorage.setItem("user", userStringify);
      navigate("/user/print");
    } catch (error) {
      setResError(error.response.data.message);
    }
  };
  return (
    <div className={css.loginContainer}>
      <div className={css.flexContainer}>
        <div>
          {settings && (
            <img
              src={settings && objHas(settings, "logo.link", "")}
              alt="logo"
              style={{
                width: "220px",
                marginLeft: "-5px",
                marginTop: "-10px",
              }}
            />
          )}
        </div>
        <div style={{ marginTop: "60px" }}>
          <div className={css.header}>Login</div>
          <div className={css.subHeader}>
            Dont Have an Account?{" "}
            <Link to={"/signup"}>Create your account</Link>
          </div>
          <div className={css.error}>{resError && resError}</div>
          <div className={css.formContainer}>
            <form onSubmit={handleSubmit}>
              <div className={css.formHolder}>
                <TextField
                  error={formError && !formData.email}
                  fullWidth
                  onChange={handleFormChange}
                  value={formData.email || ""}
                  name="email"
                  label="Email"
                  variant="outlined"
                  sx={{
                    backgroundColor: "#F5F5F5",
                  }}
                />
                {formError && !formData.email && (
                  <span style={{ color: "red" }}>This field is required</span>
                )}
              </div>
              <div className={css.formHolder}>
                <TextField
                  type={showPassword ? "text" : "password"}
                  error={formError && !formData.password}
                  fullWidth
                  onChange={handleFormChange}
                  value={formData.password || ""}
                  name="password"
                  label="Password"
                  variant="outlined"
                  sx={{
                    backgroundColor: "#F5F5F5",
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          edge="end"
                          aria-label="toggle password visibility"
                          onClick={() => setShowPassword(!showPassword)}
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                {formError && !formData.password && (
                  <span style={{ color: "red" }}>This field is required</span>
                )}
              </div>
              <div className={css.textBtnContainer}>
                <div className={css.forgotText}>
                  <Link to="/forgot-password">Forgot Password?</Link>
                </div>

                <div className={css.btn}>
                  <Button variant="contained" type="submit">
                    LOGIN
                  </Button>
                </div>
              </div>
            </form>
          </div>
        </div>

        <div
          style={{
            width: "100%",
            maxWidth: "400px",
            marginTop: "20px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            flexWrap: "wrap",
          }}
        >
          <div style={{ minWidth: "150px" }} className={css.termsText}>
            <Link to="/privacy-policy">Privacy Policy</Link>
          </div>
          <div style={{ minWidth: "150px" }} className={css.termsText}>
            <Link to="/terms-condition">Terms and Condition</Link>
          </div>
        </div>

        <div style={{ position: "absolute", top: 70, right: 60 }}>
          <Button
            variant="contained"
            type="submit"
            onClick={() => navigate("/employee-login")}
          >
            Employee Login
          </Button>
        </div>
      </div>
      <Box
        sx={{
          backgroundColor: "white",
          position: "fixed",
          bottom: 0,
          display: "flex",
          width: "100%",
          flex: 1,
          padding: 2,
          justifyContent: {
            lg: "flex-end",
            md: "flex-end",
            sm: "center",
            xs: "center",
          },
          margin: "auto",
        }}
      >
        <Typography sx={{ fontSize: "14px", color: "gray" }}>
          Powered by{" "}
          <Link
            to="https://i2ntech.in"
            target="_blank"
            style={{
              fontSize: "14px",
              color: "dodgerblue",
              textDecoration: "none",
            }}
          >
            I2N TECHNOLOGIES
          </Link>
        </Typography>
      </Box>
    </div>
  );
};

export default Login;
