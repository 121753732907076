import React, { useEffect, useState } from "react";

// styles
import css from "../styles/pages/StoreRole.module.css";
import { del, get, post, put } from "../api/services/apiMethods";
import urls from "../api/constants";
import TableFunctions from "../components/TableFunctions";
import TableData from "../components/TableData";
import { useForm } from "react-hook-form";
import PaginationComponent from "../components/Pagination";
import { Box, Button, Grid, Modal, TextField } from "@mui/material";
import { toast } from "react-toastify";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
const deleteModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const StoreRole = () => {
  const [roles, setRoles] = useState([]);
  const [isSearch, setIsSearch] = useState(true);
  const [dataCount, setDataCount] = useState(0);
  const [addModalOpen, setAddModalOpen] = useState(false);
  const [edit, setEdit] = useState("");
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteStore, setDeleteStore] = useState("");

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
  } = useForm();

  useEffect(() => {
    const getRoles = async () => {
      try {
        const { data: roles } = await get(`${urls.roles.list}`);
        setRoles(roles.result.data);
        console.log(roles, "============");
      } catch (error) {}
    };
    getRoles();
  }, []);
  const handleAddModalOpen = () => setAddModalOpen(true);
  const getData = async () => {
    try {
      const { data: roles } = await get(`${urls.roles.list}`, {
        params: {
          limit: 10,
          page: 1,
        },
      });
      setRoles(roles.result.data);
      setDataCount(roles.result.count);
    } catch (error) {}
  };
  const undoSearch = async () => {
    setIsSearch(false);
    await getData();
  };
  const handleSearch = async (search) => {
    try {
      const searchRoles = await get(`${urls.roles.list}`, {
        params: {
          search: search,
        },
      });
      setRoles(searchRoles.data.result.data);
      setIsSearch(true);
    } catch (error) {}
  };
  const handleStoreEdit = async (id, data) => {
    try {
      setEdit(id);
      setValue("role", data.role);
      setAddModalOpen(true);
    } catch (error) {}
  };
  const handleDeleteModalOpen = () => {
    setDeleteModal(true);
  };
  const handlePageChange = async (page) => {
    try {
      const { data: roles } = await get(
        `${urls.roles.list}`,
        {},
        {
          params: {
            limit: 10,
            page: page,
          },
        }
      );
      setRoles(roles.result.data);
    } catch (error) {}
  };
  const handleAddModalClose = () => {
    reset();
    setAddModalOpen(false);
    setEdit("");
  };
  const onSubmit = async (formData) => {
    try {
      const body = {
        role: formData.role,
      };
      if (!edit) {
        const { data: role, status } = await post(`${urls.roles.create}`, body);
        if (status > 199 && status < 299) {
          const { data: roleList } = await post(`${urls.roles.list}`);
          setRoles(roleList.result.data);
          handleAddModalClose();
          toast.success("New Store created");
        }
      } else {
        const { data: role, status } = await put(
          `${urls.roles.update}${edit}`,
          body
        );
        if (status > 199 && status < 299) {
          const { data: roleList } = await get(`${urls.roles.list}`);
          setRoles(roleList.result.data);
          handleAddModalClose();
          toast.success("Store updated successfully");
        }
      }
    } catch (error) {
      toast.error("Something went wrong try again later");
    }
  };
  const handleDeleteModalClose = () => {
    setDeleteModal(false);
    setDeleteStore("");
  };
  const handleDeleteStore = async (roleId) => {
    try {
      const { status } = await del(`${urls.roles.del}${roleId}`);
      if (status > 199 && status < 299) {
        setRoles((prevState) =>
          prevState.filter((role) => role._id !== roleId)
        );
        handleDeleteModalClose();
      }
    } catch (error) {}
  };
  return (
    <div className={css.container}>
      <TableFunctions
        addPermission={true}
        undoSearch={undoSearch}
        setSearch={setIsSearch}
        btnTitle="Add Role"
        handleSearch={handleSearch}
        handleAddModalOpen={handleAddModalOpen}
      />
      <TableData
        headData={[
          {
            align: "left",
            label: "Role",
          },
          {
            align: "center",
            label: "",
          },
          {
            align: "right",
            label: "",
          },
        ]}
        editPermission={true}
        deletePermission={true}
        tableData={roles}
        tableSelect={[{ align: "left", key: "role" }]}
        handleStoreEdit={handleStoreEdit}
        handleDeleteModalOpen={handleDeleteModalOpen}
        setDeleteStore={setDeleteStore}
        setPermission
        noDataTitle="No roles found"
      />
      {!isSearch && (
        <PaginationComponent
          limit={10}
          count={dataCount}
          handlePageChange={handlePageChange}
        />
      )}
      <Modal open={addModalOpen} onClose={handleAddModalClose}>
        <Box sx={{ ...modalStyle, backgroundColor: "white" }}>
          <div className={css.addStoreHeader}>
            {edit ? "Update Role" : "Add Role"}
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className={css.formContainer}>
              <Grid container spacing={2}>
                <Grid item lg={12} sm={12}>
                  <TextField
                    fullWidth
                    {...register("role", { required: true })}
                    helperText={errors.role && "This field is required"}
                    error={!!errors.role}
                    size="small"
                    variant="outlined"
                    label="Role Name"
                    className={css.textField}
                  />
                </Grid>
              </Grid>
            </div>
            <div className={css.formBtn}>
              <Button type="submit" variant="contained">
                {edit ? "Update Role" : "Add Role"}
              </Button>
              <Button
                onClick={() => handleAddModalClose()}
                type="button"
                variant="contained"
                color="warning"
              >
                Cancel
              </Button>
            </div>
          </form>
        </Box>
      </Modal>
      <Modal open={deleteModal} onClose={handleDeleteModalClose}>
        <Box sx={{ ...deleteModalStyle, backgroundColor: "white" }}>
          <div>
            <div>Are you sure you want to delete?</div>
            <div
              style={{
                display: "flex",
                gap: "5px",
                justifyContent: "flex-end",
                marginTop: "2rem",
              }}
            >
              <Button
                onClick={handleDeleteModalClose}
                size="small"
                variant="contained"
              >
                No
              </Button>
              <Button
                onClick={() => handleDeleteStore(deleteStore)}
                size="small"
                variant="contained"
              >
                Yes
              </Button>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
};
export default StoreRole;
