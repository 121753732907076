import React from 'react';

const Test = () => {
    return(
        <div>
            Display in Browser
        </div>
    )
}

export default Test