import React, { useContext, useEffect, useState } from "react";

// styles
import css from "../styles/pages/Reports.module.css";
import tableCss from "../styles/pages/components/Table.module.css";

import dayjs from "dayjs";
import { DatePicker } from "@mui/x-date-pickers";
import {
  Button,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  Box,
  DialogTitle,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  styled,
  Link,
} from "@mui/material";
import { get } from "../api/services/apiMethods";
import urls from "../api/constants";
import PaginationComponent from "../components/Pagination";
import * as Scroll from "react-scroll";
import EmployeePermission from "../context/employeePermission";
import { Search } from "@mui/icons-material";
import { toast } from "react-toastify";
import { exportToExcel, JsonToExcel } from "react-json-to-excel";

const calculateBlackAndWhitePages = (totalOrders) => {
  let totalPages = 0;

  for (let data of totalOrders) {
    console.log(data.items.length, "length");
    for (let item of data.items) {
      console.log(item.colors.color, "item==");
      if (item.colors.color === "bw") {
        totalPages += item.totalPages;
      } else if (item.colors.color === "colpar") {
        let pages = item.totalPages - item.colorPar.pageNumbers.length;
        totalPages += pages;
      } else {
      }
    }
  }

  return totalPages;
};

const calculateStats = (totalOrders) => {
  let stats = {
    createdAt: "",
    totalAmount: 0,
    totalPrints: 0,
    totalBlackPages: 0,
    totalColorPages: 0,
    binding: {
      totalStaples: 0,
      totalStickFiles: 0,
      totalSpirals: 0,
      totalWithoutBindings: 0,
    },
    sides: {
      totalOneSided: 0,
      totalDoubleSided: 0,
    },
    paperLayout: {
      totalPortrait: 0,
      totalLandscape: 0,
    },
    totalBondPages: 0,
    pageSizes: {
      totalA4: 0,
      totalA5: 0,
      totalA6: 0,
      totalLetter: 0,
      totalPostcard: 0,
      totalB5: 0,
      totalLegal: 0,
      "5*7": 0,
      "4*6": 0,
      "3.5*5": 0,
    },
  };

  for (let order of totalOrders) {
    stats["createdAt"] = dayjs(order.createdAt).format("DD/MM/YYYY");
    for (let item of order.items) {
      // sides
      if (item.side === "one") {
        stats.sides.totalOneSided += item.totalPages * item.copies;
      } else if (item.side === "both") {
        stats.sides.totalDoubleSided += item.totalPages * item.copies;
      }

      // amount
      stats.totalAmount += parseInt(item.cost);

      // pages
      stats.totalPrints += item.totalPages * item.copies;

      // colors
      if (item.colors.color === "color") {
        stats.totalColorPages =
          stats.totalColorPages + item.totalPages * item.copies;
      } else if (item.colors.color === "bw") {
        stats.totalBlackPages =
          stats.totalBlackPages + item.totalPages * item.copies;
      } else if (item.colors.color === "colpar") {
        let totalColorPages = item.colorPar.pageNumbers.length * item.copies;
        let totalBlackPages = (item.totalPages - totalColorPages) * item.copies;
        stats.totalBlackPages += totalBlackPages;
        stats.totalColorPages += totalColorPages;
      }

      // page layout
      if (item.printLayout === "Portrait") {
        stats.paperLayout.totalPortrait++;
      } else if (item.printLayout === "LandScape") {
        stats.paperLayout.totalLandscape++;
      }

      // bindings
      if (item.binding === "Spiral") {
        stats.binding.totalSpirals++;
      } else if (item.binding === "Staples") {
        stats.binding.totalStaples++;
      } else if (item.binding === "StickFile") {
        stats.binding.totalStickFiles++;
      } else if (item.binding === "noBinding") {
        stats.binding.totalWithoutBindings++;
      }

      // paper size
      if (item.paperSize === "a4") {
        stats.pageSizes.totalA4 += item.totalPages * item.copies;
      } else if (item.paperSize === "a5") {
        stats.pageSizes.totalA5 += item.totalPages * item.copies;
      } else if (item.paperSize === "legal") {
        stats.pageSizes.totalLegal += item.totalPages * item.copies;
      } else if (item.paperSize === "letter") {
        stats.pageSizes.totalLetter += item.totalPages * item.copies;
      } else if (item.paperSize === "b5") {
        stats.pageSizes.totalB5 += item.totalPages * item.copies;
      } else if (item.paperSize === "a6") {
        stats.pageSizes.totalA6 += item.totalPages * item.copies;
      } else if (item.paperSize === "postcard") {
        stats.pageSizes.totalPostcard += item.totalPages * item.copies;
      } else if (item.paperSize === "5*7") {
        stats.pageSizes["5*7"] += item.totalPages * item.copies;
      } else if (item.paperSize === "4*6") {
        stats.pageSizes["4*6"] += item.totalPages * item.copies;
      } else if (item.paperSize === "3.5*5") {
        stats.pageSizes["3.5*5"] += item.totalPages * item.copies;
      }

      // bond pages
      if (item.bondPage.selected === true) {
        stats.totalBondPages++;
      }
    }
  }

  return stats;
};

const StatsContainerStyle = {
  width: "100%",
  maxWidth: "200px",
};

const Report = () => {
  let scroll = Scroll.animateScroll;

  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [orders, setOrders] = useState([]);
  const [filterInUse, setFilterUse] = useState(false);
  const [count, setCount] = useState(0);
  const [listCount, setListCount] = useState(0);
  const [dialogOpen, setDialopOpen] = useState(false);
  const [selectOrder, setSelectOrder] = useState(null);
  const [resetPage, setResetPage] = useState(null);
  const [stores, setStores] = useState([]);
  const [storeSelect, setStoreSelect] = useState("");
  const { employeePermission } = useContext(EmployeePermission);
  const [idSearch, setIdSearch] = useState("");
  const [orderAudti, setOrderAudti] = useState({});

  const [orderStats, setOrderStats] = useState({
    totalAmount: 0,
    totalPrints: 0,
    totalBlackPages: 0,
    totalColorPages: 0,
    binding: {
      totalStaples: 0,
      totalStickFiles: 0,
      totalSpirals: 0,
      totalWithoutBindings: 0,
    },
    sides: {
      totalOneSided: 0,
      totalDoubleSided: 0,
    },
    paperLayout: {
      totalPortrait: 0,
      totalLandscape: 0,
    },
    totalBondPages: 0,
    pageSizes: {
      totalA4: 0,
      totalA5: 0,
      totalA6: 0,
      totalLetter: 0,
      totalPostcard: 0,
      totalB5: 0,
      totalLegal: 0,
      "5*7": 0,
      "4*6": 0,
      "3.5*5": 0,
    },
  });

  const exportToExcelFile = () => {
    let excelData = [];
    let totalOrders = orders;
    // let subOrders = []

    const ordersByDay = orders.reduce((acc, order) => {
      const dateKey = order.createdAt.split("T")[0]; // Extract YYYY-MM-DD part of createdAt
      if (!acc[dateKey]) {
        acc[dateKey] = []; // Initialize inner array if not exists
      }
      acc[dateKey].push(order); // Push order to inner array
      return acc;
    }, {});

    // Convert ordersByDay object to array of arrays
    const arrayOfArrays = Object.values(ordersByDay);

    for (let order of arrayOfArrays) {
      let orderStats = calculateStats(order);
      let newOrderStats = {
        Date: orderStats.createdAt,
        "Total Prints": orderStats.totalPrints,
        "Total Amount": orderStats.totalAmount,
        "Total Black&White": orderStats.totalBlackPages,
        "Total Color Pages": orderStats.totalColorPages,
        Binding: "",
        Spiral: orderStats.binding.totalSpirals,
        "Stick Files": orderStats.binding.totalStickFiles,
        Staples: orderStats.binding.totalStaples,
        "No Binding": orderStats.binding.totalWithoutBindings,
        "Paper Layout": "",
        "Total Landscape": orderStats.paperLayout.totalLandscape,
        "Total Portrait": orderStats.paperLayout.totalPortrait,
        "Bond Pages": orderStats.totalBondPages,
        Sides: "",
        "Total One Sided": orderStats.sides.totalOneSided,
        "Total Double Sided": orderStats.sides.totalDoubleSided,
        "Paper Sizes": "",
        A4: orderStats.pageSizes.totalA4,
        A5: orderStats.pageSizes.totalA5,
        A6: orderStats.pageSizes.totalA6,
        B5: orderStats.pageSizes.totalB5,
        Legal: orderStats.pageSizes.totalLegal,
        Letter: orderStats.pageSizes.totalLetter,
        "Post Card": orderStats.pageSizes.totalPostcard,
        "3.5*5": orderStats.pageSizes["3.5*5"],
        "4*6": orderStats.pageSizes["4*6"],
        "5*7": orderStats.pageSizes["5*7"],
      };

      excelData.push(newOrderStats);
    }

    // let newOrderStats = {
    //   "Date": orderStats.createdAt,
    //   "Total Prints": orderStats.totalPrints,
    //   "Total Amount": orderStats.totalAmount,
    //   "Total Black&White": orderStats.totalBlackPages,
    //   "Total Color Pages": orderStats.totalColorPages,
    //   Binding: "",
    //   Spiral: orderStats.binding.totalSpirals,
    //   "Stick Files": orderStats.binding.totalStickFiles,
    //   Staples: orderStats.binding.totalStaples,
    //   "No Binding": orderStats.binding.totalWithoutBindings,
    //   "Paper Layout": "",
    //   "Total Landscape": orderStats.paperLayout.totalLandscape,
    //   "Total Portrait": orderStats.paperLayout.totalPortrait,
    //   "Bond Pages": orderStats.totalBondPages,
    //   Sides: "",
    //   "Total One Sided": orderStats.sides.totalOneSided,
    //   "Total Double Sided": orderStats.sides.totalDoubleSided,
    //   "Paper Sizes": "",
    //   A4: orderStats.pageSizes.totalA4,
    //   A5: orderStats.pageSizes.totalA5,
    //   A6: orderStats.pageSizes.totalA6,
    //   B5: orderStats.pageSizes.totalB5,
    //   Legal: orderStats.pageSizes.totalLegal,
    //   Letter: orderStats.pageSizes.totalLetter,
    //   "Post Card": orderStats.pageSizes.totalPostcard,
    //   "3.5*5": orderStats.pageSizes["3.5*5"],
    //   "4*6": orderStats.pageSizes["4*6"],
    //   "5*7": orderStats.pageSizes["5*7"],
    // };
    return exportToExcel([...excelData], "Report.xlsx");
  };

  useEffect(() => {
    const getOrders = async () => {
      try {
        if (
          employeePermission &&
          Array.isArray(employeePermission["Orders"]) &&
          employeePermission["Orders"].includes("view")
        ) {
          const { data: stores, status } = await get(`${urls.store.list}`);
          if (stores && stores.result && stores.result.data.length > 0) {
            const storeList = stores.result.data;
            setStores(stores.result.data);
            setStoreSelect(storeList[0]._id);
          }
        }
      } catch (error) {}
    };
    getOrders();
  }, [employeePermission]);
  useEffect(() => {
    const changeStore = async () => {
      if (storeSelect) {
        const { data: orders, status } = await get(`${urls.orders.list}`, {
          params: {
            limit: 25,
            page: 1,
            storeId: storeSelect,
          },
        });
        if (status > 199 && status < 299) {
          setOrders(orders.result.data);
          console.log(orders.result.data, "ordersssssss");
          setCount(orders.result.count);
          setOrderStats(calculateStats(orders.result.data));
          let blackPages = calculateBlackAndWhitePages(orders.result.data);
          let colorPages = orders.result.pages - blackPages;
          setOrderAudti({
            totalAmount: orders.result.totalAmount,
            pages: orders.result.pages,
            colorPages: colorPages,
            blackandwhite: blackPages,
          });
        }
      }
    };
    changeStore();
  }, [storeSelect]);

  useEffect(() => {
    if (resetPage) {
      setResetPage(false);
    }
  }, [resetPage]);

  const handlePageChange = async (page, limit) => {
    try {
      if (filterInUse) {
        const { data: orders, status } = await get(`${urls.orders.list}`, {
          params: {
            limit: 25,
            page,
            startDate: dayjs(startDate).format("DD-MM-YYYY"),
            endDate: dayjs(endDate).format("DD-MM-YYYY"),
            storeId: storeSelect,
          },
        });
        if (status > 199 && status < 299) {
          let counts = limit;
          counts = counts * page - limit;
          setListCount((prev) => counts);
          setOrders(orders.result.data);
          setOrderStats(calculateStats(orders.result.data));
          let blackPages = calculateBlackAndWhitePages(orders.result.data);
          let colorPages = orders.result.pages - blackPages;
          setOrderAudti({
            totalAmount: orders.result.totalAmount,
            pages: orders.result.pages,
            colorPages: colorPages,
            blackandwhite: blackPages,
          });
          scroll.scrollToTop();
        }
      } else {
        const { data: orders, status } = await get(`${urls.orders.list}`, {
          params: {
            limit: 25,
            page,
            storeId: storeSelect,
          },
        });
        if (status > 199 && status < 299) {
          let counts = limit;
          counts = counts * page - limit;
          setListCount((prev) => counts);
          setOrders(orders.result.data);
          setOrderStats(calculateStats(orders.result.data));
          let blackPages = calculateBlackAndWhitePages(orders.result.data);
          let colorPages = orders.result.pages - blackPages;
          setOrderAudti({
            totalAmount: orders.result.totalAmount,
            pages: orders.result.pages,
            colorPages: colorPages,
            blackandwhite: blackPages,
          });
          scroll.scrollToTop();
        }
      }
    } catch (error) {}
  };
  const handleFilter = async (e) => {
    e.preventDefault();
    try {
      const { data: orders, status } = await get(`${urls.orders.list}`, {
        params: {
          startDate: dayjs(startDate).format("YYYY-MM-DD"),
          endDate: dayjs(endDate).format("YYYY-MM-DD"),
          storeId: storeSelect,
        },
      });
      if (status > 199 && status < 299) {
        setOrders(orders.result.data);
        console.log(orders.result.data, "orders filter");
        setOrderStats(calculateStats(orders.result.data));
        let blackPages = calculateBlackAndWhitePages(orders.result.data);
        let colorPages = orders.result.pages - blackPages;
        setOrderAudti({
          totalAmount: orders.result.totalAmount,
          pages: orders.result.pages,
          colorPages: colorPages,
          blackandwhite: blackPages,
        });
        setCount(orders.result.count);
        setFilterUse(true);
        setResetPage(1);
      }
    } catch (error) {}
  };
  const handleDialogOpen = () => setDialopOpen(true);

  const handleDialogClose = () => {
    setSelectOrder(null);
    setDialopOpen(false);
  };
  const handleViewDetails = (order) => {
    setSelectOrder(order);
    handleDialogOpen();
  };
  const handleFilterReset = async () => {
    setStartDate(null);
    setEndDate(null);
    setFilterUse(false);
    const { data: orders, status } = await get(`${urls.orders.list}`, {
      params: {
        limit: 25,
        page: 1,
        storeId: storeSelect,
      },
    });
    if (status > 199 && status < 299) {
      setOrders(orders.result.data);
      setOrderStats(calculateStats(orders.result.data));
      let blackPages = calculateBlackAndWhitePages(orders.result.data);
      let colorPages = orders.result.pages - blackPages;
      setOrderAudti({
        totalAmount: orders.result.totalAmount,
        pages: orders.result.pages,
        colorPages: colorPages,
        blackandwhite: blackPages,
      });
      setCount(orders.result.count);
    }
  };
  const displayOrderText = (item) => {
    let text = "";
    if (item.color === "bw") {
      text += "black/white-";
    } else {
      text += "color-";
    }
    if (item.paperSize === "a4") {
      text += "A4-";
    } else {
      text += "A5-";
    }
    if (item.paperQuality === "100gsm") {
      text += "100gsm-";
    } else {
      text += "80gsm-";
    }
    if (item.binding === "Spiral") {
      text += "Spiral";
    } else if (item.binding === "Staples") {
      text += "Staples";
    } else {
      text += "No binding";
    }
    return text;
  };
  const getItemsInText = (item, field) => {
    if (field === "color") {
      return item.color === "bw" ? "black/white" : "color";
    }
    if (field === "paperSize") {
      return item.paperSize === "a4" ? "A4" : "A5";
    }
    if (field === "paperQuality") {
      return item.paperQuality === "100gsm" ? "100gsm" : "80gsm";
    }
    if (field === "binding") {
      return item.binding === "Spiral" ? "Spiral" : "Staples";
    }
  };
  const searchId = async () => {
    try {
      if (!idSearch) return toast.error("Please enter ID for search");
      const { data: order, status } = await get(`${urls.orders.list}`, {
        params: {
          search: idSearch,
        },
      });
      console.log(order);
      if (status > 199 && status < 299) {
        if (order.result && Array.isArray(order.result.data)) {
          setOrderStats(calculateStats(order.result.data));
          setSelectOrder(order.result.data[0]);
          handleDialogOpen();
        } else {
          toast.error("Order not found");
        }
      }
    } catch (error) {
      toast.error("Order not found");
    }
  };
  return (
    <div className={css.container}>
      <form onSubmit={handleFilter} className={css.textFieldContainer}>
        <Grid container spacing={3} py={2}>
          <Grid item xs={12} sm={6} md={4}>
            <DatePicker
              label="Start Date"
              variant="outlined"
              inputFormat="DD-MM-YYYY"
              value={startDate ? startDate : null}
              onChange={(date) => setStartDate(date)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  size="small"
                  label="Start Date"
                  variant="outlined"
                  required
                  // sx={{ width: { xs: '85%', md: '100%' } }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <DatePicker
              label="End Date"
              variant="outlined"
              inputFormat="DD-MM-YYYY"
              value={endDate ? endDate : null}
              onChange={(date) => setEndDate(date)}
              disabled={!startDate}
              minDate={startDate}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  size="small"
                  label="End Date"
                  variant="outlined"
                  required
                  // sx={{ width: { xs: '85%', md: '100%' } }}
                />
              )}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={4}
            sx={{ display: "flex", alignItems: "center" }}
          >
            <Button variant="contained" type="submit" size="small">
              show
            </Button>
            {filterInUse && (
              <Button
                sx={{ marginLeft: "10px" }}
                onClick={handleFilterReset}
                type="button"
                variant="contained"
                size="small"
              >
                reset
              </Button>
            )}
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <FormControl
              fullWidth
              // sx={{ width: { xs: '85%', md: '100%' } }}
            >
              <InputLabel>Select Store</InputLabel>
              <Select
                value={storeSelect}
                label="Select Store"
                size="small"
                onChange={(e) => setStoreSelect(e.target.value)}
              >
                {stores.map((store) => (
                  <MenuItem key={store._id} value={store._id}>
                    {store.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <FormControl
              fullWidth
              // sx={{ width: { xs: '85%', md: '100%' } }}
            >
              <TextField
                value={idSearch}
                onChange={(e) => setIdSearch(e.target.value)}
                label="Search with ID"
                size="small"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">
                      <IconButton edge="end" onClick={searchId}>
                        <Search />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Button
              onClick={exportToExcelFile}
              variant="contained"
              sizee="small"
            >
              Download Excel Report
            </Button>
          </Grid>
        </Grid>
      </form>
      <div>
        <TableContainer component={Paper}>
          {orderStats ? (
            <>
              <div className={css.auditContainer}>
                <div style={StatsContainerStyle}>
                  Total Amount:₹{orderStats.totalAmount}
                </div>
                <div style={StatsContainerStyle}>
                  Total Prints:{orderStats.totalPrints}
                </div>
                <div style={StatsContainerStyle}>
                  Total b/w:{orderStats.totalBlackPages}
                </div>
                <div style={StatsContainerStyle}>
                  Total Color:{orderStats.totalColorPages}
                </div>
              </div>
              <div className={css.auditContainer}>
                <div style={StatsContainerStyle}>
                  Total Bond Pages : {orderStats.totalBondPages}
                </div>
                <div style={StatsContainerStyle}>
                  <FormControl sx={{ width: "100%" }}>
                    <InputLabel>Page Sizes</InputLabel>
                    <Select fullWidth label="Page Sizes" size="small">
                      <MenuItem>
                        {orderStats.pageSizes.totalA4} A4 pages
                      </MenuItem>
                      <MenuItem>
                        {orderStats.pageSizes.totalA5} A5 pages
                      </MenuItem>
                      <MenuItem>
                        {orderStats.pageSizes.totalA6} A6 pages
                      </MenuItem>
                      <MenuItem>
                        {orderStats.pageSizes.totalLegal} Legal Letter pages
                      </MenuItem>
                      <MenuItem>
                        {orderStats.pageSizes.totalPostcard} Postcard pages
                      </MenuItem>
                      <MenuItem>
                        {orderStats.pageSizes.totalB5} B5 pages
                      </MenuItem>
                      <MenuItem>
                        {orderStats.pageSizes["3.5*5"]} 3.5*5 pages
                      </MenuItem>
                      <MenuItem>
                        {orderStats.pageSizes["4*6"]} 4*6 pages
                      </MenuItem>
                      <MenuItem>
                        {orderStats.pageSizes["5*7"]} 5*7 pages
                      </MenuItem>
                    </Select>
                  </FormControl>
                </div>
                <div style={StatsContainerStyle}>
                  <FormControl sx={{ width: "100%" }}>
                    <InputLabel>Bindings</InputLabel>
                    <Select fullWidth label="Page Sizes" size="small">
                      <MenuItem>
                        {orderStats.binding.totalSpirals} Spiral
                      </MenuItem>
                      <MenuItem>
                        {orderStats.binding.totalStaples} Staples
                      </MenuItem>
                      <MenuItem>
                        {orderStats.binding.totalStickFiles} Stick Files
                      </MenuItem>
                      <MenuItem>
                        {orderStats.binding.totalWithoutBindings} Without
                        binding
                      </MenuItem>
                    </Select>
                  </FormControl>
                </div>
                <div style={StatsContainerStyle}>
                  <FormControl sx={{ width: "100%" }}>
                    <InputLabel>Print Layout</InputLabel>
                    <Select fullWidth label="Page Sizes" size="small">
                      <MenuItem>
                        {orderStats.paperLayout.totalLandscape} Landscape
                      </MenuItem>
                      <MenuItem>
                        {orderStats.paperLayout.totalPortrait} Portrait
                      </MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </div>
            </>
          ) : null}
          <Table>
            <TableHead className={tableCss.tableHeader}>
              <TableRow className={tableCss.tableRowHeader}>
                <TableCell align="center">#SL</TableCell>
                <TableCell align="center">Order No</TableCell>
                <TableCell align="center">Date and Time</TableCell>
                <TableCell align="center">Cost</TableCell>
                <TableCell align="center" sx={{ padding: { xs: 1 } }}>
                  Files
                </TableCell>
                <TableCell align="center">Orders</TableCell>
                {/* <TableCell align="center"></TableCell> */}
              </TableRow>
            </TableHead>
            <TableBody>
              {employeePermission &&
                Array.isArray(employeePermission["Orders"]) &&
                employeePermission["Orders"].includes("view") &&
                orders.map((order, index) => (
                  <TableRow
                    key={order._id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell align="center">
                      {listCount + index + 1}
                    </TableCell>
                    <TableCell align="center">{order.orderId}</TableCell>
                    <TableCell align="center">
                      {dayjs(order.createdAt).format("DD-MM-YYYY hh:mm A")}
                    </TableCell>
                    <TableCell align="center">{order.totalCost}</TableCell>
                    <TableCell align="center">{order.items.length}</TableCell>
                    <TableCell align="center">
                      <Button
                        variant="contained"
                        onClick={() => handleViewDetails(order)}
                      >
                        View order
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        {!orders.length && <h2 style={{ textAlign: "center" }}>No reports</h2>}
      </div>
      <PaginationComponent
        page={resetPage && 1}
        limit={25}
        count={count}
        handlePageChange={handlePageChange}
      />
      <Dialog open={dialogOpen} onClose={handleDialogClose} scroll="paper">
        <DialogTitle id="scroll-dialog-title">Order Details</DialogTitle>
        <DialogContent dividers={true}>
          {selectOrder && (
            <div className={css.selectContainer}>
              <h4>Order items: {selectOrder.items.length}</h4>
              {selectOrder.items.map((item, index) => (
                <Paper
                  key={index}
                  elevation={2}
                  className={css.fileConfigContainer_paper}
                >
                  <div className={css.fileConfigContainer}>
                    <div>File {index + 1}</div>
                    <div>
                      File url :{" "}
                      <Link
                        underline="hover"
                        href={item.documents}
                        title="Download file"
                      >
                        Download
                      </Link>
                    </div>
                    <div>
                      Xerox color:{" "}
                      {item.colors.color === "color"
                        ? "Color"
                        : item.colors.color === "colpar"
                        ? "Multi-Color"
                        : "Black&White"}
                    </div>
                    <div>
                      Pages to Print : {item.pageRange[0]} to{" "}
                      {item.pageRange[1]}
                    </div>
                    {item.colors.color === "colpar" ? (
                      <div>
                        <div>
                          Total Black Pages :{" "}
                          {item.totalPages - item.colorPar.pageNumbers.length}{" "}
                        </div>
                        <div>
                          Total Color Pages : {item.colorPar.pageNumbers.length}{" "}
                        </div>
                        <div>
                          Color Page Nos. to print :{" "}
                          {item.colorPar.pageNumbers.join(",")}{" "}
                        </div>
                      </div>
                    ) : null}

                    <div>Paper size : {getItemsInText(item, "paperSize")}</div>
                    <div>Binding : {getItemsInText(item, "binding")}</div>
                    <div>Copies : {item.copies}</div>

                    {item.bondPage.selected !== "yes" ? null : (
                      <div>
                        <div>
                          BondPage : {item.bondPage ? item.bondPage.total : "0"}
                        </div>
                      </div>
                    )}

                    <div>
                      Instructions :{" "}
                      {item.instructions ? item.instructions : "NA"}{" "}
                    </div>
                    <div>Cost : ₹{item.cost} </div>
                  </div>
                </Paper>
              ))}
              <h4>Total Cost: ₹{selectOrder.totalCost}</h4>
              <h4>Status: {selectOrder.status}</h4>
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button color="warning" onClick={handleDialogClose}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Report;
