import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import React, { useContext, useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import css from "../styles/pages/Login.module.css";
import { objPropertyCheck } from "../utils/utils";
import { post, put } from "../api/services/apiMethods";
import urls from "../api/constants";
import EmployeePermission from "../context/employeePermission";
import { objHas } from "../utils/utils";

const EmployeeLogin = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({});
  const [formError, setFormError] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [resError, setResError] = useState("");
  const { setEmployeePermission } = useContext(EmployeePermission);
  const [settings, setSettings] = useState(null);

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  useEffect(() => {
    console.log(window.localStorage.getItem("access_token"), "======");
    window.localStorage.removeItem("access_token");
    const logoURL = require("./image/logo3.jpg");
    setSettings({ ...settings, logo: { link: logoURL } });
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!objPropertyCheck(formData, ["email", "password"]))
      return setFormError(true);
    try {
      const data = {
        email: formData.email,
        password: formData.password,
      };
      const firebaseToken = window.localStorage.getItem("firebaseToken");
      const loginRes = await post(`${urls.employee.login}`, data);
      console.log(loginRes, "==============");
      if (loginRes.status < 200 || loginRes.status > 299)
        return setResError(loginRes.data.message);
      window.localStorage.setItem(
        "access_token",
        loginRes.data.result.access_token
      );
      window.localStorage.setItem(
        "refresh_token",
        loginRes.data.result.refresh_token
      );
      const userStringify = JSON.stringify(loginRes.data.result.user);
      window.localStorage.setItem("user", userStringify);
      if (
        loginRes.data.result.user.roleInfo &&
        loginRes.data.result.user.roleInfo.permissions
      ) {
        window.localStorage.setItem(
          "permission",
          JSON.stringify(loginRes.data.result.user.roleInfo.permissions)
        );
        setEmployeePermission(loginRes.data.result.user.roleInfo.permissions);
      }
      if (firebaseToken) {
        const updateToken = await put(
          `${urls.employee.update}${loginRes.data.result.user._id}`,
          {
            firebaseToken,
          }
        );
      }
      navigate("/store/stores");
    } catch (error) {
      setResError(error.response.data.message);
    }
  };
  return (
    <div className={css.loginContainer}>
      <div className={css.flexContainer}>
        <div onClick={() => navigate("/login")}>
          {settings && (
            <img
              src={settings && objHas(settings, "logo.link", "")}
              alt="logo"
              style={{
                width: "260px",
                marginLeft: "-5px",
                marginTop: "-10px",
              }}
            />
          )}
        </div>
        <div style={{ marginTop: "80px" }}>
          <div className={css.header}>Management Login</div>
          <div className={css.subHeader}></div>
          <div className={css.error}>{resError && resError}</div>
          <div className={css.formContainer}>
            <form onSubmit={handleSubmit}>
              <div className={css.formHolder}>
                <TextField
                  error={formError && !formData.email}
                  fullWidth
                  onChange={handleFormChange}
                  value={formData.email || ""}
                  name="email"
                  label="Email"
                  variant="outlined"
                  sx={{
                    backgroundColor: "#F5F5F5",
                  }}
                />
                {formError && !formData.email && (
                  <span style={{ color: "red" }}>This field is required</span>
                )}
              </div>
              <div className={css.formHolder}>
                <TextField
                  type={showPassword ? "text" : "password"}
                  error={formError && !formData.password}
                  fullWidth
                  onChange={handleFormChange}
                  value={formData.password || ""}
                  name="password"
                  label="Password"
                  variant="outlined"
                  sx={{
                    backgroundColor: "#F5F5F5",
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          edge="end"
                          aria-label="toggle password visibility"
                          onClick={() => setShowPassword(!showPassword)}
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                {formError && !formData.password && (
                  <span style={{ color: "red" }}>This field is required</span>
                )}
              </div>
              <div className={css.textBtnContainer}>
                <div className={css.btn}>
                  <Button variant="contained" type="submit">
                    LOGIN
                  </Button>
                  <Button
                    sx={{ ml: "10px" }}
                    variant="outlined"
                    onClick={() => navigate("/login")}
                  >
                    Cancel
                  </Button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <Box
        sx={{
          backgroundColor: "white",
          position: "fixed",
          bottom: 0,
          display: "flex",
          width: "100%",
          flex: 1,
          padding: 2,
          justifyContent: {
            lg: "flex-end",
            md: "flex-end",
            sm: "center",
            xs: "center",
          },
          margin: "auto",
        }}
      >
        <Typography sx={{ fontSize: "14px", color: "gray" }}>
          Powered by{" "}
          <Link
            target="_blank"
            to="https://i2ntech.in"
            style={{
              fontSize: "14px",
              color: "dodgerblue",
              textDecoration: "none",
            }}
          >
            I2N TECHNOLOGIES
          </Link>
        </Typography>
      </Box>
    </div>
  );
};

export default EmployeeLogin;
