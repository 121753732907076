import { Table, TableContainer, TableHead, TableRow } from '@mui/material';
import React from 'react';

import css from '../styles/pages/PastOrders.module.css'

const PastOrders = () => {

    return(
        <div className={css.container}>
            <div className={css.header}>
                Past Orders
            </div>
            <TableContainer>
                <Table sx={{minWidth:650}}>
                    <TableHead>
                        <TableRow>
                            
                        </TableRow>
                    </TableHead>
                </Table>
            </TableContainer>
        </div>
    )
}

export default PastOrders;