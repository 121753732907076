import { Box, Typography, styled } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const Container = styled(Box)(() => ({}));

function PreviewPages({
  file,
  selectedPages = [1],
  onPageSelect = () => {},
  pageRange = [],
  onUpdatePageRange = () => {},
  selectedDocument,
}) {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  useEffect(() => {
    let newSelectedPages = [];
    for (let selectedPage of selectedPages) {
      if (selectedPage >= pageRange[0] && selectedPage <= pageRange[1]) {
        newSelectedPages.push(selectedPage);
      }
    }

    onUpdatePageRange("colorPar", {
      ...selectedDocument.config.colorPar,
      pageNumbers: newSelectedPages,
    });
  }, [pageRange]);

  return (
    <Container>
      <Document file={file} onLoadSuccess={onDocumentLoadSuccess}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            overflow: "auto",
            height: 300,
            maxWidth: 1000,
            overflow: "auto",
            columnGap: "10px",
          }}
        >
          {Array.from({ length: numPages }).map((val, index) =>
            index + 1 <= pageRange[1] && index + 1 >= pageRange[0] ? (
              <Box
                component={"div"}
                onClick={() => onPageSelect(index + 1)}
                key={index}
                sx={{
                  position: "relative",
                  justifyContent: "center",
                  border: "1px solid lightgray",
                  borderRadius: "5px",
                  boxShadow: (theme) => theme.shadows[1],
                  padding: "5px",
                  minWidth: 200,
                  marginBottom: "5px",
                  overflow: "hidden",
                  borderColor: selectedPages.includes(index + 1)
                    ? "dodgerblue"
                    : "lightgray",
                  borderWidth: selectedPages.includes(index + 1)
                    ? "2px"
                    : "1px",
                }}
              >
                <Page height={100} width={200} pageNumber={index + 1} />
                <Typography
                  sx={{
                    position: "absolute",
                    height: "30px",
                    width: "30px",
                    borderRadius: "100%",
                    borderWidth: "2px",
                    color: "white",
                    borderColor: "gray",
                    alignSelf: "center",
                    right: "45%",
                    backgroundColor: selectedPages.includes(index + 1)
                      ? "dodgerblue"
                      : "gray",
                    bottom: "10px",
                    textAlign: "center",
                    paddingTop: "2px",
                  }}
                >
                  {index + 1}
                </Typography>
              </Box>
            ) : null
          )}
        </Box>
      </Document>
    </Container>
  );
}

export default PreviewPages;
