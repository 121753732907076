import { createTheme, Pagination, Stack, ThemeProvider } from '@mui/material';
import React, { useEffect, useState } from 'react';

// styles
import css from '../styles/components/PaginationComponent.module.css'

const theme = createTheme({
    palette:{
        tableHead:{
            main:'#005A5F',
            contrastText: '#fff',
        }
    }
})
const PaginationComponent = (props) => {    
    const [page,setPage] = useState(1);

    useEffect(() => {
        if(props.page) {
            setPage(props.page);
        }
    },[props.page])
    
    const handlePageChange = (event,value) => {
        setPage(value);
        props.handlePageChange(value,props.limit);  
    }
    return(
        <ThemeProvider theme={theme}>
            <div className={css.paginationContainer}>
                <Stack spacing={2}>
                    <Pagination color='tableHead' count={Math.ceil((props.count/props.limit))} page={page} onChange={handlePageChange}  />
                </Stack>
            </div>
        </ThemeProvider>
    )
}

export default PaginationComponent;