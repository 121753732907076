import { Button} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';


// styles
import css from '../styles/pages/Product.module.css';

// helpers
import {uploadFile} from '../helper/helper'
import urls from '../api/constants';
import { del, get, post } from '../api/services/apiMethods';
import { toast } from 'react-toastify';
import StationeryCard from '../components/StationeryCard';
import { ArrowBack } from '@mui/icons-material';

const UserProduct = () => {
    const [addModel,setAddModal] = useState(false);
    const [selectFile,setSelectFile] = useState(null);
    const [products,setProducts] = useState([]);
    const [edit,setEdit] = useState('');    
    const [isSearch,setIsSearch] = useState(false);

    const location = useLocation();
    const navigate = useNavigate();

    const {register,handleSubmit,formState:{errors,isSubmitted,isSubmitting},setValue,reset,control} = useForm({
        defaultValues:{
            stock:''
        }
    })

    // useEffects
    useEffect(() => {
        const init = async () => {
            const {data:products,status} = await get(`${urls.stationery.list}`,{
                params:{
                    entityId:location.state.entityId
                }
            });
            if(status > 199 && status < 299) {
                setProducts(products.result.data);
            }
        }
        init();
    },[location.state.entityId])

    const getData = async () => {
        console.log('cnd')
        try {
            const {data:products,status} = await get(`${urls.stationery.list}`,{
                params:{
                    entityId:location.state.entityId
                }
            });
            if(status > 199 && status < 299) {
                setProducts(products.result.data);
            }
        } catch (error) {
            
        }
    }
    const openAddModal = () => setAddModal(true);
    const closeAddModal = () => {
        setEdit('')
        reset();
        setSelectFile(null);
        setAddModal(false);
    }
    const handleEdit = (id,data) => {
        setEdit(id);
        setValue('name',data.name);
        setValue('stock',data.meta.stock);
        openAddModal();
    }
    const onSubmit = async (formData) => {
        try {
            let fileUrl
            if(selectFile) {
                fileUrl = await uploadFile(selectFile.name,selectFile)
            }
            const data = {
                name:formData.name,
                entityId:location.state.entityId,
                meta:{
                    stock:formData.stock,
                    entityId:location.state.entityId
                }
            }
            if(fileUrl) data.image = fileUrl;

            if(edit) {
                const {status} = await post(`${urls.stationery.update}${edit}`,data);
                if(status > 199 && status < 299) {
                    const {data:products} = await get(`${urls.stationery.list}`,{
                        params:{
                            entityId:location.state.entityId
                        }
                    });
                    setProducts(products.result.data);
                    closeAddModal();
                    toast.success('New product created');
                }
            } else {
                const {status} = await post(`${urls.stationery.create}`,data);
                if(status > 199 && status < 299) {
                    const {data:products} = await get(`${urls.stationery.list}`,{
                        params:{
                            entityId:location.state.entityId
                        }
                    });
                    setProducts(products.result.data);
                    closeAddModal();
                    toast.success('New product created');
                }
            }
        } catch (error) {
            console.log(error)
        }
    }
    const fileChange = (e) => {
        setSelectFile(e.target.files[0])
    }
    const handleDelete = async (id) => {
        try {
            const {status} = await del(`${urls.stationery.del}${id}`);
            if(status > 199 && status < 299) {
                setProducts(products.filter((product) => product._id !== id));
            }
        } catch (error) {
            
        }
    }
    const handleSearch = async (search) => {
        try {
          const searchProducts = await get(`${urls.stationery.list}`,{
            params:{
              search:search
            }
          })
          setProducts(searchProducts.data.result.data);
          setIsSearch(true);
        } catch (error) {
    
        }
    }
    const undoSearch = async () => {
        if(isSearch) {
            await getData()
        }
        setIsSearch(false);
    }
    return(
        <div style={{paddingLeft:'1rem'}}>
            <Button onClick={() => navigate('/user/stationery')}><ArrowBack fontSize="30px" /> Back</Button>
            <div className={css.flexContainer}>
                {products.map(product => (
                    <StationeryCard unShowDelete unShowEdit image={product.image} edit={handleEdit} delete={handleDelete} key={product._id} data={product} id={product._id} elevation={3} label={product.name} />
                ))}
            </div>
            {!products.length && <h2 style={{textAlign:'center'}}>No products</h2>}
        </div>
    )
}
export default UserProduct;