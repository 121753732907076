import { FormHelperText, Select } from '@mui/material';
import React from 'react';
import { Controller } from 'react-hook-form';

const SelectController = ({children,control,name,size,label,defaultValue,required}) => {

    return(
        <Controller 
        control={control}
        name={name}
        render={({
            field: { onChange, onBlur, value, name, ref },
            fieldState: { invalid, isTouched, isDirty, error },
            formState:{isSubmitted},
        }) => (
            <>
            <Select
            error={error}
            size={size}
            label={label}
            name={name}
            ref={ref}
            onChange={onChange}
            onBlur={onBlur}
            value={value}
            defaultValue={defaultValue}
            >

                {children}
            </Select>
            {required && isSubmitted && !value && <FormHelperText sx={{color:'red'}}>This field is required</FormHelperText>}
            </>
        )}
        />
    )
}
export default SelectController;