import React from "react";
import { Navigate } from "react-router-dom";
import { toast } from "react-toastify";

const PrivateRoute = ({children}) => {
    const isAuth = window.localStorage.getItem('access_token');

    const navUser = () => {
        toast.warning('Please login');
        return <Navigate to='/login' />
    }
    return isAuth ? children : navUser();
}
export default PrivateRoute