import React, { useEffect, useState } from "react";
import { objHas } from "../utils/utils";
import { Link, useNavigate } from "react-router-dom";
import Logo from "../assets/images/logo.jpg";

const PrivacyPolicy = () => {
  const [settings, setSettings] = useState({ logo: { link: "" } });
  useEffect(() => {
    const logoURL = require("./image/logo3.jpg");
    setSettings({ ...settings, logo: { link: logoURL } });
  }, []);
  return (
    <div>
      <div>
        <Link to="/">
          <img
            src={Logo}
            alt="logo"
            style={{
              width: "150px",
              marginLeft: "70px",
              marginTop: "10px",
            }}
          />
        </Link>
      </div>

      <div>
        <h2
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            textDecoration: "underline",
          }}
        >
          Privacy Policy:
        </h2>
      </div>
      <div style={{ marginLeft: "70px" }}>
        <div>
          <h4>1. Introduction:</h4>
          <p>
            Welcome to Dittox, the online printing service app. This policy
            explains how we collect, use, and protect your personal information.
          </p>
        </div>
        <div>
          <h4>2. Information We Collect:</h4>
          <p>
            When you use our app, we collect personal information such as your
            name, email, phone number, uploaded documents, printing preferences,
            payment details, and location for delivery purposes.
          </p>
        </div>
        <div>
          <h4>3. How We Use Your Information:</h4>
          <p>
            We use your information to process your orders, calculate printing
            prices, facilitate communication between you and vendors, handle
            payments, and enhance app performance.
          </p>
        </div>
        <div>
          <h4>4. Sharing Information with Vendors:</h4>
          <p>
            To fulfil your printing orders, we share specific details like
            document content, printing preferences, and delivery location with
            our vendors. We do not share sensitive payment information with
            vendors.
          </p>
        </div>
        <div>
          <h4>5. Data Security Measures:</h4>
          <p>
            Your data's security is a priority. We employ encryption, secure
            servers, access controls, and regular security assessments to
            safeguard your personal information.
          </p>
        </div>
        <div>
          <h4>6. Vendor Payments:</h4>
          <p>
            Your payment information is securely processed and stored only for
            financial transactions related to the app's services.
          </p>
        </div>
        <div>
          <h4>7. Your Control Over Information:</h4>
          <p>
            You can access, modify, or delete your personal information through
            your account settings. You can also opt out of promotional
            communications.
          </p>
        </div>
        <div>
          <h4>8. Cookies and Analytics:</h4>
          <p>
            We use cookies and tracking technologies for analytics and app
            performance optimization. You can manage your cookie preferences
            through your device settings.
          </p>
        </div>
        <div>
          <h4>9. Third-Party Services:</h4>
          <p>
            We integrate third-party services such as payment processors for
            seamless transactions. Refer to their privacy policies for
            information on their data handling practices.
          </p>
        </div>
        <div>
          <h4>10. Changes to Our Policy:</h4>
          <p>
            We may update this policy as our services evolve. You'll be notified
            of any changes through app notifications or email.
          </p>
        </div>
        <div>
          <h4>11. Contact Us:</h4>
          <p>
            If you have questions or concerns about your privacy, please contact
            us at I2N Technologies, 1869, 38th A cross, 11th Main, Jayanagar,
            Bangalore-560041. Ph.No : 9986537604.
          </p>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
