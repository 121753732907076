import React, { useEffect, useState } from "react";
import { objHas } from "../utils/utils";
import { Link, useNavigate } from "react-router-dom";
import Logo from "../assets/images/logo.jpg";

const TermsAndCondition = () => {
  const [settings, setSettings] = useState({ logo: { link: "" } });

  useEffect(() => {
    const logoURL = require("./image/logo3.jpg");
    setSettings({ ...settings, logo: { link: logoURL } });
  }, []);
  return (
    <div>
      <div>
        <Link to="/">
          <img
            src={Logo}
            alt="logo"
            style={{
              width: "150px",
              marginLeft: "70px",
              marginTop: "10px",
            }}
          />
        </Link>
      </div>

      <div>
        <h2
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            textDecoration: "underline",
          }}
        >
          Terms and Conditions for Users:
        </h2>
      </div>
      <div style={{ marginLeft: "70px" }}>
        <div>
          <h4>1. Acceptance of Terms:</h4>
          <p>
            By accessing and using Dittox, you acknowledge and agree to the
            following terms and conditions, which constitute a legally binding
            agreement between you and I2N Technologies. If you do not agree to
            these terms, please refrain from using our services.
          </p>
        </div>
        <div>
          <h4>2. Service Ordering:</h4>
          <p>
            Through our app, users can avail online printing services by
            uploading documents, configuring print options, and viewing pricing
            from various partnering stores listed on the app.
          </p>
        </div>
        <div>
          <h4>3. Payment:</h4>
          <p>
            Users are responsible for making prompt and accurate payments for
            their orders using the payment methods provided within the app.
          </p>
        </div>
        <div>
          <h4>4. Order Pickup:</h4>
          <p>
            Users must ensure timely pickup of their orders from the designated
            store location after receiving a notification confirming the
            completion of the printing job.
          </p>
        </div>
        <div>
          <h4>5. User Responsibilities:</h4>
          <p>
            Users are obligated to provide accurate and complete information
            when uploading documents and configuring print options. Users must
            also adhere to the policies and guidelines of the chosen store and
            acknowledge that any violation of these policies may affect their
            order.
          </p>
        </div>
        <div>
          <h4>6. Intellectual Property:</h4>
          <p>
            Users retain ownership of the content they upload through the app
            for printing purposes. Users understand and agree that they are
            solely responsible for the content they submit.
          </p>
        </div>
        <div>
          <h4>7. Limitation of Liability:</h4>
          <p>
            I2N Technologies shall not be held liable for any direct, indirect,
            incidental, special, or consequential damages arising out of or in
            connection with the use or inability to use our services.
          </p>
        </div>
        <div>
          <h4>8. Governing Law and Dispute Resolution:</h4>
          <p>
            These terms and conditions shall be governed by and construed in
            accordance with the laws of India. Any dispute arising under or in
            connection with these terms shall be subject to the exclusive
            jurisdiction of the courts located in laws of India.
          </p>
        </div>
        <div>
          <h4>9. Changes to Terms and Conditions:</h4>
          <p>
            I2N Technologies reserves the right to update, modify, or change
            these terms and conditions at any time. Users will be notified of
            such changes and the revised terms will be accessible within the
            app.
          </p>
        </div>
      </div>
      <div>
        <h2
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            textDecoration: "underline",
          }}
        >
          Terms and Conditions for Vendors:
        </h2>
      </div>
      <div style={{ marginLeft: "70px" }}>
        <div>
          <h4>1. Acceptance of Terms:</h4>
          <p>
            By registering and utilizing Dittox as a vendor, you hereby agree to
            comply with the terms and conditions outlined below. These terms
            constitute a legally binding agreement between you and I2N
            Technologies If you do not agree to these terms, please refrain from
            using our platform.
          </p>
        </div>
        <div>
          <h4>2. Vendor Acceptance Commitment:</h4>
          <p>
            When a vendor accepts an order through our app, they enter into a
            binding commitment to fulfil the accepted order within the specified
            timeframe, barring unforeseen and exceptional circumstances.
          </p>
        </div>
        <div>
          <h4>3. Order Completion Timeframe:</h4>
          <p>
            Vendors are required to complete accepted orders within the
            designated 30-minute window from the time of acceptance.
          </p>
        </div>
        <div>
          <h4>4. Cancellation Policy:</h4>
          <p>
            Vendors are strongly discouraged from cancelling accepted orders
            once they have committed to fulfilling them. Cancellations should
            only occur in truly exceptional cases.
          </p>
        </div>
        <div>
          <h4>5. Communication in Case of Cancellation:</h4>
          <p>
            In the event that a vendor needs to cancel an accepted order,
            immediate and transparent communication with the user through the
            app's designated communication channel is mandatory.
          </p>
        </div>
        <div>
          <h4>6. Consequences of Frequent Cancellations:</h4>
          <p>
            Regular or unjustified cancellations of accepted orders may result
            in penalties, including but not limited to account suspension or
            removal from our vendor network.
          </p>
        </div>
        <div>
          <h4>7. User Compensation in Case of Cancellation:</h4>
          <p>
            If a vendor cancels an accepted order, the vendor must compensate
            the user appropriately, as determined by I2N Technologies and as
            specified in the app's guidelines.
          </p>
        </div>
        <div>
          <h4>8. Vendor Reputation:</h4>
          <p>
            Vendors understand that consistently fulfilling accepted orders
            enhances their reputation within our network and contributes to
            positive user feedback.
          </p>
        </div>
        <div>
          <h4>9. Limitation of Liability:</h4>
          <p>
            I2N Technologies shall not be liable for any direct, indirect,
            incidental, special, or consequential damages arising out of or in
            connection with the use or inability to use our services.
          </p>
        </div>
        <div>
          <h4>10. Governing Law and Dispute Resolution:</h4>
          <p>
            These terms and conditions shall be governed by and construed in
            accordance with the laws of India. Any dispute arising under or in
            connection with these terms shall be subject to the exclusive
            jurisdiction of the courts located in laws of India.{" "}
          </p>
        </div>
        <div>
          <h4>11. Changes to Terms and Conditions:</h4>
          <p>
            I2N Technologies reserves the right to update, modify, or change
            these terms and conditions at any time. Vendors will be notified of
            such changes and the revised terms will be accessible within the
            app.{" "}
          </p>
        </div>
      </div>
    </div>
  );
};

export default TermsAndCondition;
