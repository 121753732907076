import { Button, Paper } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

// styles
import css from "../styles/components/StationeryCard.module.css";
import { get } from "../api/services/apiMethods";
import urls from "../api/constants";

const StationeryCard = (props) => {
  console.log(props, "props");
  const navigate = useNavigate();

  const handleNavigate = () => {
    // if (props.navigate && props.id) {
    //   navigate(props.to, { state: { entityId: props.id } });
    // }
  };
  return (
    <div className={css.container}>
      <div onClick={handleNavigate}>
        <Paper className={css.paper} elevation={props.elevation}>
          <div className={css.imageContainer}>
            {!props.data.image ? null : (
              <img
                src={props.data.image}
                alt=""
                style={{
                  width: "80%",
                  height: "80%",
                  alignSelf: "center",
                  objectFit: "contain",
                }}
              />
            )}
          </div>
          <div className={css.labelContainer}>{props.label}</div>
        </Paper>
      </div>
      <div className={css.btnContainer}>
        {!props.unShowDelete && (
          <Button
            onClick={() => props.delete(props.id)}
            color="error"
            size="small"
          >
            Delete
          </Button>
        )}
        {!props.unShowEdit && (
          <Button size="small" onClick={() => props.edit(props.id, props.data)}>
            Edit
          </Button>
        )}
      </div>
    </div>
  );
};
export default StationeryCard;
