import {
  Button,
  Checkbox,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  ListItemText,
  MenuItem,
  Modal,
  OutlinedInput,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useMediaQuery } from "@mui/material";
import css from "../styles/pages/Stores.module.css";
import tableCss from "../styles/pages/components/Table.module.css";
import { get, post, put } from "../api/services/apiMethods";
import urls from "../api/constants";
import SelectController from "../components/SelectController";
import { Clear, Delete, Edit } from "@mui/icons-material";
import PaginationComponent from "../components/Pagination";
import EmployeePermission from "../context/employeePermission";
import { toast } from "react-toastify";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
const deleteModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const Employee = () => {
  const [addModalOpen, setAddModalOpen] = useState(false);
  const [stores, setStores] = useState([]);
  const [roles, setRoles] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [edit, setEdit] = useState("");
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteEmployee, setDeleteEmployee] = useState("");
  const [search, setSearch] = useState("");
  const [count, setCount] = useState(0);
  const [resetPage, setResetPage] = useState(null);
  const [isSearch, setIsSearch] = useState(false);
  const [assignStores, setAssignStores] = useState([]);
  const { employeePermission } = useContext(EmployeePermission);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue: setFormValue,
    control,
    reset,
  } = useForm({
    defaultValues: {
      role: "",
      storeId: "",
    },
  });
  //
  useEffect(() => {
    const init = async () => {
      try {
        const { data: roles, status: roleStatues } = await get(
          `${urls.roles.list}`
        );
        if (roleStatues > 199 && roleStatues < 300) {
          setRoles(roles.result.data);
        }
      } catch (error) {}
      try {
        const { data: employees, status: employeeStatues } = await get(
          `${urls.employee.employeeList}`,
          {
            params: {
              limit: 10,
              page: 1,
            },
          }
        );
        if (employeeStatues > 199 && employeeStatues < 300) {
          setEmployees(employees.result.data);
          setCount(employees.result.count);
        }
      } catch (error) {}
      try {
        const { data: stores, status: storeStatus } = await get(
          `${urls.store.list}`
        );
        if (storeStatus > 199 && storeStatus < 299) {
          setStores(stores.result.data);
        }
      } catch (error) {}
    };
    init();
  }, []);

  const handleAddModalClose = () => {
    reset();
    setEdit("");
    setAssignStores([]);
    setAddModalOpen(false);
  };
  const handleAddModalOpen = () => setAddModalOpen(true);

  const onSubmit = async (formData) => {
    try {
      const body = {
        ...formData,
        store: assignStores,
      };

      if (!edit) {
        const { data: employee, status: employeeStatus } = await post(
          `${urls.employee.create}`,
          body
        );
        if (employeeStatus > 199 && employeeStatus < 300) {
          const { data: employees } = await get(
            `${urls.employee.employeeList}`,
            {
              params: {
                search: search ? search : null,
              },
            }
          );
          setEmployees(employees.result.data);
          setCount(employees.result.count);
          handleAddModalClose();
          toast.success("Employee successfully created");
        }
      } else {
        const { data: employee, status: employeeStatus } = await put(
          `${urls.employee.update}${edit}`,
          body
        );
        if (employeeStatus > 199 && employeeStatus < 300) {
          const { data: employees } = await get(
            `${urls.employee.employeeList}`,
            {
              params: {
                search: search ? search : null,
              },
            }
          );
          setEmployees(employees.result.data);
          setCount(employees.result.count);
          handleAddModalClose();
          toast.success("Employee successfully updated");
        }
      }
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };
  const handleDeleteEmployee = async (employeeId) => {
    try {
      const { status } = await get(
        `${urls.employee.employeeDelete}/${employeeId}`
      );
      if (status > 199 && status < 300) {
        setEmployees((prevState) =>
          prevState.filter((employee) => employee._id !== employeeId)
        );
        setCount((prevCount) => prevCount - 1);
        handleDeleteModalClose();
      }
    } catch (error) {}
  };
  const handleEditEmployee = (employee) => {
    setAssignStores(employee.store);
    setEdit(employee._id);
    setFormValue("role", employee.role);
    setFormValue("name", employee.name);
    setFormValue("email", employee.email.address);
    setFormValue("code", employee.code);
    setFormValue("mobile", employee.mobile);
    handleAddModalOpen();
  };
  const handleDeleteModalOpen = () => {
    setDeleteModal(true);
  };
  const handleDeleteModalClose = () => {
    setDeleteModal(false);
    setDeleteEmployee("");
  };
  const cancelSearch = () => {
    setSearch("");
    undoSearch();
  };
  const handleSearch = async () => {
    try {
      if (search) {
        const { data: employees, status: employeeStatues } = await get(
          `${urls.employee.employeeList}`,
          {
            params: {
              search: search,
            },
          }
        );
        if (employeeStatues > 199 && employeeStatues < 300) {
          setEmployees(employees.result.data);
          setCount(employees.result.count);
        }
      }
    } catch (error) {}
  };
  const undoSearch = async () => {
    try {
      const { data: employees, status: employeeStatues } = await get(
        `${urls.employee.employeeList}`,
        {
          params: {
            limit: 10,
            page: 1,
          },
        }
      );
      if (employeeStatues > 199 && employeeStatues < 299) {
        setEmployees(employees.result.data);
        setCount(employees.result.count);
      }
    } catch (error) {}
  };
  const handlePageChange = async (page) => {
    try {
      if (search) {
        const { data: employees, status: employeeStatues } = await get(
          `${urls.employee.employeeList}`,
          {
            params: {
              limit: 10,
              page,
              search,
            },
          }
        );
        if (employeeStatues > 199 && employeeStatues < 299) {
          setEmployees(employees.result.data);
        }
      } else {
        const { data: employees, status: employeeStatues } = await get(
          `${urls.employee.employeeList}`,
          {
            params: {
              limit: 10,
              page,
            },
          }
        );
        if (employeeStatues > 199 && employeeStatues < 299) {
          setEmployees(employees.result.data);
        }
      }
    } catch (error) {}
  };
  const handleAssignStores = (event) => {
    const {
      target: { value },
    } = event;
    console.log(value);
    setAssignStores(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const isXsScreen = useMediaQuery("(max-width:600px)");
  return (
    <div className={css.container}>
      <Grid container py={2}>
        <Grid item xs={12} md={5}>
          {employeePermission &&
            Array.isArray(employeePermission["Employees"]) &&
            employeePermission["Employees"].includes("add") && (
              <Button
                onClick={handleAddModalOpen}
                variant="contained"
                size="small"
              >
                ADD EMPLOYEE
              </Button>
            )}
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: "flex",
            justifyContent: { xs: "flex-start", md: "flex-end" },
            marginTop: { xs: 1, md: 0 },
            alignItems: "center",
          }}
        >
          <TextField
            name="search"
            className={css.searchField}
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            placeholder="Search by name"
            size="small"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {search && (
                    <IconButton edge="end" onClick={cancelSearch}>
                      <Clear />
                    </IconButton>
                  )}
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid
          item
          xs={12}
          md={1}
          sx={{
            display: "flex",
            justifyContent: { xs: "flex-start", md: "flex-end" },
            marginTop: { xs: 1, md: 0 },
            alignItems: "center",
          }}
        >
          <Button onClick={handleSearch} variant="contained" size="small">
            search
          </Button>
        </Grid>
      </Grid>
      {/* </div> */}
      {/* </div> */}
      <TableContainer component={Paper}>
        <Table>
          <TableHead className={tableCss.tableHeader}>
            <TableRow className={tableCss.tableRowHeader}>
              <TableCell align="center">Name</TableCell>
              <TableCell align="center">Employee Code</TableCell>
              <TableCell align="center">Role</TableCell>
              {!isXsScreen && <TableCell align="center">Email</TableCell>}
              {!isXsScreen && <TableCell align="center">Actions</TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {employeePermission &&
              Array.isArray(employeePermission["Employees"]) &&
              employeePermission["Employees"].includes("view") &&
              employees.map((employee) => (
                <React.Fragment key={employee._id}>
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell
                      sx={{ textTransform: "capitalize" }}
                      align="center"
                      className={tableCss.tableData}
                    >
                      {employee.name}
                    </TableCell>
                    <TableCell align="center" className={tableCss.tableData}>
                      {employee.code}
                    </TableCell>
                    <TableCell align="center" className={tableCss.tableData}>
                      {employee.role}
                    </TableCell>
                    {!isXsScreen && (
                      <TableCell align="center" className={tableCss.tableData}>
                        {employee.email.address}
                      </TableCell>
                    )}
                    {!isXsScreen && (
                      <TableCell align="center" className={tableCss.tableData}>
                        <div className={css.tableBtnContainer}>
                          {employeePermission["Employees"].includes("edit") && (
                            <IconButton
                              onClick={() => handleEditEmployee(employee)}
                              sx={{
                                color: "#005A5F",
                              }}
                            >
                              <Edit />
                            </IconButton>
                          )}
                          {employeePermission["Employees"].includes(
                            "delete"
                          ) && (
                            <IconButton
                              sx={{
                                color: "#005A5F",
                              }}
                              onClick={() => {
                                handleDeleteModalOpen();
                                setDeleteEmployee(employee._id);
                              }}
                            >
                              <Delete />
                            </IconButton>
                          )}
                        </div>
                      </TableCell>
                    )}
                  </TableRow>
                  {isXsScreen && (
                    <TableCell
                      colSpan={5}
                      align="right"
                      className={tableCss.tableData}
                      size="small"
                      sx={{ backgroundColor: "#eeeeee", padding: 1 }}
                    >
                      <div className={css.tableBtnContainer}>
                        {employeePermission["Employees"].includes("edit") && (
                          <IconButton
                            onClick={() => handleEditEmployee(employee)}
                            sx={{
                              padding: 0,
                              color: "#005A5F",
                            }}
                          >
                            <Edit />
                          </IconButton>
                        )}
                        {employeePermission["Employees"].includes("delete") && (
                          <IconButton
                            sx={{
                              color: "#005A5F",
                              padding: 0,
                            }}
                            onClick={() => {
                              handleDeleteModalOpen();
                              setDeleteEmployee(employee._id);
                            }}
                          >
                            <Delete />
                          </IconButton>
                        )}
                      </div>
                    </TableCell>
                  )}
                </React.Fragment>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      {!employees.length && (
        <h2 style={{ textAlign: "center" }}>No employees</h2>
      )}
      <PaginationComponent
        page={resetPage}
        limit={10}
        count={count}
        handlePageChange={handlePageChange}
      />
      <Modal open={addModalOpen} onClose={handleAddModalClose}>
        <Box sx={{ ...modalStyle, backgroundColor: "white" }}>
          <div className={css.addStoreHeader}>
            {edit ? "Update Employee" : "Add Employee"}
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className={css.formContainer}>
              <Grid container spacing={2}>
                <Grid item lg={6} sm={12}>
                  <TextField
                    {...register("name", { required: true })}
                    helperText={errors.name && "This field is required"}
                    error={!!errors.name}
                    size="small"
                    variant="outlined"
                    label="Employee Name"
                    className={css.textField}
                  />
                </Grid>
                <Grid item lg={6} sm={12}>
                  <TextField
                    className={css.textField}
                    size="small"
                    variant="outlined"
                    type="email"
                    label="Email"
                    {...register("email", { required: true })}
                    helperText={errors.email && "This field is required"}
                    error={!!errors.email}
                  />
                </Grid>
              </Grid>
            </div>
            <div className={css.formContainer}>
              <Grid container spacing={2}>
                <Grid item lg={6} sm={12}>
                  <TextField
                    {...register("password", { required: true })}
                    helperText={errors.password && "This field is required"}
                    error={!!errors.password}
                    className={css.textField}
                    size="small"
                    variant="outlined"
                    type="password"
                    label="Password"
                  />
                </Grid>
                <Grid item lg={6} sm={12}>
                  <TextField
                    {...register("mobile", { required: true })}
                    helperText={errors.mobile && "This field is required"}
                    error={!!errors.mobile}
                    className={css.textField}
                    size="small"
                    variant="outlined"
                    label="Mobile Number"
                    type="number"
                  />
                </Grid>
              </Grid>
            </div>
            <div className={css.formContainer}>
              <Grid container spacing={2}>
                <Grid item lg={6} sm={12}>
                  <TextField
                    {...register("code", { required: true })}
                    helperText={errors.code && "This field is required"}
                    error={!!errors.code}
                    className={css.textField}
                    size="small"
                    variant="outlined"
                    label="Employee code"
                  />
                </Grid>
                <Grid item lg={6} sm={12}>
                  <FormControl fullWidth>
                    <InputLabel>Assign Store</InputLabel>
                    <Select
                      multiple
                      value={assignStores}
                      onChange={handleAssignStores}
                      size="small"
                      label="Assign Store"
                      name="store"
                      required={true}
                      input={<OutlinedInput label="Assign Store" />}
                      renderValue={(selected) =>
                        selected
                          .map(
                            (select) =>
                              stores.find((store) => store._id === select)?.name
                          )
                          .join(", ")
                      }
                    >
                      {stores.map((store) => (
                        <MenuItem
                          key={store._id}
                          value={store._id}
                          style={{ textTransform: "capitalize" }}
                        >
                          <Checkbox
                            checked={assignStores.indexOf(store._id) > -1}
                          />
                          <ListItemText primary={store.name} />
                        </MenuItem>
                      ))}
                    </Select>
                    {/* {errors.store && <FormHelperText sx={{color:'red'}}>This field is required</FormHelperText>} */}
                  </FormControl>
                </Grid>
              </Grid>
            </div>
            <div className={css.formContainer}>
              <Grid container spacing={2}>
                <Grid item lg={6} sm={12}>
                  <FormControl fullWidth>
                    <InputLabel>Assign Role</InputLabel>
                    <SelectController
                      error={!!errors.role}
                      size="small"
                      label="Assign Role"
                      defaultValue={``}
                      control={control}
                      name="role"
                      required={true}
                    >
                      {roles.map((role) => (
                        <MenuItem
                          key={role.role}
                          value={role.role}
                          style={{ textTransform: "capitalize" }}
                        >
                          {role.role}
                        </MenuItem>
                      ))}
                    </SelectController>
                    {errors.store && (
                      <FormHelperText sx={{ color: "red" }}>
                        This field is required
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>
              </Grid>
            </div>
            <div className={css.formBtn}>
              <Button type="submit" variant="contained">
                Add
              </Button>
              <Button
                onClick={() => handleAddModalClose()}
                type="button"
                variant="contained"
                color="warning"
              >
                Cancel
              </Button>
            </div>
          </form>
        </Box>
      </Modal>
      <Modal open={deleteModal} onClose={handleDeleteModalClose}>
        <Box sx={{ ...deleteModalStyle, backgroundColor: "white" }}>
          <div style={{ padding: "0.7rem" }}>
            <div>Are you sure you want to delete?</div>
            <div
              style={{
                display: "flex",
                gap: "5px",
                justifyContent: "flex-end",
                marginTop: "1rem",
              }}
            >
              <Button
                onClick={handleDeleteModalClose}
                size="small"
                variant="contained"
              >
                No
              </Button>
              <Button
                onClick={() => handleDeleteEmployee(deleteEmployee)}
                size="small"
                variant="contained"
              >
                Yes
              </Button>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default Employee;
