import { Button } from "@mui/material";
import React from "react";

const Payment = (props) => {
  const handlePayment = async () => {
    try {
      // const data = {
      //     amount:500,
      //     currency:'INR',
      // }
      // const pay = axios.post('https://api.razorpay.com/v1/orders',data,{
      //     auth:{
      //         username:'key_test_oPfivodsIRyoCodjO6ljSTbEOVHK2zmf',
      //         password:'secret_test_ho5p4fJK0OJGt7rTUrXzdwffocRNWDSw',
      //     }
      // })
      // console.log(pay)
      // console.log(window.Razorpay);
      var options = {
        key: "rzp_test_x8onj5YWj9pmDo", // Enter the Key ID generated from the Dashboard
        amount: "500", // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
        currency: "INR",
        name: "I2N Technologies", //your business name
        description: "Test Transaction",
        image: "https://example.com/your_logo",
        order_id: "order_LYOfUaFye5bsj8", //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
        callback_url: "https://eneqd3r9zrjok.x.pipedream.net/",
        // prefill: {
        //   name: "Gaurav Kumar", //your customer's name
        //   email: "gaurav.kumar@example.com",
        //   contact: "9000090000",
        // },
        notes: {
          // address: "Razorpay Corporate Office",
        },
        theme: {
          color: "#3399cc",
        },
      };
      const rzp1 = new window.Razorpay(options);
      rzp1.open();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <Button onClick={handlePayment} variant="contained">
        Pay Now
      </Button>
    </div>
  );
};

export default Payment;
