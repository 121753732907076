import * as React from "react";
import {
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Grid,
  Link,
  TextField,
} from "@mui/material";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";

import css from "../styles/pages/Orders.module.css";
import { useEffect } from "react";
import { get, put } from "../api/services/apiMethods";
import urls from "../api/constants";
import { useState } from "react";
import dayjs from "dayjs";
import PaginationComponent from "../components/Pagination";
import { getFileType } from "../utils/utils";
import EmployeePermission from "../context/employeePermission";
import { useContext } from "react";
import { toast } from "react-toastify";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

const steps = ["Order accepted", "Printing", "Ready to pickup"];
const data = [
  {
    orderId: "779879876",
    date: "10-10-2022/10:20 AM",
    cost: 15,
  },
  {
    orderId: "779879875",
    date: "12-11-2022/10:20 AM",
    cost: 70,
  },
];
const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const StoreOrders = () => {
  const [activeStep, setActiveStep] = React.useState(1);
  const [modalOpen, setModalOpen] = React.useState(false);
  const [modalData, setModalData] = React.useState(undefined);
  const [orders, setOrders] = useState([]);
  const [count, setCount] = useState(0);
  const [filterForm, setFilterForm] = useState({});
  const [isSearch, setIsSearch] = useState(false);
  const [resetPag, setResetPag] = useState(false);
  const [stores, setStores] = useState([]);
  const { employeePermission } = useContext(EmployeePermission);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);

  useEffect(() => {
    const getOrders = async () => {
      try {
        if (
          employeePermission &&
          Array.isArray(employeePermission["Orders"]) &&
          employeePermission["Orders"].includes("view")
        ) {
          const { data: stores, statusStore } = await get(`${urls.store.list}`);
          if (stores.result && stores.result && stores.result.data.length > 0) {
            setStores(stores.result.data);
            const storesList = stores.result.data;
            setFilterForm((prev) => ({
              ...prev,
              store: storesList[0]._id,
            }));
          }
        }
      } catch (error) {
        console.log(error);
      }
    };
    getOrders();
  }, [employeePermission]);
  useEffect(() => {
    if (resetPag) {
      setResetPag(false);
    }
  }, [resetPag]);

  useEffect(() => {
    const changeStore = async () => {
      if (filterForm.store) {
        const { data: orders, status } = await get(`${urls.orders.list}`, {
          params: {
            limit: 25,
            page: 1,
            storeId: filterForm.store,
          },
        });
        if (status > 199 && status < 299) {
          setOrders(orders.result.data);
          console.log("setOrders", orders.result.data);

          setCount(orders.result.count);
          setIsSearch(false);
          setResetPag(true);
        }
      }
    };
    changeStore();
  }, [filterForm.store]);

  // useEffect(() => {
  // const changeStore = async () => {
  //         const {data:orders,status} = await get(`${urls.orders.list}`,{
  //           params:{
  //             limit:25,
  //             page:1,
  //             storeId:filterForm.store
  //           }
  //         });
  //         if(status > 199 && status < 299) {
  //           setOrders(orders.result.data);
  //           setCount(orders.result.count);
  //         }

  //   }
  //   changeStore();
  // },[filterForm.store])

  //   useEffect(() => {
  //     const getUsersStores = async () => {
  //         const {data:stores,status} = await get(`${urls.store.list}`);
  //         if(status > 199 && status < 299) {
  //             setStores(stores.result.data);
  //         }
  //     }
  //     getUsersStores();
  // },[])

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleModalClose = () => {
    setModalOpen(false);
    setModalData(undefined);
  };
  const handleModalOpen = (dataIndex) => {
    const inject = orders.find((d, index) => dataIndex === index);
    setModalData(inject);
    setModalOpen(true);
  };
  const handleStatusChange = async (id, orderStatus) => {
    const toastId = toast.loading("Changing order status", {
      autoClose: true,
    });
    try {
      const { status } = await put(`${urls.orders.update}${id}`, {
        status: orderStatus,
      });
      if (status > 199 && status < 299) {
        // const {data:orders,status} = await get(`${urls.orders.list}`,{

        // });
        // if(status > 199 && status < 299) setOrders(orders.result.data);
        setOrders((orders) =>
          orders.map((order) =>
            order._id === id ? { ...order, status: orderStatus } : order
          )
        );
        toast.update(toastId, {
          render: "Order status changed",
          type: "success",
          isLoading: false,
          progress: 1,
        });
      }
    } catch (error) {
      toast.update(toastId, {
        render: "Something failed!",
        type: "error",
        isLoading: false,
        progress: 1,
      });
    }
  };
  const handlePageChange = async (page) => {
    try {
      if (isSearch) {
        const { data: orders, status } = await get(`${urls.orders.list}`, {
          params: {
            status: filterForm.status,
            limit: 25,
            page: page,
            storeId: filterForm.store,
          },
        });
        if (status > 199 && status < 299) {
          setOrders(orders.result.data);
          setCount(orders.result.count);
        }
      } else {
        const { data: orders, status } = await get(`${urls.orders.list}`, {
          params: {
            limit: 25,
            page: page,
            storeId: filterForm.store,
          },
        });
        if (status > 199 && status < 299) {
          setOrders(orders.result.data);
          setCount(orders.result.count);
        }
      }
    } catch (error) {}
  };
  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilterForm((filter) => ({
      ...filter,
      [name]: value,
    }));
    if (isSearch) {
      setResetPag(true);
    }
  };
  const undoFilter = async () => {
    setFromDate(null);
    setToDate(null);
    try {
      const { data: orders, status } = await get(`${urls.orders.list}`, {
        params: {
          limit: 25,
          page: 1,
          storeId: filterForm.store,
        },
      });
      if (status > 199 && status < 299) {
        setOrders(orders.result.data);
        setCount(orders.result.count);
      }
      setIsSearch(false);
      setResetPag(true);
    } catch (error) {}
    setFilterForm((prev) => ({
      ...prev,
      status: "",
    }));
    setIsSearch(false);
    setResetPag(true);
  };
  const handleFilterSubmit = async (e) => {
    e.preventDefault();

    if (fromDate && !toDate) return alert("Please select to date!");
    if (!fromDate && toDate) return alert("Please select to from date!");

    let params = {
      status: filterForm.status,
      limit: 25,
      page: 1,
      storeId: filterForm.store,
    };
    if (fromDate) {
      params["startDate"] = dayjs(fromDate).toDate();
    }
    if (toDate) {
      params["endDate"] = dayjs(toDate).toDate();
    }
    try {
      const { data: orders, status } = await get(`${urls.orders.list}`, {
        params: params,
      });
      if (status > 199 && status < 299) {
        setOrders(orders.result.data);
        setCount(orders.result.count);
        setIsSearch(true);
        setResetPag(true);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const displayOrderText = (item) => {
    let text = "";
    if (item.color === "bw") {
      text += "black/white-";
    } else {
      text += "color-";
    }
    if (item.paperSize === "a4") {
      text += "A4-";
    } else {
      text += "A5-";
    }
    if (item.paperQuality === "100gsm") {
      text += "100gsm-";
    } else {
      text += "80gsm-";
    }
    if (item.binding === "Spiral") {
      text += "Spiral";
    } else if (item.binding === "Staples") {
      text += "Staples";
    } else {
      text += "No binding";
    }
    return text;
  };
  const getItemsInText = (item, field) => {
    if (field === "color") {
      return item.color === "bw" ? "black/white" : "color";
    }
    if (field === "paperSize") {
      return item.paperSize === "a4" ? "A4" : "A5";
    }
    if (field === "paperQuality") {
      return item.paperQuality === "100gsm" ? "100gsm" : "80gsm";
    }
    if (field === "binding") {
      return item.binding === "Spiral" ? "Spiral" : "Staples";
    }
  };

  return (
    <div className={css.container}>
      <form className={css.filterContainer} onSubmit={handleFilterSubmit}>
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            md={6}
            lg={3}
            sx={{
              display: "flex",
              justifyContent: { xs: "flex-start", md: "flex-end" },
            }}
          >
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                Select Store
              </InputLabel>
              <Select
                value={filterForm.store || ""}
                label="Select Store"
                name="store"
                size="small"
                required
                onChange={handleFilterChange}
              >
                {stores.map((store) => (
                  <MenuItem key={store._id} value={store._id}>
                    {store.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6} lg={3} sx={{ alignSelf: "center" }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="From Date"
                inputFormat="DD-MM-YYYY"
                value={fromDate}
                onChange={(newValue) => setFromDate(dayjs(newValue))}
                renderInput={(params) => (
                  <TextField {...params} size="small" fullWidth />
                )}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12} md={6} lg={3} sx={{ alignSelf: "center" }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="To Date"
                inputFormat="DD-MM-YYYY"
                value={toDate}
                onChange={(newValue) => setToDate(dayjs(newValue))}
                renderInput={(params) => (
                  <TextField {...params} size="small" fullWidth />
                )}
              />
            </LocalizationProvider>
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Status</InputLabel>
              <Select
                value={filterForm.status || ""}
                label="Status"
                name="status"
                size="small"
                required
                onChange={handleFilterChange}
              >
                <MenuItem value={"Pending"}>Pending</MenuItem>
                <MenuItem value={"accepted"}>Accept</MenuItem>
                <MenuItem value={"printing"}>Printing</MenuItem>
                <MenuItem value={"ready"}>Ready</MenuItem>
                <MenuItem value={"failed"}>Failed</MenuItem>
                <MenuItem value={"collected"}>Collected</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid
            item
            sx={{ display: "flex", alignItems: "center" }}
            xs={12}
            sm={6}
            md={3}
          >
            <Button variant="contained" type="submit" size="small">
              Search
            </Button>
            {isSearch || fromDate !== null || toDate !== null ? (
              <Button
                variant="contained"
                type="button"
                size="small"
                sx={{ marginLeft: "20px" }}
                onClick={undoFilter}
              >
                Reset
              </Button>
            ) : null}
          </Grid>
        </Grid>
      </form>
      {employeePermission &&
        Array.isArray(employeePermission["Orders"]) &&
        employeePermission["Orders"].includes("view") &&
        orders.map((order, index) => (
          <Card key={order._id} className={css.cardContainer}>
            <CardContent>
              <div className={css.orderDetailsContainer}>
                <div className={css.orderId}>
                  {order.isPaid ? (
                    <Button color="success">Paid</Button>
                  ) : (
                    <Button color="error">Not paid</Button>
                  )}
                </div>
                <div className={css.date}>
                  {dayjs(order.createdAt).format("DD-MM-YYYY")}
                </div>
                <div className={css.cost}>₹{order.totalCost}</div>
                <div>
                  <Button
                    onClick={() => handleModalOpen(index)}
                    size="small"
                    variant="contained"
                  >
                    VIEW ORDER DETAILS
                  </Button>
                </div>
                <div></div>
                <div>
                  <FormControl>
                    <InputLabel>Status</InputLabel>
                    <Select
                      sx={{ width: "150px" }}
                      label="Status"
                      value={order.status}
                      //disabled={order.status === "failed"}
                      onChange={(e) =>
                        handleStatusChange(order._id, e.target.value)
                      }
                      size="small"
                    >
                      <MenuItem value={"accepted"}>Accept</MenuItem>
                      <MenuItem value={"printing"}>Printing</MenuItem>
                      <MenuItem value={"ready"}>Ready to Pick</MenuItem>
                      <MenuItem value={"rejected"}>Reject</MenuItem>
                      <MenuItem disabled value={"Pending"}>
                        Pending
                      </MenuItem>
                      <MenuItem value={"failed"}>Failed</MenuItem>
                      <MenuItem value={"collected"}>Collected</MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </div>
              <Box sx={{ width: "100%" }}>
                <Stepper
                  activeStep={
                    (order.status === "Pending" && -1) ||
                    (order.status === "accepted" && 1) ||
                    (order.status === "printing" && 2) ||
                    (order.status === "ready" && 3)
                  }
                >
                  {steps.map((label, index) => {
                    return (
                      <Step
                        onClick={() => handleStatusChange(label)}
                        sx={{ cursor: "pointer" }}
                        key={label}
                      >
                        <StepLabel>{label}</StepLabel>
                      </Step>
                    );
                  })}
                </Stepper>
              </Box>
            </CardContent>
          </Card>
        ))}
      {!orders.length && <h2 style={{ textAlign: "center" }}>No orders</h2>}
      <PaginationComponent
        page={resetPag && 1}
        limit={25}
        count={count}
        handlePageChange={handlePageChange}
      />
      <Dialog open={modalOpen} onClose={handleModalClose} scroll="paper">
        <DialogTitle>Order details</DialogTitle>
        <DialogContent dividers={true}>
          {modalData && (
            // <div>{modalData._id}</div>
            <div className={css.modalContainer}>
              {modalData.items.map((item, index) => (
                <Paper
                  key={item._id}
                  elevation={2}
                  className={css.fileConfigContainer_paper}
                >
                  <div className={css.fileConfigContainer}>
                    <div>File {index + 1}</div>
                    <div>
                      File url :{" "}
                      <Link
                        underline="hover"
                        href={item.documents}
                        title="Download file"
                      >
                        Download
                      </Link>
                    </div>
                    <div>
                      Xerox color:{" "}
                      {item.colors.color === "color"
                        ? "Color"
                        : item.colors.color === "colpar"
                        ? "Multi-Color"
                        : "Black&White"}
                    </div>
                    <div>
                      Pages to Print : {item.pageRange[0]} to{" "}
                      {item.pageRange[1]}
                    </div>
                    {item.colors.color === "colpar" ? (
                      <div>
                        <div>
                          Total Black Pages :{" "}
                          {item.totalPages - item.colorPar.pageNumbers.length}{" "}
                        </div>
                        <div>
                          Total Color Pages : {item.colorPar.pageNumbers.length}{" "}
                        </div>
                        <div>
                          Color Page Nos. to print :{" "}
                          {item.colorPar.pageNumbers.join(",")}{" "}
                        </div>
                      </div>
                    ) : null}
                    <div>Instructions : {item.instructions || "NA"}</div>
                    <div>Total Pages : {item.totalPages}</div>
                    <div>
                      Sides to print on :{" "}
                      {item.side === "one" ? "Single" : "Double"}
                    </div>

                    <div>Paper size : {getItemsInText(item, "paperSize")}</div>
                    <div>Binding : {getItemsInText(item, "binding")}</div>
                    <div>Copies : {item.copies}</div>

                    {item.bondPage.selected !== "yes" ? null : (
                      <div>
                        <div>
                          BondPage : {item.bondPage ? item.bondPage.total : "0"}
                        </div>
                      </div>
                    )}
                    <div>Cost : ₹{item.cost} </div>
                  </div>
                </Paper>
              ))}
              <Paper elevation={2} className={css.totalCostPaper}>
                <div>Total Cost : ₹{modalData.totalCost}</div>
                <div>Order Status : {modalData.status}</div>
                <div>
                  Payment status : {modalData.isPaid ? "Paid" : "Not paid"}
                </div>
              </Paper>
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button color="warning" onClick={() => setModalOpen(false)}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
export default StoreOrders;
