import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import css from "../styles/pages/Login.module.css";
import { post } from "../api/services/apiMethods";
import urls from "../api/constants";
import { toast } from "react-toastify";
import { objHas } from "../utils/utils";
import { LoadingButton } from "@mui/lab";
import { useNavigate } from "react-router-dom";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({});
  const [formError, setFormError] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [submittedEmail, setSubmittedEmail] = useState(false);
  const [otp, setOtp] = useState("");
  const [header, setHeader] = useState("");
  const [settings, setSettings] = useState(null);
  const [settingOtp, setSettingOtp] = useState(false);
  const [resettingPassword, setResettingPassword] = useState(false);

  useEffect(() => {
    const logoURL = require("./image/logo3.jpg");
    setSettings({ ...settings, logo: { link: logoURL } });
  }, []);

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (!submittedEmail) {
        setSettingOtp(true);
        const { data: otp, status } = await post(`${urls.auth.genOtp}`, {
          mobile: formData.mobile,
          password: "random",
        });
        if (status > 199 && status < 299) {
          setSubmittedEmail(true);
          setHeader("Otp has been sent to your mobile");
        }
        setSettingOtp(false);
      } else {
        setResettingPassword(true);
        const { data, status } = await post(`${urls.auth.resetPassword}`, {
          password: formData.password,
          otp: formData.resetCode,
          mobile: formData.mobile,
        });
        if (status > 199 && status < 299) {
          setHeader("login again with new password");
          toast.success("Password reset successful");
          setTimeout(() => {
            navigate("/login", { replace: true });
          }, 2000);
        }
        setResettingPassword(false);
      }
    } catch (error) {
      setResettingPassword(false);
      setSettingOtp(false);
      toast.error(error?.response?.data?.message);
      console.log(error);
    }
  };
  return (
    <div className={css.loginContainer}>
      <div className={css.flexContainer}>
        <div>
          {settings && (
            <img
              src={settings && objHas(settings, "logo.link", "")}
              alt="logo"
              style={{
                width: "260px",
                marginLeft: "-5px",
                marginTop: "-10px",
              }}
            />
          )}
        </div>
        <div style={{ marginTop: "100px" }}>
          <div className={css.header}>Forgot Password</div>
          <div className={css.subHeader}>
            {!header && `Please enter your mobile number to receive OTP`}
          </div>
          <div className={css.formContainer}>
            <form onSubmit={handleSubmit}>
              {!submittedEmail && (
                <div>
                  {/* <div className={css.formHolder}>
                    <TextField
                      helperText={
                        formError && !formData.email && "Provide field"
                      }
                      error={formError && !formData.email}
                      fullWidth
                      onChange={handleFormChange}
                      value={formData.email || ""}
                      name="email"
                      label="Email"
                      variant="outlined"
                      sx={{
                        backgroundColor: "#F5F5F5",
                      }}
                    />
                  </div> */}
                  <div className={css.formHolder}>
                    <TextField
                      helperText={
                        formError && !formData.mobile && "Provide field"
                      }
                      error={formError && !formData.mobile}
                      fullWidth
                      onChange={handleFormChange}
                      value={formData.mobile || ""}
                      name="mobile"
                      label="Mobile"
                      variant="outlined"
                      type="number"
                      sx={{
                        backgroundColor: "#F5F5F5",
                      }}
                    />
                  </div>
                </div>
              )}
              {submittedEmail && (
                <div>
                  <div className={css.formHolder}>
                    <TextField
                      helperText={
                        formError && !formData.resetCode && "Provide field"
                      }
                      error={formError && !formData.resetCode}
                      fullWidth
                      onChange={handleFormChange}
                      value={formData.resetCode || ""}
                      name="resetCode"
                      label="Reset Code"
                      variant="outlined"
                      sx={{
                        backgroundColor: "#F5F5F5",
                      }}
                    />
                  </div>
                  <div className={css.formHolder}>
                    <TextField
                      type={showPassword ? "text" : "password"}
                      helperText={
                        formError && !formData.password && "Provide field"
                      }
                      error={formError && !formData.password}
                      fullWidth
                      onChange={handleFormChange}
                      value={formData.password || ""}
                      name="password"
                      label="New Password"
                      variant="outlined"
                      sx={{
                        backgroundColor: "#F5F5F5",
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              edge="end"
                              aria-label="toggle password visibility"
                              onClick={() => setShowPassword(!showPassword)}
                            >
                              {showPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </div>
                </div>
              )}
              <Box
                style={{
                  justifyContent: "space-between",
                  display: "flex",
                  width: "100%",
                }}
              >
                <div className={css.forgotText}>
                  Remember Password?<Link to="/login">Login</Link>
                </div>

                <LoadingButton
                  loading={settingOtp || resettingPassword}
                  variant="contained"
                  type="submit"
                >
                  {submittedEmail ? "reset password" : "submit"}
                </LoadingButton>
              </Box>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
