import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { get, put } from "../api/services/apiMethods";
import urls from "../api/constants";

// styles
import css from "../styles/pages/Permissions.module.css";
import tableCss from "../styles/pages/components/Table.module.css";

import {
  Button,
  Checkbox,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { toast } from "react-toastify";

const Permissions = () => {
  const [role, setRole] = useState(null);
  const [modules, setModules] = useState([]);
  const [permissions, setPermissions] = useState({});

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const pathRole = location.state.role;
    console.log(pathRole, "path role===========");
    const init = async () => {
      try {
        const { data: resRole, status } = await get(
          `${urls.roles.get}${pathRole._id}`
        );
        if (status > 199 && status < 299) {
          setRole(resRole.result);
          if (resRole.result.permissions) {
            setPermissions({
              ...resRole.result.permissions,
            });
          }
        }
        const { data: modules, status: modulesStatus } = await get(
          `${urls.roles.modules}`
        );
        if (modulesStatus > 199 && modulesStatus < 299) {
          setModules([...modules.result]);
        }
      } catch (error) {
        console.log(error);
      }
    };
    init();
  }, []);

  const handlePermissionChange = (module, permission) => {
    if (
      Array.isArray(permissions[module]) &&
      permissions[module].includes(permission)
    ) {
      setPermissions({
        ...permissions,
        [module]: permissions[module].filter((item) => item !== permission),
      });
    } else if (!Array.isArray(permissions[module])) {
      setPermissions({
        ...permissions,
        [module]: [permission],
      });
    } else {
      const temp = [...permissions[module]];
      temp.push(permission);
      setPermissions({
        ...permissions,
        [module]: [...temp],
      });
    }
  };
  const handleSubmit = async () => {
    const id = location.state.role && location.state.role._id;
    if (id) {
      const { status } = await put(`${urls.roles.update}${id}`, {
        permissions,
      });
      if (status > 199 && status < 299) {
        const { data: role, status } = await get(`${urls.roles.get}${id}`);
        if (status > 199 && status < 299) {
          if (role.result.permissions) {
            setPermissions({
              ...role.result.permissions,
            });
            toast.success("Role permissions are set");
          }
        }
      }
    }
  };
  return (
    <div className={css.container}>
      <Typography sx={{ fontWeight: "bold", padding: "20px 0 10px 5px" }}>
        Edit role permissions for : {location.state?.role?.role}
      </Typography>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }}>
          <TableHead className={tableCss.tableHeader}>
            <TableRow className={tableCss.tableRowHeader}>
              <TableCell align="left">Modules</TableCell>
              <TableCell align="right">View</TableCell>
              <TableCell align="right">Add</TableCell>
              <TableCell align="right">Edit</TableCell>
              <TableCell align="right">Delete</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {modules.map((module, index) => (
              <TableRow
                key={index}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell align="left">{module}</TableCell>
                <TableCell align="right">
                  <Checkbox
                    onChange={() => handlePermissionChange(module, "view")}
                    checked={
                      Array.isArray(permissions[module]) &&
                      permissions[module].includes("view")
                    }
                  />
                </TableCell>
                <TableCell align="right">
                  <Checkbox
                    onChange={() => handlePermissionChange(module, "add")}
                    checked={
                      Array.isArray(permissions[module]) &&
                      permissions[module].includes("add")
                    }
                  />
                </TableCell>
                <TableCell align="right">
                  <Checkbox
                    onChange={() => handlePermissionChange(module, "edit")}
                    checked={
                      Array.isArray(permissions[module]) &&
                      permissions[module].includes("edit")
                    }
                  />
                </TableCell>
                <TableCell align="right">
                  <Checkbox
                    onChange={() => handlePermissionChange(module, "delete")}
                    checked={
                      Array.isArray(permissions[module]) &&
                      permissions[module].includes("delete")
                    }
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <div className={css.btnContainer}>
        <Button
          onClick={() => navigate("/store/roles")}
          variant="contained"
          color="error"
        >
          cancel
        </Button>
        <Button onClick={handleSubmit} variant="contained">
          set permission
        </Button>
      </div>
    </div>
  );
};
export default Permissions;
