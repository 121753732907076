import Axios from "axios";
import { redirect } from "react-router-dom";
import { toast } from "react-toastify";

export const API_PREFIX = "https://dittox.in/xerox/v1";
// export const API_PREFIX = "http://localhost:3001/xerox/v1";

const client = Axios.create({
  baseURL: API_PREFIX,
});

client.interceptors.request.use(
  function (config) {
    const access_token = window.localStorage.getItem("access_token");
    if (access_token) {
      config.headers["X-auth-token"] = `bearer ${access_token}`;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

client.interceptors.response.use(
  function (config) {
    return config;
  },
  function (error) {
    if (error.response.status === 401) {
      window.localStorage.removeItem("access_token");
      toast.error("Please login");
      window.location.href = "/login";
    }
    return Promise.reject(error);
  }
);

export default client;
