import { RadioButtonChecked, RadioButtonUnchecked } from "@mui/icons-material";
import {
  Card,
  CardContent,
  Checkbox,
  Rating,
  Grid,
  Typography,
  Paper,
  Box,
  CardMedia,
  Container,
  IconButton,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import moment from "moment";
import css from "../styles/components/SelectStoreCard.module.css";
import { formatDistance, objHas } from "../utils/utils";
import { Directions } from "@mui/icons-material";
import { Button } from "@mui/material";
import Printer from "../assets/images/printer.jpg";
import getDistance from "geolib/es/getDistance";

const SelectStoreCard = (props) => {
  console.log(props, "props");
  const [value, setValue] = React.useState(2);

  const [totalCost, setTotalCost] = useState(0);
  console.log(props.totalPages, "totalPages");

  const handleChecked = () => {
    props.handleSelected(props.store._id);
  };

  const openingTime = objHas(props, "store.meta.openingTime")
    ? moment(props.store.meta.openingTime, "HH:mm A").format("hh:mm A")
    : "";
  const closingTime = objHas(props, "store.meta.closingTime")
    ? moment(props.store.meta.closingTime, "HH:mm A").format("hh:mm A")
    : "";

  const label = { inputProps: { "aria-label": "Checkbox demo" } };
  const handleDirections = (storeX, storeY) => {
    const url = `https://www.google.com/maps/dir/?api=1&destination=${storeX},${storeY}`;
    window.open(url, "_blank");
  };

  const handleViewOrder = () => {
    if (props.store) {
      const items = [...props.items];
      const costDetails = [];
      let totalOrderCost = 0;
      items.forEach((item) => {
        let cost = {};
        cost.id = items.indexOf(item) + 1;
        cost.item = { ...item };
        cost.details = item.instructions;
        cost.pageRange = item.pageRange;
        let totalCost = 0;

        // calculate cost for one side;
        let sideCost = 0;
        if (item.sides == "one") {
          cost.side = "one";
          sideCost = Number(props.store.meta.costOneSide);
        } else {
          cost.side = "both";
          sideCost = Number(props.store.meta.costTwoSide);
        }

        // calculate cost for color/bw/colorPar
        let totalBlackPages = 0;
        let totalColorPages = 0;
        let blackCost = 0;
        let colorCost = 0;

        if (item.colors.color === "bw") {
          cost.color = "Black&White";
          totalBlackPages = item.totalPages;
          blackCost = Number(props.store.meta.costBlack);
        } else if (item.colors.color === "multi") {
          totalColorPages = item.totalPages;
          cost.color = "Color";
          colorCost = Number(props.store.meta.costColor);
        } else {
          cost.color = "Multi-Color";
          totalColorPages = item.colorPar.pageNumbers.length;
          totalBlackPages = item.totalPages - totalColorPages;
          blackCost = Number(props.store.meta.costBlack);
          colorCost = Number(props.store.meta.costColor);
        }

        // calculate paper size cost
        let paperSizeCost = 0;
        if (item.paperSize == "a4") {
          cost.size = "A4";
          paperSizeCost = parseInt(props.store.meta["sizeA4"]);
        } else if (item.paperSize == "a5") {
          cost.size = "A5";
          paperSizeCost = parseInt(props.store.meta["sizeA5"]);
        } else if (item.paperSize == "legal") {
          cost.size = "Legal";
          paperSizeCost = parseInt(props.store.meta["legal"]);
        } else if (item.paperSize == "letter") {
          cost.size = "Letter";
          paperSizeCost = parseInt(props.store.meta["letter"]);
        } else if (item.paperSize == "b5") {
          cost.size = "B5";
          paperSizeCost = parseInt(props.store.meta["sizeB5"]);
        } else if (item.paperSize == "a6") {
          cost.size = "A6";
          paperSizeCost = parseInt(props.store.meta["sizeA6"]);
        } else if (item.paperSize == "postCard") {
          cost.size = "Postcard";
          paperSizeCost = parseInt(props.store.meta["postCard"]);
        } else if (item.paperSize == "5*7") {
          cost.size = "5*7";
          paperSizeCost = parseInt(props.store.meta["size5X7"]);
        } else if (item.paperSize == "4*6") {
          cost.size = "4*6";
          paperSizeCost = parseInt(props.store.meta["size4X6"]);
        } else if (item.paperSize == "3.5*5") {
          cost.size = "3.5*5";
          paperSizeCost = parseInt(props.store.meta["size35X5"]);
        }

        let bondPrice =
          item.bondPage.selected == "yes"
            ? parseInt(props.store.meta["bondPage"])
            : 0;

        // calculate binding cost
        let bindingCost = 0;
        if (item.binding === "Staples") {
          cost.binding = "Staples Binding";
          bindingCost = Number(props.store.meta.staplesBinding);
        } else if (item.binding === "Spiral") {
          cost.binding = "Spiral Binding";
          bindingCost = Number(props.store.meta.spiralBinding);
        } else if (item.binding === "StickFile") {
          cost.binding = "Stick File";
          bindingCost = Number(props.store.meta.stickFile);
        } else {
          cost.binding = "No Binding";
        }

        let totalColorPagesCost = totalColorPages * colorCost;
        let totalBlackPagesCost = totalBlackPages * blackCost;
        totalCost =
          (totalColorPagesCost +
            totalBlackPagesCost +
            paperSizeCost +
            sideCost +
            bondPrice) *
            item.copies +
          bindingCost;
        cost.copies = item.copies;
        cost.totalCost = totalCost;
        costDetails.push(cost);
        totalOrderCost = totalOrderCost + cost.totalCost;
      });

      setTotalCost(totalOrderCost);
      console.log("setTotalOrderCost", totalOrderCost);
    }
  };

  useEffect(() => {
    if (props.store) {
      handleViewOrder();
    }
  }, [props.store]);

  return (
    <Container
      sx={{
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Box
        onClick={handleChecked}
        className={`${css.container} ${
          props.selected === props.store._id ? css.selected : null
        }`}
      >
        <Grid item md={12} display="flex" p={2} gap={1}>
          <Box>
            <img
              height={70}
              width={70}
              style={{ borderRadius: "10%" }}
              src={Printer}
              alt=""
            />
          </Box>
          <Box>
            <Typography sx={{ fontSize: "14px" }}>
              Store Name: {props.store.name}
            </Typography>
            <Box sx={{ display: "flex" }}>
              <Typography sx={{ fontSize: "14px" }}>
                {props.store.avgRating.toString().substring(0, 3)}
              </Typography>
              <Rating
                sx={{ marginTop: 0.2 }}
                name="read-only"
                value={props.store.avgRating}
                readOnly
                size="small"
              />
              <Typography sx={{ fontSize: "14px" }}>
                ({props.store.feedBack?.length})
              </Typography>
            </Box>
            <Typography sx={{ fontSize: "14px" }}>
              {" "}
              <b style={{ color: "green" }}>Open:</b> {openingTime}
            </Typography>
            <Typography sx={{ fontSize: "14px" }}>
              {" "}
              <b style={{ color: "red" }}>Closes: </b> {closingTime}
            </Typography>
            <Typography sx={{ fontSize: "14px" }}>
              Estimated Cost:
              <span style={{ fontWeight: "bold" }}>
                ₹{totalCost.toFixed(2)}
              </span>
            </Typography>
          </Box>
        </Grid>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
          }}
        >
          {props.userX &&
          props.userY &&
          props.store.location.x &&
          props.store.location.y ? (
            <Box>
              <Typography
                sx={{ fontSize: "14px" }}
                color="dodgerblue"
                fontWeight={"bold"}
              >
                {formatDistance(
                  getDistance(
                    { lat: props.userX, lon: props.userY },
                    {
                      lat: props.store.location.x,
                      lon: props.store.location.y,
                    },
                    10
                  )
                )}{" "}
                from your location.
              </Typography>
            </Box>
          ) : null}

          <Box sx={{ marginBottom: "4px" }}>
            <IconButton
              sx={{ backgroundColor: "blue" }}
              variant="contained"
              size="small"
              onClick={() =>
                handleDirections(props.store.location.x, props.store.location.y)
              }
            >
              <Directions sx={{ color: "white" }} />
            </IconButton>
          </Box>
        </Box>
      </Box>
    </Container>
  );
};

export default SelectStoreCard;
