// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import "../config/firebaseBackground";
import OrderNotification from "../components/OrderNotification";
import { toast } from "react-toastify";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCvwNEikxWhvoqWrJ4wTIdvwymTLVR2ZiM",
  authDomain: "xerox-89641.firebaseapp.com",
  projectId: "xerox-89641",
  storageBucket: "xerox-89641.appspot.com",
  messagingSenderId: "585571330776",
  appId: "1:585571330776:web:66028c90d42acbdf4885ad",
  measurementId: "G-1WEM77W41C",
};

// const firebaseConfig = {
//   apiKey: "AIzaSyAwC-3QhH_jsXAiqtr_87QZh-ikee0jEFI",
//   authDomain: "ecampusstreet.firebaseapp.com",
//   projectId: "ecampusstreet",
//   storageBucket: "ecampusstreet.appspot.com",
//   messagingSenderId: "424998668986",
//   appId: "1:424998668986:web:2b65715362c78bc522d083",
//   measurementId: "G-3QT9WRTHWV",
// };

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const getTokenGen = (setTokenFound) => {
  return getToken(messaging, {
    vapidKey:
      "BK6OE1A4iG1T-2jgG_mH_pav7Vjh0jjRfKwLcuE6eMBtKhpbNj5T75FMBa1z2lJsKArdGogE3QwhsideWArrnDs",
  })
    .then((currentToken) => {
      if (currentToken) {
        console.log("current token for client: ", currentToken);
        setTokenFound(currentToken);
        window.localStorage.setItem("firebaseToken", currentToken);
        // Track the token -> client mapping, by sending to backend server
        // show on the UI that permission is secured
      } else {
        console.log(
          "No registration token available. Request permission to generate one."
        );
        setTokenFound(false);
        // shows on the UI that permission is required
      }
    })
    .catch((err) => {
      console.log("An error occurred while retrieving token. ", err);
      // catch error while creating client token
    });
};

export const messaging = getMessaging(app);

export const onMessageListener = () => {
  return new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });
};

// BK6OE1A4iG1T-2jgG_mH_pav7Vjh0jjRfKwLcuE6eMBtKhpbNj5T75FMBa1z2lJsKArdGogE3QwhsideWArrnDs
//BK6OE1A4iG1T-2jgG_mH_pav7Vjh0jjRfKwLcuE6eMBtKhpbNj5T75FMBa1z2lJsKArdGogE3QwhsideWArrnDs
