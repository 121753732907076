import React from "react";

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "flex",
        background: "green",
        borderRadius: "25px",
        height: "30px",
        width: "30px",
        justifyContent: "center",
        alignItems: "center",
        paddingTop: "3px",
      }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "flex",
        background: "green",
        borderRadius: "25px",
        height: "30px",
        width: "30px",
        justifyContent: "center",
        alignItems: "center",
        paddingTop: "3px",
        zIndex: 22,
      }}
      onClick={onClick}
    />
  );
}

export const calculateSlidersData = (length) => {
  return {
    infinite: true,
    speed: 500,
    slidesToShow: length >= 4 ? 3 : length,
    slidesToScroll: 1,
    initialSlide: 0,
    // autoplay: true,
    autoplaySpeed: 2000,
    pauseOnHover: true,
    arrows: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,

    responsive: [
      {
        breakpoint: 1536,
        settings: {
          slidesToShow: length >= 4 ? 3 : length,
          slidesToScroll: 2,
          arrows: true,
        },
      },
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: length >= 3 ? 3 : length,
          slidesToScroll: 1,
          arrows: true,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: length >= 2 ? 2 : length,
          slidesToScroll: 1,
          arrows: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: true,
        },
      },
      {
        breakpoint: 300,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: true,
        },
      },
    ],
  };
};
