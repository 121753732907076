import React from 'react';

import css from "../styles/pages/Stores.module.css";
import tableCss from '../styles/pages/components/Table.module.css';
import { Button,Box, IconButton, Paper, Rating, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Grid } from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { useMediaQuery } from '@mui/material';

const TableData = (props) => {

    const navigate = useNavigate();
    const isXsScreen = useMediaQuery('(max-width:600px)');

    return (
        <>

            <TableContainer component={Paper}>
                <Table>
                    <TableHead className={tableCss.tableHeader}>
                        <TableRow className={tableCss.tableRowHeader}>
                            {props.headData.map((data, index) => (
                                <>
                                    {isXsScreen && index > 1 ? null : <TableCell key={index} align={data.align}>
                                        {data.label}
                                    </TableCell>
                                    }
                                </>

                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props.tableData.map((data, index) => (
                            <React.Fragment key={data._id}>
                                <TableRow
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    {props.tableSelect.map((select, index) => (
                                        isXsScreen && index > 1 ? null : <TableCell sx={{ textTransform: 'capitalize' }} key={index} align={select.align} className={tableCss.tableData}>{select.key === 'avgRating' ? <div onClick={() => props.handleSelectFeedBack(data)} style={{ cursor: 'pointer' }}>{!isXsScreen && <Rating value={data[select.key]} precision={0.5} readOnly />}</div> : data[select.key]}</TableCell>
                                    ))}
                                    {props.setPermission && (
                                        <TableCell align='center' sx={{ padding: 0 }}>
                                            <Button onClick={() => navigate('/store/roles/permissions', { state: { role: data } })} variant='contained'>set permission</Button>
                                        </TableCell>
                                    )}
                                    {!isXsScreen && <TableCell align="right" className={tableCss.tableData}>
                                        <div className={css.tableBtnContainer}>
                                            {props.editPermission && (
                                                <IconButton
                                                    onClick={() => props.handleStoreEdit(data._id, data)}
                                                    sx={{
                                                        color: '#005A5F'
                                                    }}
                                                >
                                                    <Edit />
                                                </IconButton>
                                            )}
                                            {props.deletePermission && (
                                                <IconButton
                                                    sx={{
                                                        color: '#005A5F'
                                                    }}
                                                    onClick={() => {
                                                        props.handleDeleteModalOpen();
                                                        props.setDeleteStore(data._id)
                                                    }}
                                                >
                                                    <Delete />
                                                </IconButton>
                                            )}
                                        </div>
                                    </TableCell>}

                                </TableRow>

                                {isXsScreen && (
                                    <TableCell colSpan={4} className={tableCss.tableData} size="small" sx={{ backgroundColor: '#eeeeee', padding: 1 }}>
                                        <Box sx={{display:'flex',justifyContent:'center'}}>
                                
                                            <Box marginTop={1} key={index}>
                                                <Rating precision={0.5} readOnly/>
                                            </Box>
                                             
                                            <Box>
                                                <div className={css.tableBtnContainer}>
                                                    {props.editPermission && (
                                                        <IconButton
                                                            onClick={() => props.handleStoreEdit(data._id, data)}
                                                            sx={{
                                                                color: '#005A5F'
                                                            }}
                                                        >
                                                            <Edit />
                                                        </IconButton>
                                                    )}
                                                    {props.deletePermission && (
                                                        <IconButton
                                                            sx={{
                                                                color: '#005A5F'
                                                            }}
                                                            onClick={() => {
                                                                props.handleDeleteModalOpen();
                                                                props.setDeleteStore(data._id)
                                                            }}
                                                        >
                                                            <Delete />
                                                        </IconButton>
                                                    )}
                                                </div>
                                            </Box>
                                        </Box>
                                        {/* <div className={css.tableBtnContainer}>

                                            {props.editPermission && (
                                                <IconButton
                                                    onClick={() => props.handleStoreEdit(data._id, data)}
                                                    sx={{
                                                        color: '#005A5F'
                                                    }}
                                                >
                                                    <Edit />
                                                </IconButton>
                                            )}
                                            {props.deletePermission && (
                                                <IconButton
                                                    sx={{
                                                        color: '#005A5F'
                                                    }}
                                                    onClick={() => {
                                                        props.handleDeleteModalOpen();
                                                        props.setDeleteStore(data._id)
                                                    }}
                                                >
                                                    <Delete />
                                                </IconButton>
                                            )}
                                        </div> */}
                                    </TableCell>
                                )}
                            </React.Fragment>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            {!props.tableData.length && <h2 style={{ textAlign: 'center' }}>{props.noDataTitle}</h2>}
        </>
    )
}

export default TableData;