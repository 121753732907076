import React, { useContext, useEffect, useState } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import Login from "../pages/Login";
import SignUp from "../pages/SignUp";
import ForgotPassword from "../pages/ForgotPassword";
import NavMenu from "../layout/NavMenu";
import Test from "../components/ui/Test";
import Print from "../pages/Print";
import PrintConfig from "../pages/PrintConfig";
import Orders from "../pages/Orders";
import PastOrders from "../pages/PastOrders";
import Job from "../pages/Job";
import Stores from "../pages/Stores";
import Employee from "../pages/Employee";
import UserBar from "../layout/UserBar";
import StoreOrders from "../pages/StoreOders";
import Stationery from "../pages/Stationery";
import Product from "../pages/Product";
import Payment from "../pages/Payment";
import SelectStore from "../pages/SelectStore";
import StoreRole from "../pages/StoreRole";
import Permissions from "../pages/Permissions";
import EmployeeLogin from "../pages/EmployeeLogin";
import PrivateRoute from "./PrivateRouter";
import PublicRoute from "./PublicRouter";
import Report from "../pages/Reports";
import EmployeePermission from "../context/employeePermission";
import UserStationery from "../pages/UserStationery";
import UserProduct from "../pages/UserProduct";
import OrderSuccess from "../pages/OrderSuccess";
import Profile from "../pages/Profile";
import { onMessage } from "firebase/messaging";
import { getTokenGen, messaging } from "../config/firebaseSetup";
import { toast } from "react-toastify";
import OrderNotification from "../components/OrderNotification";
import { put } from "../api/services/apiMethods";
import urls from "../api/constants";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
} from "@mui/material";
import PrintContext from "../pages/PrintContex";

// styles
import modalCss from "../styles/pages/Orders.module.css";
import PrivacyPolicy from "../pages/PrivacyPolicy";
import TermsAndCondition from "../pages/TermsAndCondition";

const AppRouter = () => {
  const { setEmployeePermission, employeePermission } =
    useContext(EmployeePermission);
  const [isTokenFound, setIsTokenFound] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [order, setOrder] = useState(null);

  const [files, setFiles] = useState([]);

  getTokenGen(setIsTokenFound);
  useEffect(() => {
    if (isTokenFound) {
      window.localStorage.setItem("firebaseToken", isTokenFound);
    }
  }, [isTokenFound]);

  useEffect(() => {
    if (!employeePermission) {
      const permissions = window.localStorage.getItem("permission");
      if (permissions) {
        setEmployeePermission(JSON.parse(permissions));
      }
    }
    onMessage(messaging, (payload) => {
      console.log("Push message ============ ", payload);
      toast(
        <OrderNotification
          setModalOpen={setModalOpen}
          setOrder={setOrder}
          order={payload}
        />,
        { autoClose: false, closeOnClick: false }
      );
    });
  }, []);

  const getItemsInText = (item, field) => {
    if (field === "color") {
      return item.color === "bw" ? "black/white" : "color";
    }
    if (field === "paperSize") {
      return item.paperSize === "a4" ? "A4" : "A5";
    }
    // if(field === 'paperQuality') {
    //   return item.paperQuality === '100gsm' ? '100gsm' : '80gsm';
    // }
    if (field === "binding") {
      return item.binding === "Spiral" ? "Spiral" : "Staples";
    }
  };
  const handleRejectOrder = async () => {
    try {
      const { status } = await put(`${urls.orders.update}${order._id}`, {
        status: "rejected",
      });
      if (status > 199 && status < 299) {
        setModalOpen(false);
        toast.error("Order rejected");
      }
    } catch (error) {}
  };
  const handleAcceptOrder = async () => {
    try {
      const { status } = await put(`${urls.orders.update}${order._id}`, {
        status: "accepted",
      });
      if (status > 199 && status < 299) {
        setModalOpen(false);
        toast.success("Order accepted");
      }
    } catch (error) {}
  };
  return (
    <div>
      <PrintContext.Provider value={{ files, setFiles }}>
        <Routes>
          <Route path="/store" element={<NavMenu />}>
            <Route
              path="/store/test"
              element={
                <PrivateRoute>
                  <Test />
                </PrivateRoute>
              }
            />
            <Route
              path="/store/job"
              element={
                <PrivateRoute>
                  <Job />
                </PrivateRoute>
              }
            />
            <Route
              path="/store/stores"
              element={
                <PrivateRoute>
                  <Stores />
                </PrivateRoute>
              }
            />
            <Route
              path="/store/employee"
              element={
                <PrivateRoute>
                  <Employee />
                </PrivateRoute>
              }
            />
            <Route
              path="/store/orders"
              element={
                <PrivateRoute>
                  <StoreOrders />
                </PrivateRoute>
              }
            />
            {/* <Route
              path="/store/stationery"
              element={
                <PrivateRoute>
                  <Stationery />
                </PrivateRoute>
              }
            /> */}
            <Route
              path="/store/roles"
              element={
                <PrivateRoute>
                  <StoreRole />
                </PrivateRoute>
              }
            />
            <Route
              path="/store/roles/permissions"
              element={
                <PrivateRoute>
                  <Permissions />
                </PrivateRoute>
              }
            />
            {/* <Route
              path="/store/stationery/product"
              element={
                <PrivateRoute>
                  <Product />
                </PrivateRoute>
              }
            /> */}
            <Route
              path="/store/reports"
              element={
                <PrivateRoute>
                  <Report />
                </PrivateRoute>
              }
            />
          </Route>
          <Route path="/" element={<Navigate to={"/login"} />} />
          <Route
            path="/employee-login"
            element={
              <PublicRoute>
                <EmployeeLogin />
              </PublicRoute>
            }
          />
          <Route
            path="/login"
            element={
              <PublicRoute>
                <Login />
              </PublicRoute>
            }
          />
          <Route
            path="/signup"
            element={
              <PublicRoute>
                <SignUp />
              </PublicRoute>
            }
          />
          <Route
            path="/privacy-policy"
            element={
              <PublicRoute>
                <PrivacyPolicy />
              </PublicRoute>
            }
          />
          <Route
            path="/terms-condition"
            element={
              <PublicRoute>
                <TermsAndCondition />
              </PublicRoute>
            }
          />
          <Route
            path="/forgot-password"
            element={
              <PublicRoute>
                <ForgotPassword />
              </PublicRoute>
            }
          />
          <Route path="/user" element={<UserBar />}>
            <Route
              path="/user/print"
              element={
                <PrivateRoute>
                  <Print />
                </PrivateRoute>
              }
            />
            <Route
              path="/user/print-config"
              element={
                <PrivateRoute>
                  <PrintConfig />
                </PrivateRoute>
              }
            />
            <Route
              path="/user/orders"
              element={
                <PrivateRoute>
                  <Orders />
                </PrivateRoute>
              }
            />
            <Route
              path="/user/past-orders"
              element={
                <PrivateRoute>
                  <PastOrders />
                </PrivateRoute>
              }
            />
            <Route
              path="/user/payment"
              element={
                <PrivateRoute>
                  <Payment />
                </PrivateRoute>
              }
            />
            <Route
              path="/user/select-store"
              element={
                <PrivateRoute>
                  <SelectStore />
                </PrivateRoute>
              }
            />
            {/* <Route
              path="/user/stationery"
              element={
                <PrivateRoute>
                  <UserStationery />
                </PrivateRoute>
              }
            /> */}
            <Route
              path="/user/stationery/product"
              element={
                <PrivateRoute>
                  <UserProduct />
                </PrivateRoute>
              }
            />
            <Route
              path="/user/order-successful"
              element={
                <PrivateRoute>
                  <OrderSuccess />
                </PrivateRoute>
              }
            />
            <Route
              path="/user/profile"
              element={
                <PrivateRoute>
                  <Profile />
                </PrivateRoute>
              }
            />
          </Route>
        </Routes>
      </PrintContext.Provider>
      <Dialog
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        scroll="paper"
      >
        <DialogTitle>Order details</DialogTitle>
        <DialogContent dividers={true}>
          {order && (
            <div className={modalCss.modalContainer}>
              {order.items.map((item, index) => (
                <Paper
                  key={index}
                  elevation={2}
                  className={modalCss.fileConfigContainer_paper}
                >
                  <div className={modalCss.fileConfigContainer}>
                    <div>File {index + 1}</div>
                    <div>
                      Xerox color:{" "}
                      {item.colors.color === "color"
                        ? "Color"
                        : item.colors.color === "colpar"
                        ? "Multi-Color"
                        : "Black&White"}
                    </div>
                    <div>
                      Pages to Print : {item.pageRange[0]} to{" "}
                      {item.pageRange[1]}
                    </div>
                    {item.colors.color === "colpar" ? (
                      <div>
                        <div>
                          Total Black Pages :{" "}
                          {item.totalPages - item.colorPar.pageNumbers.length}{" "}
                        </div>
                        <div>
                          Total Color Pages : {item.colorPar.pageNumbers.length}{" "}
                        </div>
                        <div>
                          Color Page Nos. to print :{" "}
                          {item.colorPar.pageNumbers.join(",")}{" "}
                        </div>
                      </div>
                    ) : null}
                    <div>Instructions : {item.instructions || "NA"}</div>
                    <div>Paper size: {getItemsInText(item, "paperSize")}</div>
                    <div>Binding: {getItemsInText(item, "binding")}</div>
                    <div>Copies: {item.copies}</div>
                    <div>Cost: {item.cost}</div>
                  </div>
                </Paper>
              ))}
              <Paper elevation={2} className={modalCss.totalCostPaper}>
                <div>Total Cost:{order.totalCost}</div>
                <div>Status:{order.status}</div>
              </Paper>
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button color="error" onClick={handleRejectOrder}>
            Reject
          </Button>
          <Button color="success" onClick={handleAcceptOrder}>
            Accept
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AppRouter;
