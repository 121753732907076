import { Button,} from "@mui/material";
import React from "react";
import { get } from "../api/services/apiMethods";
import urls from "../api/constants";

// styles


const OrderNotification = (props) => {
    const getOrderDetails = async () => {
        console.log(props.order)
        try {
            const orderId = props.order.notification.body;
            const {data,status} = await get(`${urls.orders.get}${orderId}`);
            props.setOrder(data.result);
            props.setModalOpen(true);
            props.closeToast();
        } catch (error) {
            console.log(error)
        }
    }
    return(
        <div>
            <div>
                Received new order 
            </div>
            <div>
                <Button onClick={getOrderDetails}>view order</Button>
            </div>
        </div>
    )
}
export default OrderNotification;   