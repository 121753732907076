import React from "react";
import { Navigate } from "react-router-dom";
import { toast } from "react-toastify";

const PublicRoute = ({children}) => {
    const isAuth = window.localStorage.getItem('access_token');

    const navUser = () => {
        return <Navigate to='/user/print' />
    }
    return isAuth ? navUser() : children;
}
export default PublicRoute