import React, { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { styled, useTheme } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Avatar from "@mui/material/Avatar";
import Logo from "./image/logo.jpg";

import {
  Assessment,
  Engineering,
  Inventory2,
  LibraryBooks,
  Logout,
  Store,
  SwitchAccount,
} from "@mui/icons-material";
import { Link, Outlet, useNavigate } from "react-router-dom";

import { objHas } from "../utils/utils";

import css from "../styles/NavMenu.module.css";

import { Box, CssBaseline } from "@mui/material";
import EmployeePermission from "../context/employeePermission";
import { toast } from "react-toastify";

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const NavMenu = (props) => {
  const theme = useTheme();
  const [storageData, setStorageData] = useState({});
  const [open, setOpen] = useState(true);
  const [menuOpen, setMenuOpen] = useState([]);
  const [settings, setSettings] = useState(null);

  const navigate = useNavigate();
  const { employeePermission } = useContext(EmployeePermission);
  console.log(employeePermission, "employee permission==========");
  useEffect(() => {
    setSettings({ ...settings, logo: { link: Logo } });
  }, []);
  useEffect(() => {
    function setUserRole() {
      const storageData = localStorage.getItem("role");

      if (storageData) {
        setStorageData(JSON.parse(storageData));
      }
    }
    setUserRole();
    window.addEventListener("storage", setUserRole);

    return () => {
      window.removeEventListener("storage", setUserRole);
    };
  }, []);
  const handleSubClick = (index) => {
    setOpen(true);
    if (menuOpen.includes(index)) {
      setMenuOpen(menuOpen.filter((item) => item !== index));
    } else {
      setMenuOpen([...menuOpen, index]);
    }
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setMenuOpen([]);
    setOpen(false);
  };

  const handleLogout = async () => {
    window.localStorage.removeItem("access_token");
    window.localStorage.removeItem("refresh_token");
    window.localStorage.removeItem("permission");
    navigate("/employee-login");
    toast.success("Your logged out");
  };
  return (
    <Box className={css.navMenuContainer}>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar sx={{ background: "#ffffff", color: "forestgreen" }}>
          <div className={css.toolBarContainer}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{
                ...(open && { visibility: "hidden" }),
              }}
            >
              <MenuIcon />
              {/* <Avatar alt="Remy Sharp" src="/static/images/avatar/2.jpg" /> */}
            </IconButton>

            <div className={css.barHeader}>
              <Typography variant="h6" noWrap component="div">
                DITTOX
              </Typography>
            </div>
            <div>
              <IconButton onClick={handleLogout} color="inherit">
                <Logout />
              </IconButton>
            </div>
          </div>
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <DrawerHeader style={{ minHeight: "100px", gap: "45px" }}>
          {settings && (
            <img
              src={Logo}
              alt="logo"
              style={{ width: "40%", objectFit: "contain" }}
            />
          )}
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          {/* Stores */}
          <ListItem
            disablePadding
            sx={{
              backgroundColor:
                window.location.pathname === "/store/stores"
                  ? (theme) => theme.palette.grey[300]
                  : "transparent",
              display:
                employeePermission &&
                employeePermission["Stores"] &&
                employeePermission["Stores"].indexOf("view") !== -1
                  ? "block"
                  : "none",
            }}
          >
            <Link
              to={"/store/stores"}
              style={{ color: "inherit", textDecoration: "none" }}
            >
              <ListItemButton
                onClick={handleDrawerOpen}
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                  mr: !open && 3,
                }}
              >
                <ListItemIcon
                  onClick={handleDrawerOpen}
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <Store />
                </ListItemIcon>
                <ListItemText
                  primary={"Store"}
                  sx={{ opacity: open ? 1 : 0 }}
                />
              </ListItemButton>
            </Link>
          </ListItem>
          {/* Employees */}
          <ListItem
            disablePadding
            onClick={handleDrawerOpen}
            sx={{
              backgroundColor:
                window.location.pathname === "/store/employee"
                  ? (theme) => theme.palette.grey[300]
                  : "transparent",
              display:
                employeePermission &&
                employeePermission["Employees"] &&
                employeePermission["Employees"].indexOf("view") !== -1
                  ? "block"
                  : "none",
            }}
          >
            <Link
              to={"/store/employee"}
              style={{ color: "inherit", textDecoration: "none" }}
            >
              <ListItemButton
                onClick={handleDrawerOpen}
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                  mr: !open && 3,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <SwitchAccount />
                </ListItemIcon>
                <ListItemText
                  primary={"Employee"}
                  sx={{ opacity: open ? 1 : 0 }}
                />
              </ListItemButton>
            </Link>
          </ListItem>

          {/* Orders */}
          <ListItem
            disablePadding
            sx={{
              backgroundColor:
                window.location.pathname === "/store/orders"
                  ? (theme) => theme.palette.grey[300]
                  : "transparent",
              display:
                employeePermission &&
                employeePermission["Orders"] &&
                employeePermission["Orders"].indexOf("view") !== -1
                  ? "block"
                  : "none",
            }}
          >
            <Link
              to={"/store/orders"}
              style={{ color: "inherit", textDecoration: "none" }}
            >
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                  mr: !open && 3,
                }}
              >
                <ListItemIcon
                  onClick={handleDrawerOpen}
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <Inventory2 />
                </ListItemIcon>
                <ListItemText
                  primary={"Orders"}
                  sx={{ opacity: open ? 1 : 0 }}
                />
              </ListItemButton>
            </Link>
          </ListItem>

          {/* Roles and Permission */}
          <ListItem
            disablePadding
            sx={{
              backgroundColor:
                window.location.pathname === "/store/roles" ||
                window.location.pathname === "/store/roles/permissions"
                  ? (theme) => theme.palette.grey[300]
                  : "transparent",
              display:
                employeePermission &&
                employeePermission["Roles and Permission"] &&
                employeePermission["Roles and Permission"].indexOf("view") !==
                  -1
                  ? "block"
                  : "none",
            }}
          >
            <Link
              to={"/store/roles"}
              style={{ color: "inherit", textDecoration: "none" }}
            >
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                  mr: !open && 3,
                }}
              >
                <ListItemIcon
                  onClick={handleDrawerOpen}
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <Engineering />
                </ListItemIcon>
                <ListItemText
                  primary={"Roles and Permission"}
                  sx={{ opacity: open ? 1 : 0 }}
                />
              </ListItemButton>
            </Link>
          </ListItem>
          {/* Reports */}
          <ListItem
            disablePadding
            sx={{
              backgroundColor:
                window.location.pathname === "/store/reports"
                  ? (theme) => theme.palette.grey[300]
                  : "transparent",
              display:
                employeePermission &&
                employeePermission["Reports"] &&
                employeePermission["Reports"].indexOf("view") !== -1
                  ? "block"
                  : "none",
            }}
          >
            <Link
              to={"/store/reports"}
              style={{ color: "inherit", textDecoration: "none" }}
            >
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                  mr: !open && 3,
                }}
              >
                <ListItemIcon
                  onClick={handleDrawerOpen}
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <Assessment />
                </ListItemIcon>
                <ListItemText
                  primary={"Reports"}
                  sx={{ opacity: open ? 1 : 0 }}
                />
              </ListItemButton>
            </Link>
          </ListItem>
        </List>
        <Divider />
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1 }}>
        <DrawerHeader />
        <div className={css.outletContainer}>
          <Outlet />
        </div>
      </Box>
    </Box>
  );
};
export default NavMenu;
