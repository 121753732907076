const objPropertyCheck = (obj, keys) => {
  return keys.every((key) => obj[key]);
};
const objHas = (obj, path, defaultVal) => {
  let cur = obj;
  let keys = path.split(".");
  for (let i = 0; i < keys.length; i++) {
    if (
      cur === null ||
      typeof cur !== "object" ||
      !cur.hasOwnProperty(keys[i])
    ) {
      return defaultVal;
    }
    cur = cur[keys[i]];
  }
  return cur;
};
const subMenuPermissionChecker = (functionalities, role) => {
  if (!Array.isArray(functionalities)) return false;
  let giveAccess = false;
  for (const func of functionalities) {
    if (role[func] && role[func]["accessible"]) {
      giveAccess = true;
      break;
    }
  }
  return giveAccess;
};
const storeUnitConverter = (bytes) => {
  const toKb = bytes / 1024;
  if (toKb > 1024) {
    const toMb = toKb / 1024;
    return `${toMb.toFixed(2)} mb`;
  }
  return `${toKb.toFixed(2)} kb`;
};

const isValidEmail = (email) => {
  const validRegex =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  return email.match(validRegex);
};
const getFileType = (fileName) => {
  let fileType = "";
  for (let i = fileName.length - 1; i >= 0; i--) {
    if (fileName[i] !== ".") {
      fileType = fileName[i] + fileType;
    } else {
      return fileType;
    }
  }
};
function deg2rad(deg) {
  return deg * (Math.PI / 180);
}
function getDistanceFromLatLonInKm(lat1, lon1, lat2, lon2) {
  var R = 6371; // Radius of the earth in km
  var dLat = deg2rad(lat2 - lat1); // deg2rad below
  var dLon = deg2rad(lon2 - lon1);
  var a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) *
      Math.cos(deg2rad(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  var d = R * c; // Distance in km
  return d;
}

function formatDistance(distanceInMeters) {
  if (distanceInMeters > 1000) {
    return (distanceInMeters / 1000).toFixed(2) + " km";
  } else {
    return distanceInMeters + " meters";
  }
}

export {
  formatDistance,
  objPropertyCheck,
  subMenuPermissionChecker,
  objHas,
  storeUnitConverter,
  isValidEmail,
  getFileType,
  getDistanceFromLatLonInKm,
};
