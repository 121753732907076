import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import React, { Fragment, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Slider from "react-slick";

// styles
import css from "../styles/pages/SelectStore.module.css";
import { get, post, put } from "../api/services/apiMethods";
import urls from "../api/constants";
import SelectStoreCard from "../components/SelectStoreCard";
import {
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  ListItem,
  MenuItem,
  Paper,
  Select,
  Box,
  Grid,
  Stack,
  Typography,
  styled,
  AppBar,
} from "@mui/material";
import { toast } from "react-toastify";

// constants
import { API_PREFIX } from "../api/client";
import {
  GoogleMap,
  useLoadScript,
  Marker,
  InfoWindow,
  useGoogleMap,
} from "@react-google-maps/api";
import FeedBackDialog from "../components/FeedBackDialog";
import { Grade } from "@mui/icons-material";
import { useRef } from "react";
import { calculateSlidersData } from "../utils/carousalData";
import useWindowDimensions from "../hooks/useWindowDimensions";
import { LoadingButton } from "@mui/lab";

const AppSlider = styled(Slider)`
  width: 100%;
  .slick-track {
    display: flex;
    //flex-shrink: 1;
  }
  .slick-slide {
    display: flex;
    justify-content: center;
    //margin-bottom: 1;
    outline: none;
  }
  .slick-list {
    overflow: hidden;
  }
`;

const SelectStore = () => {
  const divRef = useRef(null);
  const [stores, setStores] = useState([]);
  const [selectedStore, setSelectStore] = useState("");
  const [dialogOpen, setDialogOpen] = useState(false);
  const [storeDetails, setStoreDetails] = useState(null);
  const storeCardsContainerRef = useRef(null);
  const [costDetails, setCostDetails] = useState([]);
  const [selectedCostDetails, setSelectedCostDetails] = useState(null);
  const [totalOrderCost, setTotalOrderCost] = useState(null);
  const [localX, setLocalX] = useState(0);
  const [localY, setLocalY] = useState(0);
  const [feedBackDialog, setFeedBackDialog] = useState(false);
  const [selectFeedBackStore, setSelectFeedBackStore] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();
  let { width } = useWindowDimensions();
  const [map, setMap] = React.useState(null);
  const [gettingOrderDetails, setGettingOrderDetails] = useState(false);

  const { isLoaded } = useLoadScript({
    id: "google-maps",
    googleMapsApiKey: "AIzaSyAgmbfa6fzY_WpJ2cnlRyQLwmAjuXRc7JM",
  });

  useEffect(() => {
    const init = async () => {
      try {
        console.log("kkkk");
        const { data: storeList, status } = await get(`${urls.store.list}`);
        if (status > 199 && status < 299) {
          setStores(storeList.result.data);
          console.log("storeeeee", storeList.result.data);
        }
      } catch (error) {
        console.log(error);
      }
    };
    init();
  }, []);

  useEffect(() => {
    if (window.navigator.geolocation) {
      window.navigator.geolocation.getCurrentPosition(
        (geo) => {
          setLocalX(geo.coords.latitude);
          setLocalY(geo.coords.longitude);
        },
        () => {
          toast.warning("Please allow location access for store location");
        }
      );
    }
  }, [window.location.pathname]);

  const handleDialogClose = () => setDialogOpen(false);
  const handleDialogOpen = () => setDialogOpen(true);

  const handleFeedBackDialogClose = () => {
    setSelectFeedBackStore(null);
    setFeedBackDialog(false);
  };
  const handleFeedBackDialogOpen = () => {
    setFeedBackDialog(true);
  };
  const handleSelectFeedBackStore = (store) => {
    handleFeedBackDialogOpen();
    setSelectFeedBackStore(store);
  };
  const handleSelected = (id) => {
    const element = document.getElementById(id);
    if (element) {
      // 👇 Will scroll smoothly to the top of the next section
      element.scrollIntoView({ behavior: "smooth" });
    }
    if (selectedStore) {
      if (selectedStore !== id) {
        setSelectStore(id);

        const store = stores.find((store) => store._id === id);
        if (store) {
          setStoreDetails(store);
        }
      } else {
        return setSelectStore("");
      }
    } else {
      setSelectStore(id);
      const store = stores.find((store) => store._id === id);
      if (store) {
        setStoreDetails(store);
      }
    }
    // const scrollAmount = 500;
    // window.scrollBy(0, scrollAmount);

    // if (storeCardsContainerRef.current) {
    //   storeCardsContainerRef.current.scrollIntoView({
    //     behavior: "smooth",
    //     block: "center",
    //     inline: "nearest",
    //   });
    // }
  };

  const handleSelectedOne = (id) => {
    if (selectedStore) {
      if (selectedStore !== id) {
        setSelectStore(id);

        const store = stores.find((store) => store._id === id);
        if (store) {
          setStoreDetails(store);
        }
      } else {
        return setSelectStore("");
      }
    } else {
      setSelectStore(id);
      const store = stores.find((store) => store._id === id);
      if (store) {
        setStoreDetails(store);
      }
    }
    // const scrollAmount = 500;
    // window.scrollBy(0, scrollAmount);
    const element = document.getElementById(selectedStore);
    if (element) {
      // 👇 Will scroll smoothly to the top of the next section
      element.scrollIntoView({ behavior: "smooth" });
    }

    if (divRef.current) {
      divRef.current.scrollIntoView({ behavior: "smooth" });
    }

    // if (storeCardsContainerRef.current) {
    //   storeCardsContainerRef.current.scrollIntoView({
    //     behavior: "smooth",
    //     block: "center",
    //     inline: "nearest",
    //   });
    // }
  };

  const handlePayment = async () => {
    try {
      if (!selectedStore) return toast.error("Please select a store");
      const data = {
        items: [...location.state.items],
        storeId: selectedStore,
      };
      setGettingOrderDetails(true);
      const { data: order, status } = await post(`${urls.orders.create}`, data);
      setGettingOrderDetails(false);

      console.log(order, "order=======");
      if (status > 199 && status < 299) {
        const cost = order.result.totalCost.toString() + "00";
        const data = {
          amount: Number(cost),
          orderId: order.result._id,
          receipt: "cnlna",
        };
        const { data: payOrder, status } = await post(
          `${urls.payment.order}`,
          data
        );
        if (status > 199 && status < 299) {
          var options = {
            key: "rzp_live_p6Q0b5huVYWl7D", // Enter the Key ID generated from the Dashboard
            amount: payOrder.result.amount, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
            currency: "INR",
            name: "I2N Technologies", //your business name
            description: "Test Transaction",
            order_id: payOrder.result.paymentId, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
            callback_url: `${API_PREFIX}/payment/confirm`,
            // prefill: {
            //   name: "Gaurav Kumar", //your customer's name
            //   email: "gaurav.kumar@example.com",
            //   contact: "9000090000",
            // },
            notes: {
              // address: "Razorpay Corporate Office",
            },
            theme: {
              color: "#3399cc",
            },
            handler: async function (response) {
              const { data: orderPayed, status } = await post(
                `${urls.orders.payment}`,
                response
              );
              if (status > 199 && status < 299) {
                if (orderPayed && orderPayed.signatureIsValid) {
                  navigate("/user/order-successful");
                } else {
                  toast.error("Something went wrong, Try again later");
                }
              }
            },
            modal: {
              ondismiss: async function () {
                await put(`${urls.orders.update}${order.result._id}`, {
                  status: "failed",
                });
              },
            },
          };
          const rzp1 = new window.Razorpay(options);
          rzp1.open();
          rzp1.on("payment.failed", function (response) {
            console.log("Payment", response);
          });
        }
      }
    } catch (error) {
      console.log(error);
    }
    setGettingOrderDetails(false);
  };
  const handleOrderOnly = async () => {
    try {
      if (!selectedStore) return toast.error("Please select a store");
      const data = {
        items: [...location.state.items],
        storeId: selectedStore,
        orderLater: true,
      };
      const { data: order, status } = await post(`${urls.orders.create}`, data);
      if (status > 199 && status < 299) {
        navigate("/user/order-successful", { state: "order-Later" });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleViewOrder = () => {
    if (!selectedStore) return toast.error("Please select a store");
    if (storeDetails) {
      const items = [...location.state.items];
      console.log(items, "items");
      const costDetails = [];
      let totalOrderCost = 0;
      items.forEach((item) => {
        let cost = {};
        cost.id = items.indexOf(item) + 1;
        cost.item = { ...item };
        cost.details = item.instructions;
        cost.pageRange = item.pageRange;
        let totalCost = 0;

        // calculate cost for one side;
        let sideCost = 0;
        if (item.sides == "one") {
          cost.side = "one";
          sideCost = Number(storeDetails.meta.costOneSide);
        } else {
          cost.side = "both";
          sideCost = Number(storeDetails.meta.costTwoSide);
        }

        // calculate cost for color/bw/colorPar
        let totalBlackPages = 0;
        let totalColorPages = 0;
        let blackCost = 0;
        let colorCost = 0;

        if (item.colors.color === "bw") {
          cost.color = "Black&White";
          totalBlackPages = item.totalPages;
          blackCost = Number(storeDetails.meta.costBlack);
        } else if (item.colors.color === "multi") {
          totalColorPages = item.totalPages;
          cost.color = "Color";
          colorCost = Number(storeDetails.meta.costColor);
        } else {
          cost.color = "Multi-Color";
          totalColorPages = item.colorPar.pageNumbers.length;
          totalBlackPages = item.totalPages - totalColorPages;
          blackCost = Number(storeDetails.meta.costBlack);
          colorCost = Number(storeDetails.meta.costColor);
        }

        // calculate paper size cost
        let paperSizeCost = 0;
        if (item.paperSize == "a4") {
          cost.size = "A4";
          paperSizeCost = parseInt(storeDetails.meta["sizeA4"]);
        } else if (item.paperSize == "a5") {
          cost.size = "A5";
          paperSizeCost = parseInt(storeDetails.meta["sizeA5"]);
        } else if (item.paperSize == "legal") {
          cost.size = "Legal";
          paperSizeCost = parseInt(storeDetails.meta["legal"]);
        } else if (item.paperSize == "letter") {
          cost.size = "Letter";
          paperSizeCost = parseInt(storeDetails.meta["letter"]);
        } else if (item.paperSize == "b5") {
          cost.size = "B5";
          paperSizeCost = parseInt(storeDetails.meta["sizeB5"]);
        } else if (item.paperSize == "a6") {
          cost.size = "A6";
          paperSizeCost = parseInt(storeDetails.meta["sizeA6"]);
        } else if (item.paperSize == "postCard") {
          cost.size = "Postcard";
          paperSizeCost = parseInt(storeDetails.meta["postCard"]);
        } else if (item.paperSize == "5*7") {
          cost.size = "5*7";
          paperSizeCost = parseInt(storeDetails.meta["size5X7"]);
        } else if (item.paperSize == "4*6") {
          cost.size = "4*6";
          paperSizeCost = parseInt(storeDetails.meta["size4X6"]);
        } else if (item.paperSize == "3.5*5") {
          cost.size = "3.5*5";
          paperSizeCost = parseInt(storeDetails.meta["size35X5"]);
        }

        let bondPrice =
          item.bondPage.selected == "yes"
            ? parseInt(storeDetails.meta["bondPage"])
            : 0;

        // calculate binding cost
        let bindingCost = 0;
        if (item.binding === "Staples") {
          cost.binding = "Staples Binding";
          bindingCost = Number(storeDetails.meta.staplesBinding);
        } else if (item.binding === "Spiral") {
          cost.binding = "Spiral Binding";
          bindingCost = Number(storeDetails.meta.spiralBinding);
        } else if (item.binding === "StickFile") {
          cost.binding = "Stick File";
          bindingCost = Number(storeDetails.meta.stickFile);
        } else {
          cost.binding = "No Binding";
        }

        let totalColorPagesCost = totalColorPages * colorCost;
        let totalBlackPagesCost = totalBlackPages * blackCost;
        console.log(totalBlackPagesCost, totalColorPagesCost, "costs");
        totalCost =
          (totalColorPagesCost +
            totalBlackPagesCost +
            paperSizeCost +
            sideCost +
            bondPrice) *
            item.copies +
          bindingCost;
        cost.copies = item.copies;
        cost.totalCost = totalCost;
        costDetails.push(cost);
        totalOrderCost = totalOrderCost + cost.totalCost;
      });
      setCostDetails([...costDetails]);
      console.log(costDetails, "cdss");
      setSelectedCostDetails(costDetails[0]);
      setTotalOrderCost(totalOrderCost);
      console.log("setTotalOrderCost", totalOrderCost);

      setDialogOpen(true);
    }
  };

  const handleOptionChange = (event) => {
    const { value } = event.target;
    setSelectedCostDetails(costDetails.filter((d) => d.id == value)[0]);
  };

  const onLoad = React.useCallback(
    function callback(map) {
      if (selectedStore) {
        // Calculate the zoom level to fit the selected store
        const bounds = new window.google.maps.LatLngBounds();
        let store = stores.filter((s) => s._id == selectedStore)[0];
        bounds.extend({
          lat: Number(store.location.x),
          lng: Number(store.location.y),
        });
        map.fitBounds(bounds);
      } else {
        // No store selected, set a default zoom level for all markers
        const bounds = new window.google.maps.LatLngBounds();
        stores.forEach(({ location }) =>
          bounds.extend({ lat: Number(location.x), lng: Number(location.y) })
        );
        map.fitBounds(bounds);
      }

      setMap(map);
    },
    [selectedStore]
  );

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  useEffect(() => {
    if (map) {
      onLoad(map);
    }
  }, [selectedStore, GoogleMap]);

  return (
    <>
      <AppBar />
      <Box className={css.box1}>
        <Grid container spacing={1}>
          <Grid item xs={12} md={4} lg={3}>
            <Typography
              className={css.header}
              style={{ fontWeight: "bold", fontSize: "25px" }}
            >
              Select Store
            </Typography>

            <Box className={css.storeContainer}>
              {stores.map((store) => (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  component={"div"}
                  id={store._id}
                  key={store._id}
                >
                  <SelectStoreCard
                    handleSelectFeedBackStore={handleSelectFeedBackStore}
                    userX={localX}
                    userY={localY}
                    handleSelected={handleSelected}
                    selected={selectedStore}
                    key={store._id}
                    store={store}
                    items={location.state.items}
                    totalOrderCost={totalOrderCost}
                    totalPages={location.state.totalPages}
                  />
                  {selectedStore === store._id && (
                    <Stack
                      spacing={1}
                      direction="row"
                      sx={{
                        // backgroundColor: "#77767a",
                        borderRadius: 2,
                        padding: "15px 0px ",
                        textAlign: "center",
                        alignItems: "center",
                        marginLeft: "20px",
                        maxWidth: "350px",
                      }}
                      className={css.btnContainer}
                    >
                      <Button
                        size="small"
                        onClick={() => navigate("/user/print")}
                        variant="contained"
                        color="error"
                      >
                        Cancel
                      </Button>
                      <Button
                        size="small"
                        onClick={handleViewOrder}
                        variant="contained"
                        color="success"
                      >
                        Submit
                      </Button>
                    </Stack>
                  )}
                </Box>
              ))}
            </Box>
          </Grid>
          <Grid item xs={12} md={8} lg={9}>
            <Box>
              {isLoaded && stores.length ? (
                <GoogleMap
                  onLoad={onLoad}
                  onUnmount={onUnmount}
                  mapContainerStyle={{
                    height: "90vh",
                    width: "100vw",
                    overflowY: "hidden",
                  }}
                  center={{
                    lat: selectedStore
                      ? stores.find((store) => store._id === selectedStore)[0]
                          ?.location.x
                      : stores[0].location.x,
                    lng: selectedStore
                      ? stores.find((store) => store._id === selectedStore)[0]
                          ?.location.y
                      : stores[0].location.y,
                  }}
                  zoom={5}
                >
                  {selectedStore
                    ? stores
                        .filter((s) => s._id == selectedStore)
                        .map((store) => {
                          return (
                            <Marker
                              key={store._id}
                              position={{
                                lat: store.location.x,
                                lng: store.location.y,
                              }}
                            />
                          );
                        })
                    : stores.map((store) => (
                        <Marker
                          key={store._id}
                          position={{
                            lat: store.location.x,
                            lng: store.location.y,
                          }}
                        />
                      ))}
                </GoogleMap>
              ) : null}
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box
        sx={{
          backgroundColor: "white",
          position: "relative",
          height: "calc(100vh - 80px)",
        }}
        className={css.box2}
      >
        {isLoaded && stores.length ? (
          <GoogleMap
            onLoad={onLoad}
            onUnmount={onUnmount}
            mapContainerStyle={{
              height: "50vh",
              width: "100vw",
              //marginLeft: "8px",
            }}
            center={{
              lat: selectedStore
                ? stores.find((store) => store._id === selectedStore)[0]
                    ?.location.x
                : stores[0].location.x,
              lng: selectedStore
                ? stores.find((store) => store._id === selectedStore)[0]
                    ?.location.y
                : stores[0].location.y,
            }}
            zoom={5}
          >
            {selectedStore
              ? stores
                  .filter((s) => s._id == selectedStore)
                  .map((store) => {
                    return (
                      <Marker
                        key={store._id}
                        position={{
                          lat: store.location.x,
                          lng: store.location.y,
                        }}
                      />
                    );
                  })
              : stores.map((store) => (
                  <Marker
                    key={store._id}
                    position={{
                      lat: store.location.x,
                      lng: store.location.y,
                    }}
                  />
                ))}
          </GoogleMap>
        ) : null}

        <Box
          component="div"
          id={selectedStore}
          sx={{
            backgroundColor: "white",
            position: "fixed",
            right: 0,
            left: 0,
            bottom: 0,
            // top: selectedStore ? "calc(100vh - 500px)" : "calc(100vh - 400px)",
            padding: "0 30px",
            //height: "320px",
          }}
        >
          <Typography
            className={css.header}
            style={{ fontWeight: "bold", marginTop: "5px", fontSize: "18px" }}
          >
            Select Store
          </Typography>

          <AppSlider {...calculateSlidersData(stores.length)}>
            {stores.map((store) => (
              <Box
                component="div"
                id={store._id}
                sx={{
                  flexDirection: "column",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "100%",
                  minWidth: "350px",
                  maxHeight: "300px",
                }}
                key={store._id}
              >
                <SelectStoreCard
                  handleSelectFeedBackStore={handleSelectFeedBackStore}
                  userX={localX}
                  userY={localY}
                  handleSelected={handleSelected}
                  selected={selectedStore}
                  key={store._id}
                  store={store}
                  items={location.state.items}
                  totalOrderCost={totalOrderCost}
                  totalPages={location.state.totalPages}
                />
                {selectedStore === store._id && (
                  <Box
                    sx={{
                      padding: "10px 20px",
                      display: "flex",
                      justifyContent: "center",
                      width: "100%",
                    }}
                  >
                    {selectedStore ? (
                      <Stack spacing={1} direction="row" sx={{}}>
                        <Button
                          size="small"
                          onClick={() => navigate("/user/print")}
                          variant="contained"
                          color="error"
                        >
                          Cancel
                        </Button>
                        <Button
                          size="small"
                          onClick={handleViewOrder}
                          variant="contained"
                          color="success"
                        >
                          Submit
                        </Button>
                      </Stack>
                    ) : null}
                  </Box>
                )}
              </Box>
            ))}
          </AppSlider>

          {/* <Box
            style={{
              overflowX: "scroll",
              width: "100%",
              display: "flex",
              // paddingBottom:"25px",
              paddingRight: "25px",
            }}
          >
            {stores.map((store) => (
              <Box
                component="div"
                id={store._id}
                sx={{
                  flexDirection: "column",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "100%",
                  minWidth: "350px",
                  maxHeight: "300px",
                }}
                key={store._id}
              >
                <SelectStoreCard
                  handleSelectFeedBackStore={handleSelectFeedBackStore}
                  userX={localX}
                  userY={localY}
                  handleSelected={handleSelected}
                  selected={selectedStore}
                  key={store._id}
                  store={store}
                  items={location.state.items}
                  totalOrderCost={totalOrderCost}
                  totalPages={location.state.totalPages}
                />
                {selectedStore === store._id && (
                  <Grid item xs={12} md={12} lg={12}>
                    {selectedStore ? (
                      <Stack
                        spacing={1}
                        direction="row"
                        sx={{
                          borderRadius: 2,
                          padding: "10px 20px",
                          marginLeft: "15px",
                          width: "100%",
                        }}
                      >
                        <Button
                          size="small"
                          onClick={() => navigate("/user/print")}
                          variant="contained"
                          color="error"
                        >
                          Cancel
                        </Button>
                        <Button
                          size="small"
                          onClick={handleViewOrder}
                          variant="contained"
                          color="success"
                        >
                          Submit
                        </Button>
                      </Stack>
                    ) : null}
                  </Grid>
                )}
              </Box>
            ))}
          </Box> */}
        </Box>
      </Box>

      {costDetails.length ? (
        <Dialog
          open={dialogOpen}
          onClose={handleDialogClose}
          scroll="paper"
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
        >
          <DialogTitle sx={{ textAlign: "center" }} id="scroll-dialog-title">
            Order Details
          </DialogTitle>
          <DialogTitle
            m={0}
            sx={{
              backgroundColor: "green",
              padding: "5px 10px",
              color: "white",
              fontSize: "16px",
            }}
            id="scroll-dialog-title-store"
          >
            Store : {storeDetails?.name}
          </DialogTitle>
          <DialogContent sx={{ maxWidth: "350px" }} dividers={true}>
            <div className={css.dialogContainer}>
              <div className={css.flexContainer}>
                <FormControl fullWidth>
                  <Select
                    value={selectedCostDetails.id}
                    onChange={handleOptionChange}
                    variant="outlined"
                    style={
                      selectedCostDetails !== null
                        ? { outline: "none", boxShadow: "0 0 0.5px blue" }
                        : null
                    }
                    size="small"
                  >
                    {costDetails.map((costDetail, index) => (
                      <MenuItem
                        key={index}
                        value={costDetail.id}
                        style={
                          selectedCostDetails.id === costDetail.id
                            ? { backgroundColor: "blue", color: "white" }
                            : null
                        }
                      >
                        {costDetail.item?.fileName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <Paper elevation={0} className={css.costContainer}>
                  <div>Xerox color: {selectedCostDetails?.color}</div>
                  <div>
                    Pages to Print : {selectedCostDetails?.pageRange[0]} to{" "}
                    {selectedCostDetails?.pageRange[1]}
                  </div>
                  <div>
                    Sides to print on :{" "}
                    {selectedCostDetails.side == "one" ? "Single" : "Double"}
                  </div>
                  {selectedCostDetails?.item.colors.color === "colpar" ? (
                    <div>
                      <div>
                        Total Black Pages :{" "}
                        {selectedCostDetails.item.totalPages -
                          selectedCostDetails.item.colorPar.pageNumbers
                            .length}{" "}
                      </div>
                      <div>
                        Total Color Pages :{" "}
                        {selectedCostDetails.item.colorPar.pageNumbers.length}{" "}
                      </div>
                      <div>
                        Color Page Nos. to print :{" "}
                        {selectedCostDetails.item.colorPar.pageNumbers.join(
                          ","
                        )}{" "}
                      </div>
                    </div>
                  ) : null}
                  <div>Total pages: {selectedCostDetails?.item.totalPages}</div>
                  <div>Paper size:{selectedCostDetails?.size}</div>
                  <div>
                    Print Layout: {selectedCostDetails?.item.printLayout}
                  </div>
                  <div>Binding: {selectedCostDetails?.binding}</div>
                  <div>Copies: {selectedCostDetails?.copies}</div>
                  <div>
                    Description:{" "}
                    {selectedCostDetails?.item.instructions || "NA"}
                  </div>
                  <div>Cost: ₹{selectedCostDetails?.totalCost?.toFixed(2)}</div>
                </Paper>
              </div>
            </div>
          </DialogContent>
          <DialogTitle sx={{ textAlign: "center" }} id="scroll-dialog-title">
            Total Payable : ₹{totalOrderCost?.toFixed(2)}
          </DialogTitle>

          <DialogActions
            sx={{
              minWidth: "300px",
              display: "flex",
              justifyContent: "space-around",
            }}
          >
            <Button
              color="warning"
              variant="outlined"
              onClick={handleDialogClose}
            >
              Cancel
            </Button>
            <LoadingButton
              loading={gettingOrderDetails}
              variant="contained"
              color="success"
              onClick={handlePayment}
            >
              Pay Now
            </LoadingButton>
            <Button
              variant="contained"
              color="success"
              onClick={handleOrderOnly}
            >
              Order Later
            </Button>
          </DialogActions>
        </Dialog>
      ) : null}
      <FeedBackDialog
        open={feedBackDialog}
        onClose={handleFeedBackDialogClose}
        store={selectFeedBackStore}
      />
    </>
  );
};
export default SelectStore;
