import {
  Button,
  Grid,
  InputAdornment,
  Modal,
  TextField,
  styled,
} from "@mui/material";
import AddLocationIcon from "@mui/icons-material/AddLocation";
import { Box } from "@mui/system";
import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import css from "../styles/pages/Stores.module.css";
import { toast } from "react-toastify";
import { get, post, put } from "../api/services/apiMethods";
import urls from "../api/constants";
import TableData from "../components/TableData";
import PaginationComponent from "../components/Pagination";
import TableFunctions from "../components/TableFunctions";
import EmployeePermission from "../context/employeePermission";
import FeedBackDialog from "../components/FeedBackDialog";
import moment from "moment";
import { useMediaQuery } from "@mui/material";
import dayjs from "dayjs";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  height: "100%",
  overflow: "scroll",
  display: "block",
  marginBottom: "1rem",
};
const deleteModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const Stores = () => {
  const [addModalOpen, setAddModalOpen] = useState(false);
  const [stores, setStores] = useState([]);
  const [edit, setEdit] = useState("");
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteStore, setDeleteStore] = useState("");
  const [dataCount, setDataCount] = useState(0);
  const [isSearch, setIsSearch] = useState(true);
  const [search, setSearch] = useState("");
  const { employeePermission } = useContext(EmployeePermission);
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
  } = useForm();
  const [feedBackDialog, setFeedBackDialog] = useState(false);
  const [selectStoreFeedBack, setSelectStoreFeedBack] = useState(null);
  const isXsScreen = useMediaQuery("(max-width:600px)");

  useEffect(() => {
    const getStores = async () => {
      if (
        employeePermission &&
        Array.isArray(employeePermission["Stores"]) &&
        employeePermission["Stores"].includes("view")
      ) {
        console.log("reach");
        const { data: storeList } = await post(
          `${urls.store.list}`,
          {},
          {
            params: {
              limit: 10,
              page: 1,
            },
          }
        );
        setStores(storeList.result.data);
        setDataCount(storeList.result.count);
      }
    };
    getStores();
  }, []);

  const getData = async () => {
    const { data: storeList } = await post(
      `${urls.store.list}`,
      {},
      {
        params: {
          limit: 10,
          page: 1,
        },
      }
    );
    setStores(storeList.result.data);
    setDataCount(storeList.result.count);
  };
  const undoSearch = async () => {
    setIsSearch(false);
    setSearch("");
    await getData();
  };

  const handleDeleteModalOpen = () => {
    setDeleteModal(true);
  };
  const handleDeleteModalClose = () => {
    setDeleteModal(false);
    setDeleteStore("");
  };
  const handleAddModalClose = () => {
    reset();
    setAddModalOpen(false);
    setEdit("");
  };
  const handleAddModalOpen = () => setAddModalOpen(true);

  const handleAddLocation = () => {
    if (window.navigator.geolocation) {
      window.navigator.geolocation.getCurrentPosition(
        (geo) => {
          setValue("locationY", geo.coords.longitude);
          setValue("locationX", geo.coords.latitude);
        },
        () => {
          toast.warning("Please allow location access for store location");
        }
      );
    }
  };
  const onSubmit = async (formData) => {
    try {
      const body = {
        name: formData.storeName,
        address: formData.address,
        openingTime: moment(formData.openingTime).format("hh:mm A"),
        closingTime: moment(formData.closingTime).format("hh:mm A"),
        location: {
          x: Number(formData.locationX),
          y: Number(formData.locationY),
        },
        meta: {
          ...formData,
        },
      };
      if (!edit) {
        const { data: store, status } = await post(
          `${urls.store.create}`,
          body
        );
        if (status > 199 && status < 299) {
          const { data: storeList } = await post(`${urls.store.list}`);
          setStores(storeList.result.data);
          console.log("setStores", storeList.result.data);
          setDataCount(storeList.result.count);
          handleAddModalClose();
          toast.success("New Store created");
        }
      } else {
        const { data: store, status } = await post(
          `${urls.store.update}${edit}`,
          body
        );
        if (status > 199 && status < 299) {
          const { data: storeList } = await put(`${urls.store.list}`);
          setStores(storeList.result.data);
          setDataCount(storeList.result.count);
          handleAddModalClose();
          toast.success("Store updated successfully");
        }
      }
    } catch (error) {
      toast.error("Something went wrong try again later");
    }
  };
  const handleDeleteStore = async (storeId) => {
    try {
      const { status } = await get(`${urls.store.deleteStore}${storeId}`);
      if (status > 199 && status < 299) {
        setStores((prevState) =>
          prevState.filter((store) => store._id !== storeId)
        );
        setDataCount((prevCount) => prevCount - 1);
        handleDeleteModalClose();
        toast.success("Store deleted successfully");
      }
    } catch (error) {}
  };
  const handleStoreEdit = async (id, data) => {
    try {
      setEdit(id);
      const keys = Object.keys(data.meta);
      for (const key of keys) {
        setValue(key, data.meta[key]);
      }
      setAddModalOpen(true);
    } catch (error) {
      console.log(error);
    }
  };
  const handlePageChange = async (page) => {
    try {
      if (isSearch) {
        const { data: storeList } = await post(
          `${urls.store.list}`,
          {},
          {
            params: {
              limit: 10,
              page: page,
              search,
            },
          }
        );
        setStores(storeList.result.data);
        setDataCount(storeList.result.count);
      } else {
        const { data: storeList } = await post(
          `${urls.store.list}`,
          {},
          {
            params: {
              limit: 10,
              page: page,
            },
          }
        );
        setStores(storeList.result.data);
        setDataCount(storeList.result.count);
      }
    } catch (error) {}
  };
  const handleSearch = async (search) => {
    try {
      console.log(search, "search===================");
      let searchQuery = {};
      if (search.searchText && search.searchText.trim()) {
        searchQuery["name"] = { $regex: search.searchText, $options: "i" };
      }
      if (search.fromDate && search.toDate) {
        searchQuery["createdAt"] = {
          $gte: dayjs(search.fromDate),
          $lte: dayjs(search.toDate).add(1, "day"),
        };
      }

      console.log(searchQuery, "search query");
      const searchStore = await get(`${urls.store.list}`, {
        params: {
          search: searchQuery,
        },
      });
      setStores(searchStore.data.result.data);
      setDataCount(searchStore.data.result.count);
      setSearch(search);
      setIsSearch(true);
    } catch (error) {}
  };
  const handleDialogClose = () => {
    setSelectStoreFeedBack(null);
    setFeedBackDialog(false);
  };
  const handleDialogOpen = () => {
    setFeedBackDialog(true);
  };
  const handleSelectFeedBack = (store) => {
    handleDialogOpen();
    setSelectStoreFeedBack(store);
  };
  return (
    <div className={css.container}>
      <TableFunctions
        showDateRange={true}
        addPermission={
          employeePermission &&
          Array.isArray(employeePermission["Stores"]) &&
          employeePermission["Stores"].includes("add")
        }
        undoSearch={undoSearch}
        setSearch={setIsSearch}
        btnTitle="Add Store"
        handleSearch={handleSearch}
        handleAddModalOpen={handleAddModalOpen}
      />

      <TableData
        headData={[
          {
            align: "left",
            label: "Name",
          },
          {
            align: "center",
            label: "Address",
          },
          {
            align: "center",
            label: "Rating",
          },
          {
            align: "right",
            label: "",
          },
        ]}
        editPermission={
          employeePermission &&
          employeePermission["Stores"] &&
          employeePermission["Stores"].includes("edit")
        }
        deletePermission={
          employeePermission &&
          employeePermission["Stores"] &&
          employeePermission["Stores"].includes("delete")
        }
        tableData={
          employeePermission &&
          Array.isArray(employeePermission["Stores"]) &&
          employeePermission["Stores"].includes("view")
            ? stores
            : []
        }
        tableSelect={[
          { align: "left", key: "name" },
          { align: "center", key: "address" },
          { align: "center", key: "avgRating" },
        ]}
        handleStoreEdit={handleStoreEdit}
        handleDeleteModalOpen={handleDeleteModalOpen}
        setDeleteStore={setDeleteStore}
        handleSelectFeedBack={handleSelectFeedBack}
        noDataTitle="No Stores found"
      />

      <PaginationComponent
        limit={10}
        count={dataCount}
        handlePageChange={handlePageChange}
      />

      <Modal open={addModalOpen} onClose={handleAddModalClose}>
        <Box sx={{ ...modalStyle, backgroundColor: "white" }}>
          <div className={css.addStoreHeader}>
            {edit ? "Update" : "Add Store"}
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className={css.formContainer}>
              <Grid container spacing={2}>
                <Grid item lg={12} sm={12}>
                  <TextField
                    fullWidth
                    {...register("storeName", { required: true })}
                    helperText={errors.storeName && "This field is required"}
                    error={!!errors.storeName}
                    size="small"
                    variant="outlined"
                    label="Store Name"
                    className={css.textField}
                  />
                </Grid>
                <Grid item lg={12} sm={12}>
                  <textarea
                    {...register("address", { required: true })}
                    placeholder="Address"
                    cols="10"
                    rows="100"
                    style={{ width: "100%", height: "70px" }}
                  ></textarea>
                </Grid>
              </Grid>
            </div>
            <div className={css.formContainer}>
              <Grid container spacing={2}>
                <Grid item lg={6} sm={12}>
                  <TextField
                    {...register("location", { required: true })}
                    helperText={errors.location && "This field is required"}
                    error={!!errors.location}
                    className={css.textField}
                    fullWidth
                    size="small"
                    variant="outlined"
                    label="Location"
                  />
                </Grid>
                <Grid item lg={6} sm={12}>
                  <TextField
                    {...register("costOneSide", {
                      required: true,
                      pattern: {
                        value: /^\d+(\.\d{1,2})?$/, // Pattern for up to two decimal places
                        message: "Maximum two decimal places allowed.",
                      },
                    })}
                    helperText={errors.costOneSide && "This field is required"}
                    error={!!errors.costOneSide}
                    className={css.textField}
                    fullWidth
                    size="small"
                    variant="outlined"
                    label="Cost"
                    type="text"
                    InputProps={{
                      inputMode: "decimal",
                      endAdornment: (
                        <InputAdornment position="end">
                          /One side
                        </InputAdornment>
                      ),
                      startAdornment: (
                        <InputAdornment position="start">₹</InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </Grid>
            </div>
            <div className={css.formContainer}>
              <Grid container spacing={2}>
                <Grid item lg={6} sm={12}>
                  <TextField
                    {...register("costTwoSide", {
                      required: true,
                      pattern: {
                        value: /^\d+(\.\d{1,2})?$/, // Pattern for up to two decimal places
                        message: "Maximum two decimal places allowed.",
                      },
                    })}
                    helperText={errors.costTwoSide && "This field is required"}
                    error={!!errors.costTwoSide}
                    className={css.textField}
                    fullWidth
                    size="small"
                    variant="outlined"
                    type="text"
                    label="Cost"
                    InputProps={{
                      inputMode: "decimal",
                      endAdornment: (
                        <InputAdornment position="end">
                          /Two side
                        </InputAdornment>
                      ),
                      startAdornment: (
                        <InputAdornment position="start">₹</InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item lg={6} sm={12}>
                  <TextField
                    {...register("costBlack", {
                      required: true,
                      pattern: {
                        value: /^\d+(\.\d{1,2})?$/, // Pattern for up to two decimal places
                        message: "Maximum two decimal places allowed.",
                      },
                    })}
                    helperText={errors.costBlack && "This field is required"}
                    error={!!errors.costBlack}
                    className={css.textField}
                    fullWidth
                    size="small"
                    variant="outlined"
                    type="text"
                    label="Cost"
                    InputProps={{
                      inputMode: "decimal",
                      endAdornment: (
                        <InputAdornment position="end">/Black</InputAdornment>
                      ),
                      startAdornment: (
                        <InputAdornment position="start">₹</InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </Grid>
            </div>
            <div className={css.formContainer}>
              <Grid container spacing={2}>
                <Grid item lg={6} sm={12}>
                  <TextField
                    {...register("costColor", {
                      required: true,
                      pattern: {
                        value: /^\d+(\.\d{1,2})?$/, // Pattern for up to two decimal places
                        message: "Maximum two decimal places allowed.",
                      },
                    })}
                    helperText={errors.costColor && "This field is required"}
                    error={!!errors.costColor}
                    className={css.textField}
                    fullWidth
                    size="small"
                    variant="outlined"
                    type="text"
                    label="Cost"
                    InputProps={{
                      inputMode: "decimal",
                      endAdornment: (
                        <InputAdornment position="end">/Color</InputAdornment>
                      ),
                      startAdornment: (
                        <InputAdornment position="start">₹</InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item lg={6} sm={12}>
                  <TextField
                    {...register("sizeA4", {
                      required: true,
                      pattern: {
                        value: /^\d+(\.\d{1,2})?$/, // Pattern for up to two decimal places
                        message: "Maximum two decimal places allowed.",
                      },
                    })}
                    helperText={errors.sizeA4 && "This field is required"}
                    error={!!errors.sizeA4}
                    className={css.textField}
                    fullWidth
                    size="small"
                    variant="outlined"
                    label="Cost"
                    type="text"
                    InputProps={{
                      inputMode: "decimal",
                      endAdornment: (
                        <InputAdornment position="end">/A4</InputAdornment>
                      ),
                      startAdornment: (
                        <InputAdornment position="start">₹</InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </Grid>
            </div>

            <div className={css.formContainer}>
              <Grid container spacing={2}>
                <Grid item lg={6} sm={12}>
                  <TextField
                    {...register("legal", {
                      required: true,
                      pattern: {
                        value: /^\d+(\.\d{1,2})?$/, // Pattern for up to two decimal places
                        message: "Maximum two decimal places allowed.",
                      },
                    })}
                    helperText={errors.legal && "This field is required"}
                    error={!!errors.legal}
                    className={css.textField}
                    fullWidth
                    size="small"
                    variant="outlined"
                    type="text"
                    label="Cost"
                    InputProps={{
                      inputMode: "decimal",
                      endAdornment: (
                        <InputAdornment position="end">/legal</InputAdornment>
                      ),
                      startAdornment: (
                        <InputAdornment position="start">₹</InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item lg={6} sm={12}>
                  <TextField
                    {...register("letter", {
                      required: true,
                      pattern: {
                        value: /^\d+(\.\d{1,2})?$/, // Pattern for up to two decimal places
                        message: "Maximum two decimal places allowed.",
                      },
                    })}
                    helperText={errors.letter && "This field is required"}
                    error={!!errors.letter}
                    className={css.textField}
                    fullWidth
                    size="small"
                    variant="outlined"
                    label="Cost"
                    type="text"
                    InputProps={{
                      inputMode: "decimal",
                      endAdornment: (
                        <InputAdornment position="end">/letter</InputAdornment>
                      ),
                      startAdornment: (
                        <InputAdornment position="start">₹</InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </Grid>
            </div>

            <div className={css.formContainer}>
              <Grid container spacing={2}>
                <Grid item lg={6} sm={12}>
                  <TextField
                    {...register("sizeB5", {
                      required: true,
                      pattern: {
                        value: /^\d+(\.\d{1,2})?$/, // Pattern for up to two decimal places
                        message: "Maximum two decimal places allowed.",
                      },
                    })}
                    helperText={errors.sizeB5 && "This field is required"}
                    error={!!errors.sizeB5}
                    className={css.textField}
                    fullWidth
                    size="small"
                    variant="outlined"
                    type="text"
                    label="Cost"
                    InputProps={{
                      inputMode: "decimal",
                      endAdornment: (
                        <InputAdornment position="end">/sizeB5</InputAdornment>
                      ),
                      startAdornment: (
                        <InputAdornment position="start">₹</InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item lg={6} sm={12}>
                  <TextField
                    {...register("sizeA6", {
                      required: true,
                      pattern: {
                        value: /^\d+(\.\d{1,2})?$/, // Pattern for up to two decimal places
                        message: "Maximum two decimal places allowed.",
                      },
                    })}
                    helperText={errors.sizeA6 && "This field is required"}
                    error={!!errors.sizeA6}
                    className={css.textField}
                    fullWidth
                    size="small"
                    variant="outlined"
                    label="Cost"
                    type="text"
                    InputProps={{
                      inputMode: "decimal",
                      endAdornment: (
                        <InputAdornment position="end">/sizeA6</InputAdornment>
                      ),
                      startAdornment: (
                        <InputAdornment position="start">₹</InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </Grid>
            </div>

            <div className={css.formContainer}>
              <Grid container spacing={2}>
                <Grid item lg={6} sm={12}>
                  <TextField
                    {...register("postCard", {
                      required: true,
                      pattern: {
                        value: /^\d+(\.\d{1,2})?$/, // Pattern for up to two decimal places
                        message: "Maximum two decimal places allowed.",
                      },
                    })}
                    helperText={errors.postCard && "This field is required"}
                    error={!!errors.postCard}
                    className={css.textField}
                    fullWidth
                    size="small"
                    variant="outlined"
                    type="text"
                    label="Cost"
                    InputProps={{
                      inputMode: "decimal",
                      endAdornment: (
                        <InputAdornment position="end">
                          /Post Card
                        </InputAdornment>
                      ),
                      startAdornment: (
                        <InputAdornment position="start">₹</InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item lg={6} sm={12}>
                  <TextField
                    {...register("size5X7", {
                      required: true,
                      pattern: {
                        value: /^\d+(\.\d{1,2})?$/, // Pattern for up to two decimal places
                        message: "Maximum two decimal places allowed.",
                      },
                    })}
                    helperText={errors.size5X7 && "This field is required"}
                    error={!!errors.size5X7}
                    className={css.textField}
                    fullWidth
                    size="small"
                    variant="outlined"
                    label="Cost"
                    type="text"
                    InputProps={{
                      inputMode: "decimal",
                      endAdornment: (
                        <InputAdornment position="end">
                          /5X7 in / 127X178 mm
                        </InputAdornment>
                      ),
                      startAdornment: (
                        <InputAdornment position="start">₹</InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </Grid>
            </div>

            <div className={css.formContainer}>
              <Grid container spacing={2}>
                <Grid item lg={6} sm={12}>
                  <TextField
                    {...register("size4X6", {
                      required: true,
                      pattern: {
                        value: /^\d+(\.\d{1,2})?$/, // Pattern for up to two decimal places
                        message: "Maximum two decimal places allowed.",
                      },
                    })}
                    helperText={errors.size4X6 && "This field is required"}
                    error={!!errors.size4X6}
                    className={css.textField}
                    fullWidth
                    size="small"
                    variant="outlined"
                    type="text"
                    label="Cost"
                    InputProps={{
                      inputMode: "decimal",
                      endAdornment: (
                        <InputAdornment position="end">
                          / 4X6 in / 10X15 cm
                        </InputAdornment>
                      ),
                      startAdornment: (
                        <InputAdornment position="start">₹</InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item lg={6} sm={12}>
                  <TextField
                    {...register("size35X5", {
                      required: true,
                      pattern: {
                        value: /^\d+(\.\d{1,2})?$/, // Pattern for up to two decimal places
                        message: "Maximum two decimal places allowed.",
                      },
                    })}
                    helperText={errors.size35X5 && "This field is required"}
                    error={!!errors.size35X5}
                    className={css.textField}
                    fullWidth
                    size="small"
                    variant="outlined"
                    label="Cost"
                    type="text"
                    InputProps={{
                      inputMode: "decimal",
                      endAdornment: (
                        <InputAdornment position="end">
                          / 3.5X5 in / 89X127 mm
                        </InputAdornment>
                      ),
                      startAdornment: (
                        <InputAdornment position="start">₹</InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </Grid>
            </div>

            <div className={css.formContainer}>
              <Grid container spacing={2}>
                <Grid item lg={6} sm={12}>
                  <TextField
                    {...register("sizeA5", {
                      required: true,
                      pattern: {
                        value: /^\d+(\.\d{1,2})?$/, // Pattern for up to two decimal places
                        message: "Maximum two decimal places allowed.",
                      },
                    })}
                    helperText={errors.sizeA5 && "This field is required"}
                    error={!!errors.sizeA5}
                    className={css.textField}
                    fullWidth
                    size="small"
                    variant="outlined"
                    type="text"
                    label="Cost"
                    InputProps={{
                      inputMode: "decimal",
                      endAdornment: (
                        <InputAdornment position="end">/A5</InputAdornment>
                      ),
                      startAdornment: (
                        <InputAdornment position="start">₹</InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item lg={6} sm={12}>
                  <TextField
                    {...register("spiralBinding", {
                      required: true,
                      pattern: {
                        value: /^\d+(\.\d{1,2})?$/, // Pattern for up to two decimal places
                        message: "Maximum two decimal places allowed.",
                      },
                    })}
                    helperText={
                      errors.spiralBinding && "This field is required"
                    }
                    error={!!errors.spiralBinding}
                    className={css.textField}
                    fullWidth
                    size="small"
                    variant="outlined"
                    label="Cost"
                    type="text"
                    InputProps={{
                      inputMode: "decimal",
                      endAdornment: (
                        <InputAdornment position="end">
                          /Spiral Binding
                        </InputAdornment>
                      ),
                      startAdornment: (
                        <InputAdornment position="start">₹</InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </Grid>
            </div>

            <div className={css.formContainer}>
              <Grid container spacing={2}>
                <Grid item lg={6} sm={12}>
                  <TextField
                    {...register("staplesBinding", {
                      required: true,
                      pattern: {
                        value: /^\d+(\.\d{1,2})?$/, // Pattern for up to two decimal places
                        message: "Maximum two decimal places allowed.",
                      },
                    })}
                    helperText={
                      errors.staplesBinding && "This field is required"
                    }
                    error={!!errors.staplesBinding}
                    className={css.textField}
                    fullWidth
                    size="small"
                    variant="outlined"
                    type="text"
                    label="Cost"
                    InputProps={{
                      inputMode: "decimal",
                      endAdornment: (
                        <InputAdornment position="end">
                          /Staples Binding
                        </InputAdornment>
                      ),
                      startAdornment: (
                        <InputAdornment position="start">₹</InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item lg={6} sm={12}>
                  <TextField
                    {...register("stickFile", {
                      required: true,
                      pattern: {
                        value: /^\d+(\.\d{1,2})?$/, // Pattern for up to two decimal places
                        message: "Maximum two decimal places allowed.",
                      },
                    })}
                    helperText={errors.stickFile && "This field is required"}
                    error={!!errors.stickFile}
                    className={css.textField}
                    fullWidth
                    size="small"
                    variant="outlined"
                    type="text"
                    label="Cost"
                    InputProps={{
                      inputMode: "decimal",
                      endAdornment: (
                        <InputAdornment position="end">
                          /stick File
                        </InputAdornment>
                      ),
                      startAdornment: (
                        <InputAdornment position="start">₹</InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </Grid>
            </div>
            <div className={css.formContainer}>
              <Grid container spacing={2}>
                <Grid item lg={6} sm={12}>
                  <TextField
                    {...register("quality100Gsm", {
                      required: true,
                      pattern: {
                        value: /^\d+(\.\d{1,2})?$/, // Pattern for up to two decimal places
                        message: "Maximum two decimal places allowed.",
                      },
                    })}
                    helperText={
                      errors.quality100Gsm && "This field is required"
                    }
                    error={!!errors.quality100Gsm}
                    className={css.textField}
                    fullWidth
                    size="small"
                    variant="outlined"
                    label="Cost"
                    type="text"
                    InputProps={{
                      inputMode: "decimal",
                      endAdornment: (
                        <InputAdornment position="end">
                          /Quality 100Gsm
                        </InputAdornment>
                      ),
                      startAdornment: (
                        <InputAdornment position="start">₹</InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item lg={6} sm={12}>
                  <TextField
                    {...register("quality80gsm", {
                      required: true,
                      pattern: {
                        value: /^\d+(\.\d{1,2})?$/, // Pattern for up to two decimal places
                        message: "Maximum two decimal places allowed.",
                      },
                    })}
                    helperText={errors.quality80gsm && "This field is required"}
                    error={!!errors.quality80gsm}
                    className={css.textField}
                    fullWidth
                    size="small"
                    variant="outlined"
                    type="text"
                    label="Cost"
                    InputProps={{
                      inputMode: "decimal",
                      endAdornment: (
                        <InputAdornment position="end">
                          /Quality 80gsm
                        </InputAdornment>
                      ),
                      startAdornment: (
                        <InputAdornment position="start">₹</InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </Grid>
            </div>

            <div className={css.formContainer}>
              <Grid container spacing={2}>
                <Grid item lg={6} sm={12}>
                  <TextField
                    {...register("bondPage", {
                      required: true,
                      pattern: {
                        value: /^\d+(\.\d{1,2})?$/, // Pattern for up to two decimal places
                        message: "Maximum two decimal places allowed.",
                      },
                    })}
                    helperText={errors.bondPage && "This field is required"}
                    error={!!errors.bondPage}
                    className={css.textField}
                    fullWidth
                    size="small"
                    variant="outlined"
                    type="text"
                    label="Cost"
                    InputProps={{
                      inputMode: "decimal",
                      endAdornment: (
                        <InputAdornment position="end">
                          /BondPage
                        </InputAdornment>
                      ),
                      startAdornment: (
                        <InputAdornment position="start">₹</InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </Grid>
            </div>
            <div className={css.formContainer}>
              <Grid container spacing={2}>
                <Grid item lg={6} sm={12}>
                  <TextField
                    {...register("email", { required: true })}
                    helperText={errors.email && "This field is required"}
                    error={!!errors.email}
                    className={css.textField}
                    fullWidth
                    size="small"
                    variant="outlined"
                    label="Email"
                    type="email"
                  />
                </Grid>
                <Grid item lg={6} sm={12}>
                  <TextField
                    {...register("contactNumber", { required: true })}
                    helperText={
                      errors.contactNumber && "This field is required"
                    }
                    error={!!errors.contactNumber}
                    className={css.textField}
                    inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                    fullWidth
                    size="small"
                    variant="outlined"
                    label="Contact Number"
                    type="number"
                  />
                </Grid>
              </Grid>
            </div>

            <div className={css.formContainer}>
              <Grid container spacing={2}>
                <Grid item lg={6} sm={12}>
                  <TextField
                    {...register("openingTime", { required: true })}
                    helperText={errors.openingTime && "This field is required"}
                    error={!!errors.openingTime}
                    className={css.textField}
                    fullWidth
                    size="small"
                    variant="outlined"
                    label="openingTime"
                    type="time"
                    step="60"
                    pattern="[0-9]{2}:[0-9]{2}"
                  />
                </Grid>
                <Grid item lg={6} sm={12}>
                  <TextField
                    {...register("closingTime", { required: true })}
                    helperText={errors.closingTime && "This field is required"}
                    error={!!errors.closingTime}
                    className={css.textField}
                    fullWidth
                    size="small"
                    variant="outlined"
                    label="closingTime"
                    type="time"
                    step="60"
                    pattern="[0-9]{2}:[0-9]{2}"
                  />
                </Grid>
              </Grid>
            </div>
            <div className={css.formContainer}>
              <Grid container spacing={2}>
                <Grid item lg={6} sm={12}>
                  <TextField
                    {...register("locationX", { required: true })}
                    helperText={errors.locationX && "This field is required"}
                    error={!!errors.locationX}
                    className={css.textField}
                    fullWidth
                    size="small"
                    variant="outlined"
                    label="Location X"
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item lg={6} sm={12}>
                  <TextField
                    {...register("locationY", { required: true })}
                    helperText={errors.locationY && "This field is required"}
                    error={!!errors.locationY}
                    className={css.textField}
                    fullWidth
                    size="small"
                    variant="outlined"
                    label="Location Y"
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
              </Grid>
            </div>

            <div className={css.formContainer}>
              <Button onClick={handleAddLocation}>
                <AddLocationIcon /> USE MY LOCATION
              </Button>
            </div>
            <div className={css.formBtn}>
              <Button type="submit" variant="contained">
                {edit ? "Update" : "Add Store"}
              </Button>
              <Button
                onClick={() => handleAddModalClose()}
                type="button"
                variant="contained"
                color="warning"
              >
                Cancel
              </Button>
            </div>
          </form>
        </Box>
      </Modal>
      <Modal open={deleteModal} onClose={handleDeleteModalClose}>
        <Box sx={{ ...deleteModalStyle, backgroundColor: "white" }}>
          <div>
            <div>Are you sure you want to delete?</div>
            <div
              style={{
                display: "flex",
                gap: "5px",
                justifyContent: "flex-end",
                marginTop: "2rem",
              }}
            >
              <Button
                onClick={handleDeleteModalClose}
                size="small"
                variant="contained"
              >
                No
              </Button>
              <Button
                onClick={() => handleDeleteStore(deleteStore)}
                size="small"
                variant="contained"
              >
                Yes
              </Button>
            </div>
          </div>
        </Box>
      </Modal>
      <FeedBackDialog
        onClose={handleDialogClose}
        open={feedBackDialog}
        store={selectStoreFeedBack}
      />
    </div>
  );
};

export default Stores;
