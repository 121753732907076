import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import authentication from "../api/services/authentication";
import css from "../styles/pages/Login.module.css";
import { isValidEmail, objPropertyCheck } from "../utils/utils";
import { objHas } from "../utils/utils";
import Checkbox from "@mui/material/Checkbox";

const SignUp = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({});
  const [formError, setFormError] = useState(false);
  const [dobVal, setDobVal] = useState(dayjs(Date.now()));
  const [showPassword, setShowPassword] = useState(false);
  const [userRegOtp, setUserRegOtp] = useState(false);
  const [otp, setOtp] = useState("");
  const [resError, setResError] = useState("");
  const [settings, setSettings] = useState(null);
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    const logoURL = require("./image/logo3.jpg");
    setSettings({ ...settings, logo: { link: logoURL } });
  }, []);

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (userRegOtp) {
        if (objPropertyCheck(formData, ["otp"])) {
          const { data, status } = await authentication.signUp({
            name: formData.fullName,
            email: formData.email,
            password: formData.password,
            otp: formData.otp,
            mobile: formData.mobile,
          });
          console.log(status);
          if (status > 299) {
            setResError(data.message);
          } else {
            window.localStorage.setItem(
              "access_token",
              data.result.access_token
            );
            window.localStorage.setItem(
              "refresh_token",
              data.result.refresh_token
            );
            const userStringify = JSON.stringify(data.result.user);
            window.localStorage.setItem("user", userStringify);
            navigate("/user/print");
          }
        } else {
          setFormError(true);
        }
      } else {
        if (
          objPropertyCheck(formData, [
            "email",
            "password",
            "mobile",
            "address",
            "fullName",
          ]) &&
          isValidEmail(formData.email)
        ) {
          const sendOtp = await authentication.regOtp({
            name: formData.fullName,
            email: formData.email,
            mobile: formData.mobile,
          });
          if (sendOtp.status === 200) {
            setUserRegOtp(true);
            setOtp(sendOtp.data.result.otp);
          } else {
            setResError(sendOtp.data.message);
          }
        } else {
          setFormError(true);
        }
      }
    } catch (error) {
      setResError(error.response.data.message);
    }
  };

  console.log(checked, "===checked");
  return (
    <div className={css.loginContainer}>
      <div className={css.flexContainer}>
        <div onClick={() => navigate("/login")}>
          {settings && (
            <img
              src={settings && objHas(settings, "logo.link", "")}
              alt="logo"
              style={{
                width: "220px",
                marginLeft: "-5px",
                marginTop: "-10px",
              }}
            />
          )}
        </div>
        <div className={css.header}>Sign Up</div>
        <div className={css.subHeader}>
          Already have a account? Login <Link to={"/login"}>here</Link>
        </div>
        <div className={css.error}>{resError && resError}</div>
        <div className={css.formContainer}>
          <form onSubmit={handleSubmit}>
            {userRegOtp ? (
              <>
                <div className={css.formHolder}>
                  <TextField
                    size="small"
                    helperText={formError && !formData.otp && "Provide field"}
                    error={formError && !formData.otp}
                    fullWidth
                    onChange={handleFormChange}
                    value={formData.otp || ""}
                    name="otp"
                    label="Otp"
                    variant="outlined"
                    sx={{
                      backgroundColor: "#F5F5F5",
                    }}
                  />
                </div>
                <div></div>
              </>
            ) : (
              <>
                <div className={css.formHolder}>
                  <TextField
                    size="small"
                    required
                    error={
                      formError &&
                      (!formData.email || !isValidEmail(formData.email))
                    }
                    fullWidth
                    onChange={handleFormChange}
                    value={formData.email || ""}
                    name="email"
                    label="Email"
                    variant="outlined"
                    sx={{
                      backgroundColor: "#F5F5F5",
                    }}
                  />
                  {formError && !formData.email && (
                    <span style={{ color: "red" }}>This field is required</span>
                  )}
                  {formError && !isValidEmail(formData.email) && (
                    <span style={{ color: "red" }}>Provide a valid email</span>
                  )}
                </div>
                <div className={css.formHolder}>
                  <TextField
                    size="small"
                    error={formError && !formData.fullName}
                    fullWidth
                    onChange={handleFormChange}
                    value={formData.fullName || ""}
                    name="fullName"
                    label="Full Name"
                    variant="outlined"
                    sx={{
                      backgroundColor: "#F5F5F5",
                    }}
                  />
                  {formError && !formData.fullName && (
                    <span style={{ color: "red" }}>This field is required</span>
                  )}
                </div>
                <div className={css.formHolder}>
                  <TextField
                    type="number"
                    size="small"
                    error={formError && !formData.mobile}
                    fullWidth
                    onChange={handleFormChange}
                    value={formData.mobile || ""}
                    name="mobile"
                    label="Mobile Number"
                    variant="outlined"
                    sx={{
                      backgroundColor: "#F5F5F5",
                    }}
                  />
                  {formError && !formData.mobile && (
                    <span style={{ color: "red" }}>This field is required</span>
                  )}
                </div>
                <div className={css.formHolder}>
                  <TextField
                    size="small"
                    error={formError && !formData.address}
                    fullWidth
                    onChange={handleFormChange}
                    value={formData.address || ""}
                    name="address"
                    label="Address"
                    variant="outlined"
                    sx={{
                      backgroundColor: "#F5F5F5",
                    }}
                  />
                  {formError && !formData.address && (
                    <span style={{ color: "red" }}>This field is required</span>
                  )}
                </div>
                <div className={css.formHolder}>
                  <DatePicker
                    disableFuture
                    label="Date Of Birth"
                    openTo="year"
                    views={["year", "month", "day"]}
                    value={dobVal}
                    onChange={(dateVal) => setDobVal(dateVal)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        fullWidth
                        onChange={handleFormChange}
                        value={formData.dob || ""}
                        name="dob"
                        label="Date of Birth"
                        variant="outlined"
                        sx={{
                          backgroundColor: "#F5F5F5",
                        }}
                      />
                    )}
                  />
                </div>
                <div className={css.formHolder}>
                  <TextField
                    type={showPassword ? "text" : "password"}
                    size="small"
                    error={formError && !formData.password}
                    fullWidth
                    onChange={handleFormChange}
                    value={formData.password || ""}
                    name="password"
                    label="Password"
                    variant="outlined"
                    sx={{
                      backgroundColor: "#F5F5F5",
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            edge="end"
                            aria-label="toggle password visibility"
                            onClick={() => setShowPassword(!showPassword)}
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  {formError && !formData.password && (
                    <span style={{ color: "red" }}>This field is required</span>
                  )}
                </div>
              </>
            )}
            {userRegOtp ? (
              <div className={css.textBtnContainer}>
                <div className={css.btn} style={{ width: "100%" }}>
                  <Button variant="contained" type="submit">
                    Verfiy Otp
                  </Button>
                </div>
              </div>
            ) : (
              <div className={css.textBtnContainer}>
                <div className={css.btn} style={{ width: "100%" }}>
                  <Box sx={{ display: "flex", marginBottom: "10px" }}>
                    <Checkbox
                      value={checked}
                      onChange={(e) => setChecked(!checked)}
                    />{" "}
                    <Typography>
                      By continuing you agree to accept the{" "}
                      <Link to="/terms-condition">Terms of Service</Link> and{" "}
                      <Link to="/privacy-policy">Privacy policy</Link>
                    </Typography>
                  </Box>

                  <Button disabled={!checked} variant="contained" type="submit">
                    Sign Up
                  </Button>
                </div>
              </div>
            )}
          </form>
        </div>
      </div>
    </div>
  );
};

export default SignUp;
