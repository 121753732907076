const urls = {
  store: {
    create: "/store/create",
    list: "/store/list",
    deleteStore: "/store/delete/",
    update: "/store/update/",
  },
  employee: {
    rolesList: "/systemUsers/roles",
    employeeList: "/systemUsers/list",
    employeeDelete: "/systemUsers/delete",
    create: "/systemUsers/create",
    login: "/systemUsers/login",
    update: "/systemUsers/update/",
  },
  entities: {
    create: "/entities/create",
    list: "/entities/read",
    update: "/entities/update/",
    del: "/entities/delete/",
  },
  cloudService: {
    signed: "/cloud-services/file/getSignedUrl",
    download: "/cloud-services/file/getDownloadableUrl",
  },
  stationery: {
    create: "/stationary/create",
    list: "/stationary/list",
    update: "/stationary/update/",
    del: "/stationary/delete/",
  },
  orders: {
    create: "/orders/create",
    list: "/orders/list",
    update: "/orders/update/",
    payment: "/payment/confirm",
    del: "/orders/delete/",
    get: "/orders/details/",
  },
  payment: {
    order: "/payment/order",
  },
  roles: {
    create: "/permission/addRole",
    list: "/permission/list",
    get: "/permission/get/",
    update: "/permission/update/",
    del: "/permission/delete/",
    modules: "/permission/modules",
  },
  auth: {
    genOtp: "/account/generateResetPasswordOtp",
    resetPassword: "/account/resetForgotPassword",
  },
  fileUpload: {
    upload: "/fileUpload/create",
    showDocument: "/fileUpload/showDocuments",
  },
};

export default urls;
