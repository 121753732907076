import { Box, Button, Card, Dialog, DialogActions, DialogContent, DialogTitle, Rating } from "@mui/material";
import React, { useState } from "react";

// styles 
import css from '../styles/components/FeedBack.module.css';

const FeedBackDialog = (props) => {
    return(
        <div>
            <Dialog
            open={props.open}
            onClose={props.onClose}
            scroll="paper"
            fullWidth
            maxWidth="sm"
            >
                <DialogTitle>Reviews</DialogTitle>
                <DialogContent dividers={true} >
                    <div className={css.container}>
                    <div className={css.flexContainer}>
                        {props.store?.feedBack.map((userfeedback) => (
                            <Card className={css.ratingCard}>
                                <Box>
                                    <div>
                                        <Rating value={userfeedback.rating} readOnly />
                                    </div>
                                    <div>
                                        <strong>{props.store?.reviewer.find((user) => userfeedback.user === user._id)?.name}</strong>
                                    </div>
                                    <div>
                                        {userfeedback.review}  
                                    </div>
                                </Box>
                            </Card>
                        ))}
                    </div>
                    </div>
                </DialogContent>
                <DialogActions> 
                    <Button onClick={props.onClose} color="warning">Close</Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default FeedBackDialog;