import { Clear } from "@mui/icons-material";
import {
  Button,
  IconButton,
  InputAdornment,
  TextField,
  Grid,
} from "@mui/material";
import React, { useEffect, useState } from "react";

// styles
import css from "../styles/components/TableFunctions.module.css";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";

const TableFunctions = (props) => {
  const [search, setSearch] = useState("");
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);

  // useEffect(() => {
  //   if (search === "" && props.undoSearch ) {
  //     props.undoSearch();
  //   }
  // }, [search]);

  const cancelSearch = () => {
    setSearch("");
    setFromDate(null);
    setToDate(null);
    props.undoSearch();
  };

  if (!props.showDateRange)
    return (
      <>
        <div className={css.container}>
          <Grid container py={2}>
            <Grid item xs={12} md={6}>
              <div className={css.btnContainer}>
                {props.addPermission && (
                  <Button
                    onClick={props.handleAddModalOpen}
                    variant="contained"
                    size="small"
                  >
                    {props.btnTitle}
                  </Button>
                )}
              </div>
            </Grid>
            <Grid item xs={12} md={6}>
              <div className={css.searchContainer}>
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    props.handleSearch(search);
                  }}
                  className={css.formContainer}
                >
                  <Grid container sx={{ gap: { md: 4, lg: 5 } }}>
                    <Grid
                      item
                      xs={12}
                      md={10}
                      sx={{
                        display: "flex",
                        justifyContent: { xs: "flex-start", md: "flex-end" },
                        marginTop: { xs: 1, md: 0 },
                      }}
                    >
                      <TextField
                        className={css.searchField}
                        name="search"
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                        size="small"
                        placeholder={props.placeholder || "Search by name"}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              {search && (
                                <IconButton edge="end" onClick={cancelSearch}>
                                  <Clear />
                                </IconButton>
                              )}
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={1}
                      sx={{
                        display: "flex",
                        justifyContent: { xs: "flex-start", md: "flex-end" },
                        marginTop: { xs: 1, md: 0 },
                        alignItems: "center",
                      }}
                    >
                      <Button type="submit" variant="contained" size="small">
                        SEARCH
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              </div>
            </Grid>
          </Grid>
        </div>
      </>
    );

  return (
    <>
      <div className={css.container}>
        <Grid container py={2}>
          <Grid item xs={12} md={12} mb={2}>
            <div className={css.btnContainer}>
              {props.addPermission && (
                <Button
                  onClick={props.handleAddModalOpen}
                  variant="contained"
                  size="small"
                >
                  {props.btnTitle}
                </Button>
              )}
            </div>
          </Grid>
          <Grid item sx={{}} xs={12}>
            <div className={css.searchContainer}>
              <form className={css.formContainer}>
                <Grid container sx={{ gap: 2 }}>
                  {props.showDateRange ? (
                    <>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={6}
                        lg={3}
                        sx={{ alignSelf: "center" }}
                      >
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            label="From Date"
                            inputFormat="DD-MM-YYYY"
                            value={fromDate}
                            onChange={(newValue) =>
                              setFromDate(dayjs(newValue))
                            }
                            renderInput={(params) => (
                              <TextField {...params} size="small" fullWidth />
                            )}
                          />
                        </LocalizationProvider>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={6}
                        lg={3}
                        sx={{ alignSelf: "center" }}
                      >
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            label="To Date"
                            inputFormat="DD-MM-YYYY"
                            value={toDate}
                            onChange={(newValue) => setToDate(dayjs(newValue))}
                            renderInput={(params) => (
                              <TextField {...params} size="small" fullWidth />
                            )}
                          />
                        </LocalizationProvider>
                      </Grid>
                    </>
                  ) : null}
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={6}
                    lg={3}
                    sx={{
                      display: "flex",
                      marginTop: { xs: 1, md: 0 },
                    }}
                  >
                    <TextField
                      className={css.searchField}
                      name="search"
                      value={search}
                      onChange={(e) => setSearch(e.target.value)}
                      size="small"
                      placeholder={props.placeholder || "Search by name..."}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={6}
                    lg={1.2}
                    sx={{
                      display: "flex",
                      justifyContent: {
                        xs: "flex-start",
                        alignItems: "center",
                      },
                      marginTop: { xs: 1, md: 0 },
                    }}
                  >
                    <Button
                      onClick={(e) => {
                        e.preventDefault();
                        props.handleSearch({
                          searchText: search,
                          fromDate,
                          toDate,
                        });
                      }}
                      variant="contained"
                      size="small"
                    >
                      SEARCH
                    </Button>
                    {search.trim().length ||
                    fromDate !== null ||
                    toDate !== null ? (
                      <Button
                        sx={{ marginLeft: "5px" }}
                        variant="contained"
                        type="button"
                        size="small"
                        onClick={cancelSearch}
                      >
                        Reset
                      </Button>
                    ) : null}
                  </Grid>
                </Grid>
              </form>
            </div>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default TableFunctions;
