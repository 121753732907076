import { Button, Modal, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { Box } from '@mui/system';
import React, { useState } from 'react';

import css from '../styles/pages/Job.module.css'

const rows = [
    {
        orderId:'77977687',
        paper:'A4-100gsm-color',
        copies:5
    },
    {
        orderId:'789777898',
        paper:'A5-80gsm-b/w',
        copies:3
    }
]
const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };
  
const Job = () => {
    const [modalOpen,setModalOpen] = useState(false);

    const handleModalOpen = () => setModalOpen(true);
    const handleModalClose = () => setModalOpen(false)

    return(
        <div className={css.container}>
            <div className={css.header}>
                Past Orders
            </div>
            <TableContainer component={Paper}>
                <Table sx={{minWidth:650}}>
                    <TableHead className={css.tableHeader}>
                        <TableRow className={css.tableRowHeader}> 
                            <TableCell>Order No</TableCell>
                            <TableCell>Paper size/quality/color</TableCell>
                            <TableCell>No. of copies</TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row) => (
                            <TableRow
                            key={row.orderId}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell className={css.tableData}>{row.orderId}</TableCell>
                                <TableCell className={css.tableData}>{row.paper}</TableCell>
                                <TableCell className={css.tableData}>{row.copies}</TableCell>
                                <TableCell><Button onClick={handleModalOpen} size='small' variant='contained'>VIEW JOB</Button></TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <div className={css.header}>
                Pending Jobs
            </div>
            <TableContainer component={Paper}>
                <Table sx={{minWidth:650}}>
                    <TableHead className={css.tableHeader}>
                        <TableRow className={css.tableRowHeader}> 
                            <TableCell>Order No</TableCell>
                            <TableCell>Paper size/quality/color</TableCell>
                            <TableCell>No. of copies</TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row) => (
                            <TableRow
                            key={row.orderId}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell className={css.tableData}>{row.orderId}</TableCell>
                                <TableCell className={css.tableData}>{row.paper}</TableCell>
                                <TableCell className={css.tableData}>{row.copies}</TableCell>
                                <TableCell><Button size='small' variant='contained'>VIEW JOB</Button></TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Modal
            open={modalOpen}
            onClose={handleModalClose}
            >
                <Box sx={{...modalStyle,backgroundColor:'white'}}>
                    <h1>ccldskn</h1>
                </Box>
            </Modal>
        </div>
    )
}
export default Job;